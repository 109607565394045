(function () {
  'use strict';
  angular.module('lucidity').directive('ngCleditor', ['$',
    function ($) {
      var directive = {};
      directive.require = '?ngModel';
      directive.link = function ($scope, elem, attrs, ngModel) {
        if (!ngModel) {
          return;
        }

        ngModel.$render = function () {
          $(elem).val(ngModel.$viewValue).blur();
        };

        $(elem).cleditor().change(function () {
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              ngModel.$setViewValue(elem.val());
            });
          }
        });
      };

      return directive;
    },
  ]);
})();
