(function () {
  'use strict';
  angular.module('lucidity').factory('AutoSaveService', [
    '_',
    'Restangular',
    function (_, Restangular) {
      var service = {};
      var apiParams = [];

      service.setApiParam = function (apiKey, apiValue) {
        apiParams[apiKey] = apiValue;
      };

      service.getApiParam = function (apiKey) {
        var returnApiParam = null;
        if (apiParams[apiKey] !== undefined) {
          returnApiParam = apiParams[apiKey];
        }

        return returnApiParam;
      };

      service.autoSaveQueue = function (autoSaveObjectId, params) {
        var autoSaveEndPoint = service.getApiParam('autoSaveEndPoint');
        return Restangular.one(autoSaveEndPoint, autoSaveObjectId).customPUT(params);
      };

      return service;
    },
  ]);
})();
