(function () {
  'use strict';

  angular.module('lucidity').controller('ColumnConfigController', ColumnConfigController);

  ColumnConfigController.$inject = ['_', 'filterFilter', 'columnConfigService', '$timeout', 'MessageService', '$window', '$sce'];

  function ColumnConfigController(_, filterFilter, columnConfigService, $timeout, MessageService, $window, $sce) {
    var vm = this;
    var columnConfig = null;
    var columnConfigs = [];
    var moduleController = '';
    var uri = '';

    var flattenedColumns = [];

    vm.redirectUrl = '';
    vm.columnConfig = null;
    vm.configureColumnOption = null;
    vm.placeholdertext = 'Add Column';
    vm.configureColumnOptions = [];
    vm.filteredDisplayColumns = [];
    vm.selectColumnConfig = selectColumnConfig;
    vm.selectAllColumns = selectAllColumns;
    vm.shouldShowAddAllButton = false;

    vm.addColumn = addColumn;
    vm.init = init;
    vm.removeColumn = removeColumn;
    vm.moveColumn = moveColumn;
    vm.saveColumnConfig = saveColumnConfig;

    vm.processing = {
      saving: false,
      resetting: false,
      modified: false,
    };

    function saveColumnConfig() {
      vm.processing.saving = true;
      columnConfigService.saveColumnConfigs(columnConfigs).then(
          function () {
            vm.processing.saving = false;
            vm.processing.modified = false;
            MessageService.success('Column config saved!');
            $window.location = vm.redirectUrl;
          }
      );
    }

    function columnDisplayName(column) {
      var name = column.name;
      if (column.$$parent !== vm.columnConfig.columnSet) {
        name += ' <span class="label label-default">' + column.$$parent.name + '</span>';
      }

      return name;
    }

    function moveColumn(movedColumn, $index) {
      var columns = movedColumn.$$parent.columns;
      vm.processing.modified = true;
      columns.splice(columns.indexOf(movedColumn), 1);
      columns.forEach(function (column, index, collection) {
        if (key(movedColumn) === key(column)) {
          collection[index] = movedColumn;
        }
      });
    }

    function key(column) {
      if (_.isString(column.key)) {
        return column.key;
      }

      return JSON.stringify(column.key);
    }

    function init(initModuleController, columnConfigOptions, redirectUrl) {
      moduleController = initModuleController;
      vm.redirectUrl = redirectUrl;
      vm.configureColumnOptions = columnConfigOptions;
      vm.configureColumnOption = columnConfigOptions[0] || null;
      selectColumnConfig(vm.configureColumnOption);
    }

    function initialiseColumnConfig(remoteColumnConfig) {
      columnConfig = remoteColumnConfig.data;
      if (columnConfigs.indexOf(columnConfig) === -1) {
        columnConfigs.push(columnConfig);
      }

      vm.columnConfig = columnConfig.data;
      prepareColumns(vm.columnConfig.columnSet);
      filterColumnList();
      function prepareColumns(columnSet) {
        var columns = columnSet.columns || [];
        columns.forEach(function (column) {

          column.parentKey = columnSet.key;
          column.$$parent = columnSet;
          column.displayName = columnDisplayName(column);
          column.template = '/app/column-config/column.html';
          if (column.columns !== undefined) {
            column.template = '/app/column-config/column-set.html';
            prepareColumns(column);
          } else {
            flattenedColumns.push(column);
          }
        });
      }
    }

    function selectColumnConfig(columnConfigSelector) {
      vm.filteredDisplayColumns = flattenedColumns = [];
      vm.columnConfig = columnConfig = null;
      vm.configureColumnOption = columnConfigSelector;

      uri = moduleController + '/' + columnConfigSelector.name;
      columnConfigService
          .getColumnConfig(uri)
          .then(function (remoteColumnConfig) {

            $timeout(function () {
              initialiseColumnConfig(remoteColumnConfig);
            }, 500);
          });

    }

    function addColumn(value, column) {
      selectColumn(column);
      filterColumnList();
      vm.selectedColumn = null;
      function selectColumn(column) {
        column.enabled = true;
        if (column.$$parent !== vm.columnConfig.columnSet) {
          selectColumn(column.$$parent);
        }
      }
    }

    function removeColumn(column) {
      vm.processing.modified = true;
      deselectColumn(column);
      filterColumnList();
      function deselectColumn(column) {
        column.enabled = false;
        if (column.$$parent !== vm.columnConfig.columnSet && enabledColumns(column.$$parent).length === 0) {
          deselectColumn(column.$$parent);
        }
      }

    }

    function enabledColumns(column) {
      return _.filterByKey(column.columns || [], 'enabled', true);
    }

    function filterColumnList() {
      vm.filteredDisplayColumns = filterFilter(flattenedColumns, { enabled: false });
      var shouldShowTheAddAllButton = false;
      if (vm.filteredDisplayColumns.length === 0 && flattenedColumns.length !== 0) {
        vm.placeholdertext = 'All columns selected';
      } else {
        vm.placeholdertext = 'Add columns';
        if (vm.configureColumnOption && vm.configureColumnOption.name !== 'list') {
          shouldShowTheAddAllButton = true;
        }
      }
      vm.shouldShowAddAllButton = shouldShowTheAddAllButton;
    }

    function selectAllColumns() {
      filterFilter(flattenedColumns, { enabled: false }).forEach(function(column) {
        addColumn(null, column);
      });
    }

  }

})();
