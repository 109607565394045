(function () {
    'use strict';
    angular.module('lucidity', [
        'restangular',
        'flow',
        'ui.bootstrap',
        'ui.select',
        'ui.sortable',
        'ngRoute',
        'ngSanitize',
        'ngStorage',
        'angular-ladda',
        'checklist-model',
        'dndLists',
        'ui.tree',
        'angularSpectrumColorpicker',
        'ngMap',
    ]);
})();
