(function () {
    'use strict';

    angular.module('lucidity').factory('ReorderPageService', ReorderPageService);

    ReorderPageService.$inject = ['Restangular'];

    function ReorderPageService(Restangular) {

        return {
            getManagementSystemPages: getManagementSystemPages,
            alphabeticSort: alphabeticSort,
            alphabeticSortPagesByManagementSystem: alphabeticSortPagesByManagementSystem,
            moveAfterSibling: moveAfterSibling,
            moveToStartOfParent: moveToStartOfParent,
            moveToEndOfParent: moveToEndOfParent,
        };

        function getManagementSystemPages(managementSystemId) {
            return Restangular.one('management', managementSystemId).one('pageorder').get();

        }

        function moveAfterSibling(pageId, siblingId) {
            return Restangular.one('pages', pageId).one('moveaftersibling', siblingId).put();
        }

        function moveToStartOfParent(pageId, parentId) {
            var baseCall = Restangular.one('pages', pageId);
            if (parentId !== undefined) {
                return baseCall.one('movetostartofparent', parentId).put();
            } else {
                return baseCall.one('movetostartofparent').put();
            }
        }

        function moveToEndOfParent(pageId, parentId) {
            return Restangular.one('pages', pageId).one('movetoendofparent', parentId).put();
        }

        function alphabeticSort(pageId) {
            return Restangular.one('pages', pageId).one('alphabeticsort').put();
        }

        function alphabeticSortPagesByManagementSystem(managementSystemId) {
            return Restangular.one('managementsystem', managementSystemId).one('alphabeticsort').put();
        }
    }
})();
