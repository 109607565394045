// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
(function () {
    'use strict';

    angular.module('lucidity').factory('WidgetService', WidgetService);

    WidgetService.$inject = ['Restangular'];

    function WidgetService(Restangular) {

        return {

            widgetdisplay: widgetdisplay,
        };

        function widgetdisplay(config) {

            return Restangular.one('widget', config.uuid).customPUT(config.display);
        }




    }
})();
