(function () {
  'use strict';
  angular.module('lucidity').directive('dateRangePickerBackdrop', dateRangePickerBackdrop);

  function dateRangePickerBackdrop() {
    var directive = {
        restrict: 'EA',
        replace: true,
        template: '<div class="date-range-picker-backdrop" ng-show="isOpen" ng-click="close()"></div>',
      };
    return directive;
  }
})();
