(function () {
    'use strict';
    angular.module('lucidity').directive('ngApproval', [
        'EventService',
        '_',
        function (EventService, _) {
            var directive = {};
            directive.restrict = 'AE';
            directive.templateUrl = '/app/element/approval/approval.html';
            directive.require = ['?ngModel'];
            directive.scope = {
                element: '@?',
                approver: '@?',
                approved: '@?',
                signature: '@?',
                class: '@?',
                signable: '@?',
                currentuser: '@?',
                modifiedat: '@?'
            };
            directive.link = function (scope, element, attr, ngModel) {
                scope.canvasModel = '';
                scope.scope = scope;
                scope.hasApprover = (scope.approver !== undefined && scope.approver !== '');
                scope.showCancelButton = showCancel();
                scope.showDisable = showDisable();

                if (scope.approved) {
                    scope.canvasClass = scope.class + ' canvas-approved';
                } else {
                    scope.canvasClass = scope.class
                }
                EventService.on('element.update', scope.element, function (event, data) {
                    var approverId = _.nested(data.extendedValue, 'id');
                    if (approverId) {
                        scope.approver = approverId;
                        scope.hasApprover = true;
                        scope.showCancelButton = showCancel();
                        scope.showDisable = showDisable();
                    }
                });

                /**
                 * Show Cancel button only if user can sign, and element is not approved yet
                 * @returns {string|boolean}
                 */
                function showCancel() {
                    if (scope.approved) {
                        return false
                    } else {
                        var hasApprover = (scope.approver !== undefined && scope.approver !== '');
                        var newApproverCanSign = (scope.approver === scope.currentuser);
                        return (hasApprover && (scope.signable && newApproverCanSign));
                    }
                }

                function showDisable() {
                    if (scope.approved) {
                        return false;
                    } else {
                        var newApproverCanSign = (scope.approver === scope.currentuser);
                        return (!scope.hasApprover || !(scope.signable && newApproverCanSign));
                    }

                }
            };
            return directive;
        },
    ]);
})();
