(function () {
  'use strict';
  angular.module('lucidity').directive('ngBuildLegacyFancySelect', [
    '_',
    function (_) {
      var directive = {};
      directive.require = '^ngLegacyFancySelect';
      directive.link = function ($scope, elem, attrs, fancyCtrl) {
        elem.multifancy({ runtimeConfig: fancyCtrl.getDisplayOptions() });
        elem.multifancy('addKeyValueOptions', fancyCtrl.getInitialOptions());
        elem.multifancy('setValue', fancyCtrl.getSelectedValues());
        elem.multifancy('setOnChange', function () {
          $scope.$apply(function () {
            var labels = elem.multifancy('getSelectedLabels');
            var values = elem.multifancy('getSelectedValues');
            var newModel = [];
            _.each(values, function (val, key) {
              newModel.push({ key: val, value: labels[key] });
            });

            fancyCtrl.updateModel(newModel);
          });
        });
      };

      return directive;
    },
  ]);
})();
