(function () {
    'use strict';
    angular
        .module('lucidity')
        .controller('reviewAndFinaliseController', [
        '$scope',
        '$http',
        '$modal',
        'ModalService',
        '$location',
        function($scope, $http, $modal, ModalService, $location) {

            $scope.base_url = $location.$$protocol + '://' + $location.$$host;
            $scope.email = null;
            $scope.review = {}
            $scope.review.isTested = false;
            $scope.review.isEnabled = false;
            $scope.review.template_id = null
            $scope.statusMessage = '';
            $scope.loading = false;
            $scope.hasEmail = true;
            $scope.template = {}
            $scope.isCreateTemplate = true;

            $scope.$on("eventSendTriggerToReviewAndFinalize", function (events, data) {
                $scope.template = data;

                if ('undefined' != typeof data.isUpdateTemplate) {
                    $scope.isCreateTemplate = false;
                    $scope.isUpdateTemplate = data.isUpdateTemplate;
                }
            });

            $scope.$on("eventTriggerHasChanged", function (events, data) {
                if(data){
                    $scope.review.isTested = false;
                    $scope.review.isEnabled = false;
                    $scope.review.template_id = null
                    $scope.statusMessage = '';
                }
            });

            $scope.$on("emailSentSuccessful", function (events){
                $scope.statusMessage = 'email sent successfully';
                $scope.review.isTested = true;
                $scope.loading = false;
            })


            $scope.save = function () {
                $scope.$emit('eventReviewAndFinalizeController', $scope.review );
            }

            $scope.editAndTestTemplate = function() {
                $scope.$emit('eventUpdateAndTest');
            }

            $scope.getEmail = function(){
                $http({
                    method  : 'POST',
                    url     :  $scope.base_url+'/intranet/api/v1/notification/get-login-user',
                    dataType: 'json',
                })
                    .success(function(response) {
                        $scope.email = response.data.email;
                        if ($scope.email == null || $scope.email == '') {
                            $scope.statusMessage = 'You have no email address';
                        } else {
                            $scope.hasEmail = false;
                        }

                    }).error(function(response) {
                        $scope.statusMessage = 'this user has no email address';
                        alert('An error has occured. Please check the log for details');
                });

            }

            $scope.getEmail();

            $scope.getId = function(){
                var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                    sURLVariables = sPageURL.split('&'),
                    sParameterName,
                    i;

                for (i = 0; i < sURLVariables.length; i++) {
                    sParameterName = sURLVariables[i].split('=');

                    if (sParameterName[0] === 'template_id') {
                        return sParameterName[1] === undefined ? true : sParameterName[1];
                    }
                }
            }

            $scope.openModal = function () {

                var controllerOptions = {
                    title: 'Message details',
                    templateUrl: '/app/notification/confirm-send-email-test.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$modalInstance', 'email', function ($scope, $modalInstance, $email) {
                            $scope.confirm = function () {
                                $modalInstance.close();
                            };
                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }
                    ],
                    resolve: {
                        email: function () {
                            return $scope.email;
                        }
                    }
                };

                var modalInstance = $modal.open(controllerOptions);

                modalInstance.result.then(function () {
                    $scope.loading = true;
                    $scope.statusMessage = ' sending your test email';

                    if ('undefined' == typeof $scope.isUpdateTemplate) {
                        $scope.sendTestEmail();
                    } else {
                        $scope.editAndTestTemplate();
                    }
                });

                return false;
            };

            $scope.sendTestEmail = function () {

                $http({
                    method  : 'POST',
                    url     :  $scope.base_url+'/intranet/api/v1/notification/send-test-template',
                    data    :  { template: $scope.template },
                    dataType: 'json',
                })
                    .success(function(response) {
                        $scope.review.template_id = response.data.template_id;
                        $scope.$emit('eventSendTemplateIdToParent', $scope.review.template_id);
                        $scope.statusMessage = 'email sent successfully';
                        $scope.review.isTested = true;
                        $scope.loading = false;


                    }).error(function(response) {
                        $scope.statusMessage = 'failed to send email';
                        $scope.review.isTested = false;
                        $scope.loading = false;
                });
            }

            $scope.openConfirmationCancelModal = function () {
                $scope.$emit('eventCancelNotification', $scope.review.template_id);
            }

        }
    ]);
})();
