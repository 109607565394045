(function () {
    'use strict';
    angular
        .module('lucidity').
        controller('editTemplateController', [
        '$scope',
        '$http',
        '$modal',
        'ModalService',
        '$location',
        '$localStorage',
        '$timeout',
        function($scope, $http, $modal, ModalService, $location, $localStorage) {

            var urlParams = new URLSearchParams(window.location.search);
            $scope.base_url = $location.$$protocol + '://' + $location.$$host;
            $scope.templateId = urlParams.get('template_id');
            $scope.validationMessage = '';
            $scope.parent = {};

            $scope.conditionTemplate = "/app/notification/filter/default.html";

            $scope.parent.template_id = urlParams.get('template_id');
            $scope.parent.trigger_id = null;
            $scope.parent.template = {};
            $scope.parent.filter = {};
            $scope.parent.recipient = [];
            $scope.parent.review = {};
            $scope.parent.isUpdateTemplate = true;

            $scope.$on('receiveTemplateDetails', function (events, args) {
                $scope.parent.template  = args;
            });

            $scope.$on("receiveFilterDetails", function (events, args) {
                $scope.parent.filter  = args;
            });

            $scope.$on("receiveRecipientsDetails", function (events, args) {
                $scope.parent.recipient  = args;
            });

            $http.get($scope.base_url+'/intranet/api/v1/notification-template/'+$scope.templateId)
                .then(function success(srv_resp) {
                        var templateDetails = srv_resp.data.data;
                        $scope.loading = 0;
                        $scope.parent.trigger_id = templateDetails.Triggers.data[0].id;
                        $scope.parent.template.subject = templateDetails.subject;
                        $scope.parent.template.body = templateDetails.messageBody;
                        $scope.parent.filter.expression = templateDetails.conditions;
                        $scope.parent.filter.modules = JSON.parse(templateDetails.modules);
                        $scope.parent.template.attachment = templateDetails.attachment;
                        $scope.parent.triggers = templateDetails.Triggers.data;

                        for (var userIndex = 0; userIndex < templateDetails.UserRecipients.data.length; userIndex++) {
                            $scope.parent.recipient.push(templateDetails.UserRecipients.data[userIndex]);
                        }

                        for (var groupIndex = 0; groupIndex < templateDetails.GroupRecipients.data.length; groupIndex++) {
                            $scope.parent.recipient.push(templateDetails.GroupRecipients.data[groupIndex]);
                        }

                        for (var roleIndex = 0; roleIndex < templateDetails.RoleRecipients.data.length; roleIndex++) {
                            $scope.parent.recipient.push(templateDetails.RoleRecipients.data[roleIndex]);
                        }

                        $scope.$broadcast('eventSendTriggerToPlaceholder', $scope.parent);
                        $scope.$broadcast('eventSendTriggerToConditions', $scope.parent);
                        $scope.$broadcast('eventSendTriggerToAddRecipients', $scope.parent);
                        $scope.$broadcast('eventSendTriggerToReviewAndFinalize', $scope.parent);

                    }, function failed(srv_resp) {
                        $scope.loading = 0;
                    }
                );


            $scope.$on('eventUpdateAndTest', function () {

                $scope.$broadcast('requestTemplateDetails');
                $scope.$broadcast('requestFilter');
                $scope.$broadcast('requestRecipients');

                $http.post($scope.base_url+'/intranet/api/v1/notification/edit-test-template?template_id='+$scope.templateId, { 'notificationInputs': $scope.parent })
                    .then(function success(srv_resp) {

                            var errors = srv_resp.data.data;
                            $scope.validationMessage = '';

                            if (true === errors) {

                                $scope.$broadcast('emailSentSuccessful');

                            } else {

                                for (var idx = 0; idx < errors.length; idx++) {
                                    $scope.validationMessage += errors[idx]+'<br/>';
                                }

                            }

                        }, function failed(srv_resp) {
                            $scope.loading = 0;
                        }
                    );
            });

            $scope.editTemplate = function() {

                $scope.$broadcast('requestTemplateDetails');
                $scope.$broadcast('requestFilter');
                $scope.$broadcast('requestRecipients');

                $http.post($scope.base_url+'/intranet/api/v1/notification/edit-template?template_id='+$scope.templateId, { 'notificationInputs': $scope.parent })
                    .then(function success(srv_resp) {

                        $localStorage.edit_notification = $scope.parent.template.subject

                        var errors = srv_resp.data.data;
                        $scope.validationMessage = '';

                        if (true === errors) {
                            window.location = $scope.base_url+'/system/notificationtemplate/templatelist';
                        } else {

                            for (var idx = 0; idx < errors.length; idx++) {
                                $scope.validationMessage += errors[idx]+'<br/>';
                            }

                        }

                        }, function failed(srv_resp) {
                            $scope.loading = 0;
                        }
                    );
            }

            $scope.backToTemplates = function() {
                window.location = $scope.base_url+'/system/notificationtemplate/templatelist';
            }
        }
    ]);
})();
