(function () {
  'use strict';
  angular.module('lucidity').directive('ngDatepicker', [
    '_',
    function (_) {
      var directive = {};
      directive.templateUrl = '/app/element/datepicker/date-picker.html';
      directive.restrict = 'AE';
      directive.require = '?ngModel';
      directive.scope = {
        id: '@',
        name: '@',
        placeholder: '@',
        dateFromString: '@?',
        required: '@',
        model: '=?ngModel',
      };

      directive.link = function (scope, elem, attrs, ngModel) {
        scope.isDateInputFocussed = false;
        scope.displayDate = '';
        scope.isOpen = false;
        scope.dateOptions = {
          formatYear: 'yy',
          startingDay: 1,
        };
        scope.dateModel = null;
        scope.model = scope.model || undefined;
        scope.dateFromString = scope.dateFromString || null;
        if (scope.model) {
          var parsedDate = moment(scope.model, 'YYYY-MM-DD');
          scope.dateModel = parsedDate.toDate();
          scope.displayDate = parsedDate.format('DD/MM/YYYY');
        } else if (scope.dateFromString !== null) {
          scope.model = scope.dateFromString;
          scope.dateModel = new Date(scope.dateFromString);
        }

        scope.$watch('dateModel',
          function (newValue, oldValue) {
            if (newValue !== oldValue) {
              scope.updateDate();
            }
          }
        );

        scope.$watch('model', function (newValue, oldValue) {
          if (ngModel) {
            if (newValue !== oldValue) {
              ngModel.$setViewValue(newValue);
            }
            /**
             * Only check required validator status if user enabled required validator
             * @type {boolean|*}
             */
            var validity = (!scope.required || (scope.required && newValue !== undefined));
            ngModel.$setValidity('required',validity);
          }
        });

        elem.find('.focusser').on('focus', function ($event) {
          scope.$apply(function () {
            scope.focusDateInput();
          });
        });

        scope.updateFromStringAndClose = function () {
          scope.updateFromString();
          scope.close();
        };

        scope.focusDateInput = function () {
          scope.isDateInputFocussed = true;
        };

        scope.updateFromString = function () {
          if (_.isString(scope.displayDate)) {
            if (scope.displayDate.replace(/\s+/g) === '') {
              scope.dateModel = null;
              return;
            }
          }

          var parsedDate = moment(scope.displayDate, ['DD/MM/YYYY', 'D/M/YY', 'D/M/YYYY'], true);
          if (parsedDate.isValid()) {
            scope.dateModel = parsedDate.toDate();
          }

          scope.updateDate();
        };

        scope.updateDate = function () {
          scope.isDateInputFocussed = false;
          if (scope.dateModel) {
            scope.displayDate = moment(scope.dateModel).format('DD/MM/YYYY');
            scope.model = moment(scope.dateModel).format('YYYY-MM-DD');
          } else {
            scope.displayDate = '';
            scope.model = undefined;
          }
        };

        function open() {
          scope.isOpen = true;
          scope.focusDateInput();
        }

        scope.open = function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          open();
        };

        scope.close = function () {
          scope.isDateInputFocussed = false;
          scope.isOpen = false;
        };
      };

      return directive;
    },
  ]);
})();
