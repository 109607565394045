(function () {
    'use strict';

    angular.module('lucidity').controller('PageOrderController', PageOrderController);

    PageOrderController.$inject = ['ReorderPageService', 'MessageService', '$scope', '$timeout', 'treeConfig', 'UiTreeHelper', '_'];

    function PageOrderController(ReorderPageService, MessageService, $scope, $timeout, treeConfig, UiTreeHelper, _) {

        var vm = this;
        var mouseOverTimer;
        var draggingNode = undefined;
        var hoverOverNode = undefined;

        vm.data = [];
        vm.managementSystemId = null;
        vm.maxPageNesting = 9;
        vm.loading = false;
        vm.hovering = false;
        vm.draging = false;

        // access to function from ui-tree.
        // Form more see: https://github.com/angular-ui-tree/angular-ui-tree
        $scope.reorderTree = {
            beforeDrop: beforeDrop,
            dropped: dropped,
            dragStop: dragStop,
            dragStart: dragStart,
            dragMove: dragMove,
            accept: accept,
        };

        vm.load = load;
        vm.collapsed = collapsed;
        vm.hasChildren = hasChildren;
        vm.mouseEnter = mouseEnter;
        vm.mouseLeave = mouseLeave;
        vm.alphabeticSort = alphabeticSort;

        /* public functions */
        function load(managementSystemId) {
            treeConfig.defaultCollapsed = true;

            vm.loading = true;
            vm.managementSystemId = managementSystemId;
            ReorderPageService.getManagementSystemPages(vm.managementSystemId).then(function (results) {
                vm.data[0] = results.data.plain();
                vm.loading = false;
            });
        }

        function collapsed(page) {
            return hasChildren(page) ? 'true' : 'false';
        }

        function hasChildren(page) {
            return page.children != undefined && page.children.data !== undefined && page.children.data.length > 0;
        }

        function mouseEnter(node) {
            startHover(node);
            if (node.$nodeScope.collapsed && vm.dragingg) {
                mouseOverTimer = $timeout(function () {
                    node.$nodeScope.toggle(node);
                }, 350);
            }
        }

        function mouseLeave(node) {
            if (mouseOverTimer !== undefined) {
                $timeout.cancel(mouseOverTimer);
            }

            endHover();
        }

        function alphabeticSort(node, item) {
            node.sortingAlphabetically = true;
            node.$nodeScope.expand();
            node.$nodeScope.$element.removeClass('just-dropped');
            var promise = null;
            //Check to see if ordering entire management system or just children of a page.
            var topLevelSorting = false;
            if (item.managementSystemId === undefined) {
                topLevelSorting = true;
                promise = ReorderPageService.alphabeticSortPagesByManagementSystem(item.id);
            } else {
                promise = ReorderPageService.alphabeticSort(item.id);
            }

            promise.then(function (response) {
                var plainResponse = response.data.plain();
                node.$nodeScope.$modelValue.children.data = (topLevelSorting) ? plainResponse.children.data : plainResponse;
                node.$nodeScope.expand();
                node.sortingAlphabetically = false;
                node.$nodeScope.$element.addClass('just-dropped');
                MessageService.success('Pages have been reordered');
                $timeout(function () {
                    node.$nodeScope.$element.removeClass('just-dropped');
                }, 900);
            });
        }

        /*ui.tree callbacks */

        function accept(sourceNodeScope, destNodesScope) {
            var targetPageDepth = destNodesScope.depth();
            var subPageDepth = sourceNodeScope.maxSubDepth();
            if ((targetPageDepth + subPageDepth) > vm.maxPageNesting) {
                MessageService.error('Unable to nest more than 8 pages.');
                return false;
            }

            if (targetPageDepth === 0 && vm.hovering != true) {
                return false;
            }

            return true;
        }

        function beforeDrop(event) {
            //If hover out side of the root
            if (event.dest.nodesScope.$parent.$modelValue === undefined) {
                return false;
            }

            if (vm.hovering && isInTarget(hoverOverNode.$nodeScope.$childNodesScope.$modelValue, event.source.nodeScope.$modelValue)) {
                return false;
            }

            //if hover over node has children and is not expanded.
            if (vm.hovering === true && hoverOverNode !== undefined) {
                if (hoverOverNode.collapsed === true) {
                    hoverOverNode.$nodeScope.toggle(hoverOverNode);
                }

                $scope.reorderTree.dropped(droppedOnParent(event));
                return false;

            }
            //Terminate if dragging and dropping at the same place.
            if (hoverOverNode === undefined && event.source.index === event.dest.index) {
                if (event.source.nodesScope.$parent.$modelValue === event.dest.nodesScope.$parent.$modelValue) {
                    endHover();
                    return false;
                }
            }

            return true;
        }

        function dropped(event) {
            var page = event.source.nodeScope.$modelValue;
            var moveAfterPage;
            var parentPage;

            if (hoverOverNode !== undefined) {
                parentPage = hoverOverNode.$nodeScope.$modelValue;
                if (parentPage !== undefined && parentPage.id !== undefined) {
                    ReorderPageService.moveToEndOfParent(page.id, parentPage.id);
                }
            } else if (event.dest.index > 0) {
                var pages = event.dest.nodesScope.$modelValue;
                if (pages.length > (event.dest.index - 1)) {
                    moveAfterPage = pages[event.dest.index - 1];
                }
                if (moveAfterPage !== undefined && moveAfterPage.id !== undefined) {
                    ReorderPageService.moveAfterSibling(page.id, moveAfterPage.id);
                }
            } else if (event.dest.index === 0) {
                parentPage = event.dest.nodesScope.$parent.$modelValue;
                if (parentPage !== undefined && parentPage.id !== undefined) {
                    if (parentPage.managementSystemId !== undefined) {
                        ReorderPageService.moveToStartOfParent(page.id, parentPage.id);
                    } else {
                        ReorderPageService.moveToStartOfParent(page.id);
                    }
                }
            }

            MessageService.success('Page has been reordered');
            event.source.nodeScope.$element.addClass('just-dropped');
            endHover();
            $timeout(function () {
                event.source.nodeScope.$element.removeClass('just-dropped');
            }, 900);
        }

        function dragStart(event) {
            event.source.nodeScope.$element.removeClass('just-dropped');
            vm.dragging = true;
            draggingNode = event.source.nodeScope;
        }

        function dragMove(event) {
            //If hover out side of the root
            if (event.dest.nodesScope.$parent.$modelValue === undefined) {
                event.elements.placeholder.hide();
            }

            if (hoverOverNode !== undefined) {
                if (hoverOverNode.hover && hoverOverNode.collapsed && hoverOverNode.$childNodesScope.childNodesCount() > 0) {
                    hoverOverNode.expanding = true;
                    $timeout(function () {
                        if (hoverOverNode !== undefined) {
                            hoverOverNode.expanding = false;
                        }
                    }, 900);

                    $timeout(function () {
                        if (hoverOverNode !== undefined && hoverOverNode.collapsed === true) {
                            hoverOverNode.$nodeScope.toggle(hoverOverNode);
                            hoverOverNode.expanding = false;
                        }
                    }, 1000);
                } else {
                    hoverOverNode.expanding = false;
                }
            }
        }

        function dragStop(event) {
            vm.dragging = false;
            draggingNode = undefined;
            endHover();
        }

        function isInTarget(siblingPages, page) {
            if (siblingPages.length > 0) {
                _.forEach(siblingPages, function (siblingPage, key) {
                    if (siblingPage.id === page.id) {
                        return true;
                    } else if (siblingPage.children && siblingPage.children.data.length > 0) {
                        return isInTarget(siblingPage.children.data, page);
                    }
                });
            }

            return false;
        }

        function droppedOnParent(event) {
            console.log('Dropped');
            var dragInfo = UiTreeHelper.dragInfo(event.source.nodeScope);
            dragInfo.moveTo(
                hoverOverNode.$nodeScope.$childNodesScope,
                hoverOverNode.$nodeScope.childNodes(),
                hoverOverNode.$nodeScope.childNodesCount()
            );
            dragInfo.apply();
            return dragInfo.eventArgs(event.elements, event.pos);
        }

        function startHover(node) {
            if (node && vm.dragging && node !== vm.hovering) {
                node.hover = true;
                vm.hovering = true;
                hoverOverNode = node;
            }
        }



        function endHover() {
            if (hoverOverNode !== undefined) {
                hoverOverNode.hover = false;
            }

            hoverOverNode = undefined;
            vm.hovering = false;
        }
    }

})();
