(function () {
  'use strict';

  angular.module('lucidity').factory('columnConfigService', columnConfigService);

  columnConfigService.$inject = ['Restangular', 'hashService', '$q'];

  function columnConfigService(Restangular, hashService, $q) {
    var columnConfigs = {};
    return {
      getColumnConfig: getColumnConfig,
      saveColumnConfigs: saveColumnConfigs,
      saveColumnConfig: saveColumnConfig,
      resetColumnConfig: resetColumnConfig,
    };

    function getColumnConfig(uri) {
      var params = { uri: uri };
      var hashCode = hashService.objectHashCode(params);
      if (columnConfigs[hashCode] === undefined) {
        columnConfigs[hashCode] = Restangular.one('columnconfig').get({ uri: uri });
      }

      return columnConfigs[hashCode];

    }

    function saveColumnConfigs(columnConfigs) {
      return $q.all(
        columnConfigs.map(function (columnConfig) {
          return saveColumnConfig(columnConfig);
        })
      );
    }

    function saveColumnConfig(columnConfig) {
      return columnConfig.put();
    }

    function resetColumnConfig(columnConfig) {
      return columnConfig.remove({ uri: columnConfig.data.uri });
    }

  }

})();
