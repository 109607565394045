(function () {
  'use strict';
  angular.module('lucidity').directive('ngSubmitAs', SubmitAs);

  SubmitAs.$inject = ['$'];

  function SubmitAs($) {
    var directive = {};
    directive.restrict = 'A';
    directive.link = link;
    return directive;

    function link(scope, elem, attrs) {
      var method = attrs.ngSubmitAs || 'PUT';
      elem.on('click', function () {
        methodElement(elem).val(method);
      });
    }

    function methodElement(elem) {
      var parentForm = elem.parents('form');
      var methodElement = parentForm.find('[name=_method]');

      if (methodElement.length === 1) {
        return methodElement;
      }

      return $('<input type="hidden" name="_method" />').appendTo(parentForm);
    }
  }

})();
