/*
    See: https://github.com/angular-ui/ui-select/issues/2048
 */
(function () {
    'use strict';
    angular.module('lucidity').filter('safehighlight', function() {
        function escapeHtml(text) {
            return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }

        function escapeRegexp(queryToEscape) {
            return ('' + queryToEscape).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
        }

        return function (matchItem, query) {
            var matchHtml = escapeHtml('' + matchItem);
            return query && matchItem ? matchHtml.replace(new RegExp(escapeRegexp(query), 'gi'), '<span class="ui-select-highlight">$&</span>') : matchHtml;
        };
    });
})();
