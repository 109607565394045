(function () {
  'use strict';
  angular.module('lucidity').directive('ngLegacyFancySelect', [
      '_',
      'ValueParseService',
      function (_, ValueParseService) {
        var directive = {};
        directive.templateUrl = '/app/element/legacy-fancy-select/legacy-fancy-select.html';
        directive.require = '?ngModel';
        directive.scope = {
          name: '@?',
          displayOptions: '@?',
          selectedOptions: '@?',
          model: '=?ngModel',
          multipleValues: '=?multipleValues'
        };

        directive.link = function (scope, elem, attrs, ngModel) {
          if (scope.multipleValues) {
            scope.name = scope.name + '[]';
          }

          scope.$watch('model', function (newValue, oldValue) {
            if (ngModel) {
              if (newValue !== oldValue) {
                ngModel.$setViewValue(newValue);
              }

              ngModel.$setValidity('required', newValue != false);
            }
          });
        };

        directive.controller = ['$scope', function ($scope) {
          var parsedOptions = null;

          function getSelectedOptions() {
            if (parsedOptions === null) {
              if ($scope.model) {
                parsedOptions = $scope.model;
              } else if ($scope.selectedOptions) {
                parsedOptions = ValueParseService.jsonParse($scope.selectedOptions);
              }
            }

            return parsedOptions;
          }

          this.getDisplayOptions = function () {
            return ValueParseService.jsonParse($scope.displayOptions);
          };

          this.getSelectedValues = function () {
            var values = [];
            var opts = getSelectedOptions();
            if (opts) {
              _.each(opts, function (opt) {
                values.push(opt.key);
              });
              return values;
            }
          };

          this.getName = function () {
            return $scope.name;
          };

          this.getInitialOptions = function () {
            return getSelectedOptions();
          };

          this.updateModel = function (data) {
            if ($scope.model) {
              $scope.model = data;
            }
          };
        },];

        return directive;
      }
    ]
  )
  ;
})

();
