(function () {
  'use strict';
  angular.module('lucidity').controller('WeatherWidgetController', [
    '$scope',
    '$http',
    'Restangular',
    'MessageService',
    'ModalService',
    '_',
    '$modalInstance',
    '$modalOptions',
    function ($scope, $http, Restangular, MessageService, ModalService, _, $modalInstance, $modalOptions) {
      ModalService.init($scope, $modalInstance, $modalOptions).loadBody();
      $scope.config = $modalOptions.config;
      $scope.disabled = { ok: false };
      $scope.preferences = {
        location: '',
        state: '',
        country: '',
      };

      $scope.loadPreferences = function (preferences) {
        if (preferences !== null) {
          $scope.preferences = preferences;
        }
      };

      $scope.callback('ok', function ($parentScope, $modalInstance) {
        $scope.form.weatherForm.$setDirty();
        if ($scope.form.weatherForm.$invalid) {
          return;
        }

        $http.post($scope.config.editUrl, $scope.preferences).success(function (response) {
          $modalInstance.close($scope);
        });
      });

      $scope.$watch('form.weatherForm.$invalid', function (validity) {
        $scope.disabled.ok = validity;
      });
    },
  ]);

})();

