// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
(function () {
    'use strict';

    angular.module('lucidity').factory('FilingCabinetService', FilingCabinetService);

    FilingCabinetService.$inject = ['Restangular', 'TreeFactory'];

    function FilingCabinetService(Restangular, TreeFactory) {

        var filingCabinet = Restangular.all('filingcabinet');

        return {
            getItemPermissions: getItemPermissions,
            getItemNestedPermissions: getItemNestedPermissions,
            saveReview: saveReview,
            getCurrentReviews: getCurrentReviews,
            deleteReview: deleteReview,
            generateFileLink: generateFileLink,
            deleteFileLink: deleteFileLink,
            fileLinkvisibility: fileLinkvisibility,
        };

        function getItemPermissions(id, targetId, type) {
            return filingCabinet.one('permissions', id).one('type', type).one('target', targetId).get();
        }

        function getItemNestedPermissions(id, targetId, type) {

            return filingCabinet.one('permissions', id).one('type', type).one('target', targetId).get({includeNested: true});
        }

        function saveReview(item, review) {
            if (review.id === null) {
                return filingCabinet.one('files', item.data.id).post('reviews', review);
            } else {
                return filingCabinet.one('files', item.data.id).one('reviews', review.id).customPUT(review);
            }
        }

        function getCurrentReviews(item) {
            return filingCabinet.one('files', item.data.id).getList('reviews');
        }

        function deleteReview(item, review) {
            return filingCabinet.one('files', item.data.id).one('reviews', review.id).remove();
        }

        function generateFileLink(item) {

            return Restangular.one('files', item.data.file.data.id).post('links', []);
        }

        function deleteFileLink(file) {

            return Restangular.one('files', file.data.id).one('links', file.data.currentUserFileLink.id).remove();
        }

        function fileLinkvisibility(file) {

            return Restangular.one('files', file.data.id).one('links', file.data.currentUserFileLink.id).customPUT(file.data.currentUserFileLink);
        }


    }
})();
