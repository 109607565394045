(function () {
  'use strict';
  angular.module('lucidity').controller('FormController', [
    '$scope',
    'ModelTransactionQueueService',
    'AutoSaveService',
    'MessageService',
    'moment',
    function ($scope, ModelTransactionQueueService, AutoSaveService, MessageService, moment) {
      $scope.model = $scope.model || {};
      $scope.queue = false;
      $scope.autoSaving = false;
      $scope.autoSaved = false;

      $scope.modelUpdated = function (id, value) {
        var modelTransaction;
        modelTransaction = ModelTransactionQueueService.createModelTransaction(id, value);
        ModelTransactionQueueService.addToQueue(modelTransaction);
        $scope.queue = ModelTransactionQueueService.getQueue();
      };

      $scope.autoSave = function (autoSaveObjectId) {
        if (autoSaveObjectId === undefined) {
          return;
        }

        //Displays "Saving..." text message.
        $scope.autoSaving = true;
        $scope.autoSaved = false;

        var queue = ModelTransactionQueueService.getQueue();

        //Call auto save service to save the queue
        AutoSaveService.autoSaveQueue(autoSaveObjectId, queue).then(function (response) {
          var responseData = response.data;
          if (responseData && responseData.id !== null) {
            MessageService.success('Autosaved at ' + moment().format('DD/MM/YYYY hh:mm a'));
            window.console.log('Autosaved at ' + moment().format('DD/MM/YYYY hh:mm a'));
            $scope.autoSaved = true;
            ModelTransactionQueueService.flushQueue();
          } else {
            window.console.log('Invalid route specified');
          }
        }, function (response) {

          window.console.log('Error in auto saving form');
          MessageService.error('Error in auto saving form');
        });

        //handles error

        //Displays "Draft auto saved" text message.
        $scope.autoSaving = false;
      };
    },
  ]);
})();

