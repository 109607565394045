(function () {
  'use strict';
  angular.module('lucidity').factory('ValueParseService', [
      '_',
        function (_) {

          var service = {};

          service.booleanParse = function (value) {
            value = service.jsonParse(value);
            if (typeof (value) === 'boolean') {
              return value;
            } else if (value === 1) {
              return true;
            }

            return false;
          };

          service.nestedScope = function (scope, value) {
            return _.nested(scope, value);
          };

          service.jsonParse = function (value, defaultValue) {
            try {
              return JSON.parse(value);
            } catch (e) {
              return defaultValue;
            }
          };

          service.isPrefixedKey = function (prefix, key) {
            return ('' + key).match(prefixRegex(prefix));
          };

          service.removePrefixes = function (prefix, values) {
            var updatedValues = {};
            var keyMatcher = prefixRegex(prefix);
            _(values).forEach(function (value, key) {
              if (('' + key).match(keyMatcher) !== null) {
                key = removePrefixRegex(keyMatcher, key);
              }

              updatedValues[key] = value;
            });

            return updatedValues;
          };

          service.removePrefix = function (prefix, key) {
            return removePrefixRegex(prefixRegex(prefix), key);
          };

          service.injectAttribute = function (scope, scopeKey, attribute) {

            if (attribute !== undefined) {
              //Parse json strings
              attribute = service.jsonParse(attribute);

              if (_.isFunction(scope[scopeKey])) {
                scope[scopeKey](attribute);
              } else if (scope[scopeKey] !== undefined) {
                scope[scopeKey] = attribute;
              } else if (_.isFunction(scope.setAttribute)) {
                scope.setAttribute(scopeKey, attribute);
              }
            }
          };

          function prefixRegex(prefix) {
            prefix = prefix === 'data' ? '' : prefix;
            return RegExp('^' + prefix + '[-]?');
          }

          function removePrefixRegex(prefixRegexMatcher, key) {
            var updatedKey = key.replace(prefixRegexMatcher, '');
            return updatedKey.charAt(0).toLowerCase() + updatedKey.slice(1);
          }

          return service;
        },
    ]);
})();
