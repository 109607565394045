(function () {
    'use strict';
    angular.module('lucidity').directive('ngFancySelect', [
        '_',
        'ValueParseService',
        '$interpolate',
        '$compile',
        '$parse',
        function (_, ValueParseService, $interpolate, $compile, $parse) {

            var directive = {};
            var templateSingleSelect = '/app/element/fancy-select/single.html';
            var templateMultiSelect = '/app/element/fancy-select/multi.html';

            directive.require = "?ngFormIdentifier";

            directive.scope = {
                id: '@?fancyId',
                appendBody: '@?fancyAppendBody',
                name: '@?fancyName',
                allowNewOptions: '=?fancyAllowNewOptions',
                allowTemporaryOptions: '=?fancyAllowTemporaryOptions',
                key: '@?fancyKey',
                multiple: '=?fancyMultiple',
                disabled: '=?fancyDisabled',
                valueTemplate: '@?fancyValueTemplate',
                placeholderText: '@?fancyPlaceholderText',
                placeholder: '=?fancyPlaceholder',
                validationCallback: '=?fancyValidationCallback',
                multiOptions: '=?fancyMultiOptions',
                multiOptionsFromString: '@fancyMultiOptionsFromString',
                postEndpoint: '&?fancyPostEndpoint',
                postProperty: '@?fancyPostProperty',
                getEndpoint: '&?fancyGetEndpoint',
                getEndpointParams: '=?fancyGetEndpointParams',
                getEndpointParamsFromString: '@?fancyGetEndpointParamsFromString',
                value: '&?fancyValue',
                externalModel: '=?ngModel',
                changeCallback: '=?fancyChangeCallback',
                filterCallbackFromString: '@?fancyFilterCallback',
            };

            directive.controller = 'FancySelectController';
            directive.transclude = true;
            directive.restrict = 'AE';
            directive.templateUrl = function (elem, attrs) {
                var multipleSelect = ValueParseService.jsonParse(attrs.fancyMultiple);
                if (multipleSelect === 'true' || multipleSelect) {
                    return templateMultiSelect;
                } else {
                    return templateSingleSelect;
                }
            };

            directive.link = function (scope, elem, attrs, ngFormIdentifier) {
                scope.key = scope.key || 'id';
                scope.required = attrs.required || false;
                scope.allowNewOptions = scope.allowNewOptions || false;

                var getEndpointParser = (scope.getEndpoint() !== undefined) ? $interpolate(scope.getEndpoint()) : null;
                var postEndpointParser = (scope.postEndpoint() !== undefined) ? $interpolate(scope.postEndpoint()) : null;
                scope.getEndpoint = function () {
                    return (getEndpointParser !== null) ? getEndpointParser(_.merge(scope, ngFormIdentifier)) : null;
                };
                scope.postEndpoint = function () {
                    return (postEndpointParser !== null) ? postEndpointParser(_.merge(scope, ngFormIdentifier)) : null;
                };

                scope.multiple = scope.multiple || false;
                scope.placeholder = scope.placeholder || scope.placeholderText || 'Search...';
                scope.validationCallback = scope.validationCallback || function (key, value, data) {
                    return true;
                };

                scope.changeCallback = scope.changeCallback || function (key, value, data) {
                    return true;
                };

                scope.filterCallback = scope.filterCallbackFromString || function (option) {
                    return true;
                };

                if (!_.isFunction(scope.filterCallback)) {
                    var filterExpression = (scope.filterCallback + "").replace(/{/, '{{').replace(/}/, '}}');
                    scope.filterCallback = _.partial($parse(filterExpression), scope.$parent);
                }

                //Initialise internal model from external model
                if (scope.valueTemplate === undefined) {
                    throw 'fancy-value-template must be supplied';
                }

                scope.setValueTemplate(scope.valueTemplate);

                //Multi options must be set so watch doesn't clear option. Also must immediately call setOptions, so that options
                //are initialised for any subsequent value calls
                scope.multiOptions = scope.multiOptions || ValueParseService.jsonParse(scope.multiOptionsFromString, {});
                scope.setMultiOptions(scope.multiOptions);
                if (scope.externalModel !== undefined) {
                    scope.select.model = scope.externalModel;
                    scope.setValue(scope.externalModel, true);
                }

                var value = scope.value();
                if (value !== undefined && value !== null) {
                    scope.setValue(value, true);
                    if (!scope.multiple) {
                        scope.select.model = scope.select.selectedOptions[0];
                    } else {
                        scope.select.model = scope.select.selectedOptions;
                    }
                }

                //Push value out to external model on internal model update
                scope.$watch('multiOptions', function (newValue, oldValue) {
                    scope.setMultiOptions(newValue || []);
                });

                scope.endpointParams = scope.getEndpointParams || ValueParseService.jsonParse(scope.getEndpointParamsFromString, {});

                scope.$watch('select.model', function (newValue, oldValue) {
                    if (_.isObject(newValue)) {
                        newValue._externalModel = true;
                    }
                    scope.externalModel = newValue;
                });

                //Pull the value from external model onto the internal model update
                scope.$watch('externalModel', function (newValue, oldValue) {
                    if (newValue) {
                        if (newValue !== oldValue && newValue._externalModel === undefined) {
                            scope.select.selectedOptions = newValue;
                            scope.select.model = newValue;
                        }
                    }
                });
            };
            return directive;
        },
    ]);
})();
