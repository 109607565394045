(function () {
  'use strict';
  angular.module('lucidity')
  .config([
    'flowFactoryProvider',
    '_',
    'XSRFTOKEN',
    function (flowFactoryProvider, _, XSRFTOKEN) {
      flowFactoryProvider.factory = function (options) {
        options = _.extend({}, {
          simultaneousUploads: 1,
          headers: {
            'X-XSRF-TOKEN': XSRFTOKEN,
            'X-REQUESTED-WITH': 'XMLHttpRequest',
          },
        }, options);
        return new window.fustyFlowFactory(options);
      };
    },
  ])

  .config([
    '$sessionStorageProvider',
    'lucidityConfig',
    '_',

    function ($sessionStorageProvider, lucidityConfig, _) {
      var prefix = _.nested(lucidityConfig, 'session_token', 'ngStorage-');
      $sessionStorageProvider.setKeyPrefix(prefix);
    },

  ])

  .config([
    'uiSelectConfig',
    function (uiSelectConfig) {
      uiSelectConfig.theme = 'bootstrap';
    },
  ])

  .config([
    '$provide',
    'CACHEID',
    function ($provide, CACHEID) {
      $provide.decorator('ngModelDirective', ['$delegate', '_', function ($delegate, _) {
        var directive = $delegate[0];
        var controller = directive.controller;
        directive.controller = ['$scope', '$element', '$attrs', '$injector',
          function (scope, element, attrs, $injector) {
            var $interpolate = $injector.get('$interpolate');
            attrs.$set('name', $interpolate(attrs.name || '')(scope));
            $injector.invoke(controller, this, {
              $scope: scope,
              $element: element,
              $attrs: attrs,
            });
          },
        ];

        return $delegate;
      }, ]);

      $provide.decorator('formDirective', ['$delegate', function ($delegate) {
        var form = $delegate[0];
        var controller = form.controller;
        form.controller = ['$scope', '$element', '$attrs', '$injector',
          function (scope, element, attrs, $injector) {
            var $interpolate = $injector.get('$interpolate');
            attrs.$set('name', $interpolate(attrs.name || attrs.ngForm || '')(scope));
            $injector.invoke(controller, this, {
              $scope: scope,
              $element: element,
              $attrs: attrs,
            });
          },
        ];
        return $delegate;
      }, ]);

      $provide.decorator('$templateCache', ['$delegate', function ($delegate) {

        function cacheBust(cacheId) {
          if (cacheId.indexOf('.html') !== -1) {
            return cacheId + '?' + CACHEID;
          }

          return cacheId;
        }

        var getTemplate = $delegate.get;
        $delegate.get = function (cacheId) {
          return getTemplate(cacheBust(cacheId));
        };

        var putTemplate = $delegate.put;
        $delegate.put = function (cacheId, value) {
          return putTemplate(cacheBust(cacheId), value);
        };

        var removeTemplate = $delegate.remove;
        $delegate.remove = function (cacheId) {
          return removeTemplate(cacheBust(cacheId));
        };

        return $delegate;
      }, ]);

    }, ]
  )

  .config([
    'RestangularProvider',
    '$httpProvider',
    'XSRFTOKEN',
    function (RestangularProvider, $httpProvider, XSRFTOKEN) {
      RestangularProvider.setBaseUrl('/intranet/api/v1');
      $httpProvider.defaults.headers.common['X-XSRF-TOKEN'] = XSRFTOKEN;
      RestangularProvider.setDefaultHeaders({
        'X-XSRF-TOKEN': XSRFTOKEN,
        'X-REQUESTED-WITH': 'XMLHttpRequest',
      });

      //Return all response data (enables POSTs to send objects back)
      RestangularProvider.setFullResponse(true);

      //@TODO This is as suss as all get out
      RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
        var extractedData;

        // .. to look for getList operations
        if (data.data !== undefined && data.data.data !== undefined && data.data.meta !== undefined) {
          // .. and handle the data and meta data
          extractedData = data.data.data;
          extractedData.meta = data.data.meta;
        } else if (data.data !== undefined) {
          extractedData = data.data;
          if (!(typeof extractedData === 'string' || extractedData instanceof String)) {
              extractedData.meta = data.result;
          }
          else{
            extractedData = {};
            extractedData.meta = data.result;
          }
        }
        return extractedData;
      });
    },
  ]);

})();
