(function () {
  'use strict';
  angular.module('lucidity').directive('ngResize', [
    '$interval',
    function ($interval) {
      var directive = {};
      directive.restrict = 'AE';
      directive.link = function (scope, element, attr) {
        var frame = window.$(element);
        $interval(function () {
          if (frame.contents().find('body').outerHeight()) {
            frame.css('height', frame.contents().find('body').outerHeight() + 'px');
          }
        }, 250);
      };

      return directive;
    },
  ]);
})();
