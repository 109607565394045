(function () {
    'use strict';
    angular
        .module('lucidity')
        .controller('recordListController', [
        '$scope',
        '$http',
        '$modal',
        '$filter',
        'ModalService',
        '$location',
        function($scope, $http, $modal, $filter, ModalService, $location) {

            $scope.base_url = $location.$$protocol + '://' + $location.$$host;
            $scope.records = [{}];
            $scope.loading = 1;
            $scope.server_busy = 0;
            $scope.current_page = 1;
            $scope.per_page = 10;
            $scope.total_items = 0;

            $scope.search = {
                "category": "",
                "keyword": "",
                "startDate": "",
                "endDate": ""
            }

            $scope.openCalendar = function ($event, calendarKey) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope[calendarKey] = true;
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                dateFormat: "yy-mm-dd",
                startingDay: 1
            };

            $scope.openModal = function (id) {

                var getRecord = $scope.records.filter(function(item) {
                    return item.id === id;
                })[0];

                var modalOptions = {
                    backdrop: 'static',
                };

                var controllerOptions = {
                    title: 'Message details',
                    templateUrl: '/app/notification/record-modal.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$filter', '$modalInstance','record', function ($scope, $filter, $modalInstance, $record) {
                            $scope.modalRecord = $record;
                            $scope.closeModal = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }
                    ],
                    resolve: {
                        record: function () {
                            return getRecord;
                        }
                    }
                };

                var modalInstance = $modal.open(controllerOptions);

                modalInstance.result.then(function () {

                });

                return false;
            };


            $scope.getId = function(){
                var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                    sURLVariables = sPageURL.split('&'),
                    sParameterName,
                    i;

                for (i = 0; i < sURLVariables.length; i++) {
                    sParameterName = sURLVariables[i].split('=');

                    if (sParameterName[0] === 'id') {
                        return sParameterName[1] === undefined ? true : sParameterName[1];
                    }
                }
            }

            $scope.searchTriggers = function () {

                $scope.records = [{}];

                if ($scope.getId() != undefined) {
                    $scope.search.templateId = $scope.getId();
                }

                $scope.loading = 1;

                $http.post($scope.base_url+'/intranet/api/v1/notification/records/search', $scope.search)
                    .then(function success(srv_resp) {
                        $scope.records = cruse.helper.htmlEncodeObject(srv_resp.data.data);
                        $scope.loading = 0;
                        $scope.total_items = $scope.records.length;
                    }, function failed(srv_resp) {
                        $scope.records = [{}];
                        $scope.records = [];
                        $scope.loading = 0;
                    }
                );
            }

            $scope.searchTriggers();

        }
    ]).filter('trusted', ['$sce', function($sce) {
        var div = document.createElement('div');
        return function(text) {
            div.innerHTML = text;
            return $sce.trustAsHtml(div.textContent);
        };
    }]);
})();
