(function () {
  'use strict';
  angular.module('lucidity').directive('ngHttpPrefix', function () {

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, controller) {
        function ensureHttpPrefix(value) {
          // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
          value = (value || '').replace(/^\s\s*/, '').replace(/\s\s*$/, '');
          if (value && !/^(http|https):\/\//i.test(value)) {
            controller.$setViewValue('http://' + value);
            controller.$render();
            return 'http://' + value;
          } else {
            return value;
          }
        }

        controller.$formatters.push(ensureHttpPrefix);
        controller.$parsers.push(ensureHttpPrefix);
      },
    };
  });
})();
