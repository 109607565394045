(function () {
  'use strict';
  angular.module('lucidity').factory('MessageService', [
      '$rootScope',
      '$interpolate',
      'uuid',
        function ($rootScope, $interpolate, uuid) {
          var service = {};
          service.messages = [];

          service.error = function (message, context) {
            return service.message(message, {
              type: 'danger',
              context: context || {},
            });
          };

          service.success = function (message, context) {
            return service.message(message, {
              type: 'success',
              context: context || {},
            });
          };

          service.warning = function (message, context) {
            return service.message(message, {
              type: 'warning',
              context: context || {},
            });
          };

          service.info = function (message, context) {
            return service.message(message, {
              type: 'info',
              context: context || {},
            });
          };

          service.message = function (message, options) {
            var messageId = uuid.v4();
            var updateMessage = function (message, options) {
              options = options || {};
              var messageFound = false;
              var messageOptions = {
                messageId: messageId,
                message: $interpolate(message)(options.context || {}),
                type: options.type || 'success',
              };

              for (var messageIndex = 0; messageIndex < service.messages.length; messageIndex++) {
                if (service.messages[messageIndex].messageId === messageId) {
                  service.messages[messageIndex] = messageOptions;
                  messageFound = true;
                }
              }

              if (!messageFound) {
                service.messages.push(messageOptions);
              }

              $rootScope.$broadcast('messageAdded');
            };

            updateMessage(message, options);
            return updateMessage;
          };

          service.releaseMessages = function () {
            var currentMessages = service.messages;
            service.messages = [];
            return currentMessages;
          };

          return service;
        },
    ]);
})();
