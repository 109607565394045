(function () {
    'use strict';
    angular.module('lucidity').controller('FilingCabinetController', [
        '$scope',
        '$rootScope',
        'Restangular',
        'TreeFactory',
        'MessageService',
        'ModalService',
        'FilingCabinetService',
        '_',
        '$window',
        '$sessionStorage',
        'NavigationService',
        function ($scope, $rootScope, Restangular, TreeFactory, MessageService, ModalService, FilingCabinetService, _, $window, $sessionStorage, NavigationService) { // NOSONAR
            var filingCabinet = Restangular.all('filingcabinet');
            $scope.itemRoot = TreeFactory.create(1);
            $scope.currentFolder = null;
            $scope.currentItem = null;
            $scope.$storage = $sessionStorage.$default({filingCabinet: {}});

            $scope.isFolder = function (item) {
                return item && item.data.isFolder || false;
            };

            $scope.isFileLink = function (item) {
                return !$scope.isFolder(item) && item.data.file.data.currentUserFileLink !== null;
            };

            $scope.canI = function (action, item) {
                if (item === null) {
                    return false;
                }

                var checkPermission = function (action, item, depth) {
                    //Disallow permissions on children of root node
                    var permissions = _(item).nested('data.permissions.data').omit(['target_id', 'cabinet_item_id']).value() || {};
                    if (permissions[action] !== undefined && permissions[action] === true) {
                        return true;
                    }

                    //Move up tree
                    return (item.parent !== null && checkPermission(action, item.parent, depth + 1));
                };

                return checkPermission(action, item, 0);

            };

            //Deprecated
            $scope.hasParent = function (item) {
                return (item !== null && item !== undefined && item.parent !== undefined && item.parent !== null && $scope.itemRoot !== item.parent);
            };

            $scope.showFolder = function (currentFolder, item) {
                var model = item || {
                    data: {
                        isFolder: true,
                    },
                };
                var title = item === undefined ? "Add Folder to '{{ context.data.name }}'" : "Edit Folder '{{ model.data.name }}'";
                ModalService.create({
                    title: title,
                    bodyUrl: '/app/filing-cabinet/itemmodal.html',
                    context: currentFolder,
                    model: model,
                    callbacks: {
                        ok: function ($modalScope, $modalInstance) {
                            var folder = $modalScope.model;
                            $modalScope.processing = true;
                            $scope.saveFolder(folder.data, item ? item.parent.id : currentFolder.id).then(function () {
                                $modalInstance.dismiss($modalScope);
                            });
                        },
                    },
                });

            };

            $scope.showEdit = function (currentFolder, item) {
                if (item.data.isFolder) {
                    return $scope.showFolder(currentFolder, item);
                } else {
                    return $scope.showFile(currentFolder, item);
                }
            };

            $scope.generateFileLink = function (item) {
                FilingCabinetService.generateFileLink(item).then(function (response) {

                    var data = response.data.plain();
                    $scope.currentItem.data.file.data.currentUserFileLink = data;
                });
            };

            $scope.deleteFileLink = function (item) {
                if ($scope.isFileLink(item)) {
                    FilingCabinetService.deleteFileLink(item.data.file).then(function (response) {
                        $scope.currentItem.data.file.data.currentUserFileLink = null;
                    });
                }
            };

            $scope.fileLinkvisibility = function (item) {
                if ($scope.isFileLink(item)) {
                    FilingCabinetService.fileLinkvisibility(item.data.file).then(function (response) {
                        var data = response.data.plain();
                        $scope.currentItem.data.file.data.currentUserFileLink = data;
                    });
                }
            };

            $scope.saveFolder = function (folder, parentId) {
                var promise;
                folder = _.merge(folder, {
                    parentId: parentId,
                    isFolder: true,
                });
                if (folder.id === undefined) {
                    promise = filingCabinet.all('folders').post(folder);
                } else {
                    promise = filingCabinet.one('folders', folder.id).put(folder);
                }

                promise.then(function (item) {
                    item = _.merge(item.data.plain(), {
                        selected: false,
                        expanded: false,
                    });
                    item = $scope.addItem(item, parentId);
                    MessageService.success("Folder '{{ data.name }}' saved", item);
                }, function (item) {

                    MessageService.error("Error saving '{{ name }}'", item);
                });

                return promise;
            };

            $scope.showFile = function (currentFolder, item) {
                var model = angular.copy(item) || {
                    data: {
                        isFolder: false,
                        tags: [],
                    },
                };
                model.data.tags = _.map(model.data.tags || [], function (tag) {
                    return {key: tag, value: tag};
                });

                var title = item === undefined ? "Add File to '{{ context.data.name }}'" : "Edit File '{{ model.data.name }}'";
                ModalService.create({
                    title: title,
                    bodyUrl: '/app/filing-cabinet/itemmodal.html',
                    context: currentFolder,
                    model: model,
                    callbacks: {
                        ok: function ($modalScope, $modalInstance) {
                            var file = $modalScope.model;
                            $modalScope.processing.ok = true;
                            $scope.saveFile(file.data, file.data.file.data.id, currentFolder.id).then(function (response) {
                                if (item !== undefined) {
                                    item.data = _.merge($modalScope.model.data, response.data.plain());
                                }

                                $modalInstance.dismiss($modalScope);
                            });
                        },
                        okDisabled: function($modalScope, $modalInstance) {
                            return $modalScope.disabled.ok;
                        },
                        load: function($modalScope, $modalInstance) {
                            if (item === undefined) {
                                $modalScope.disabled.ok = true;
                            }
                            $modalScope.obj = {};
                        },
                    },
                    methods: {
                        fileAdded: function (scope, file, response) {
                            scope.disabled.ok = false;
                            file.isUploading = false;
                            file.isUploaded = true;
                            scope.model.data.file = JSON.parse(response);
                        },

                        fileSubmitted: function (scope, flow, files) {
                            scope.disabled.ok = true;
                            scope.isFileError = false;
                            scope.errorMessages = [];
                            scope.errorCode = null;

                            //Set name in input to use file name
                            scope.model.data.name = scope.model.data.name || files[0].name.replace(/\.[\w\d]*$/, '');
                            flow.upload();
                        },

                        fileError: function (scope, file, response) {
                            scope.disabled.ok = true;
                            file.isUploading = false;
                            file.isUploaded = false;
                            file.error = true;
                            scope.isFileError = true;
                            if (response !== '') {
                                response = JSON.parse(response);
                                if (response.result !== undefined && response.result.messages !== undefined) {
                                    scope.errorMessages = response.result.messages;
                                    scope.errorCode = response.result.code;
                                }
                            }
                        },
                        isInvalidExtension: function (scope) {
                            return (scope.errorCode === 4015 && scope.isFileError);
                        },
                    },
                }, {
                    backdrop: 'static',
                    keyboard: false
                });
            };

            $scope.saveFile = function (file, fileId, parentId) {
                var promise;
                file = _.merge(file, {
                    fileId: fileId,
                    parentId: parentId,
                    isFolder: false,
                    tags: _.map(file.tags || [], function (tag) {
                        return tag.key;
                    }),
                });
                if (file.id === undefined) {
                    promise = filingCabinet.all('files').post(file);
                } else {
                    promise = filingCabinet.one('files', file.id).customPUT(file);
                }

                promise.then(function (item) {
                    item = _.merge(item.data.plain(), {
                        selected: false,
                        expanded: false,
                    });
                    $scope.addItem(item, parentId);
                    $scope.currentItem.data.file.data.currentUserFileLink = item.file.data.currentUserFileLink;
                    MessageService.success("File '{{ name }}' saved", file);
                }, function (item) {

                    MessageService.error("Error saving '{{ name }}'", item);
                });

                return promise;
            };

            $scope.showReview = function (currentItem, item) {
                var title = "Scheduled Reviews : '{{ context.data.name }}'";
                ModalService.create({
                    title: title,
                    bodyUrl: '/app/filing-cabinet/review.html',
                    context: item,
                    model: item,
                }, {
                    controller: 'FilingCabinetReviewController',
                });
            };
            var copyMove = function (item, parent, action) {
                var model = parent || {};

                ModalService.create({
                    title: "{{ context.action }} '{{ context.item.data.name }}'",
                    bodyUrl: '/app/filing-cabinet/movecopy.html',
                    model: model,
                    context: {
                        item: item,
                        action: action,
                        itemRoot: $scope.itemRoot,
                        currentItem: null,
                    },
                    callbacks: {
                        load: function ($modalScope, $modalInstance) {
                            $modalScope.disabled.ok = true;
                            $modalScope.loadChildren(1);
                        },

                        ok: function ($modalScope, $modalInstance) {
                            var promise;
                            $modalScope.processing = true;
                            if (action === 'Copy') {
                                promise = $scope.copyItem(item, $modalScope.model);
                                promise.then(function () {
                                    return $modalInstance.close($modalScope);
                                });
                            } else if (action === 'Move') {
                                promise = $scope.moveItem(item, $modalScope.model);
                                promise.then(function () {
                                    return $modalInstance.close($modalScope);
                                });
                            }
                        },
                    },
                    methods: {
                        selectItem: function ($modalScope, item) {
                            $modalScope.disabled.ok = false;
                            $modalScope.model = $modalScope.currentItem = item;
                        },

                        toggleItem: function ($modalScope, item) {
                            $modalScope.selectItem(item);
                            item.data.expanded = (!item.data.expanded);
                            $modalScope.loadChildren(item.data.id);
                        },

                        folderPaneFilter: function ($modalScope, item) {
                            return ($modalScope.item.id !== item.id);
                        },

                        loadChildren: function ($modalScope, id) {
                            $modalScope.itemRoot.loadChildren(id, function () {
                                return filingCabinet.one('folders', id).get();
                            }, {

                                initData: {
                                    selected: false,
                                    expanded: false,
                                },
                            });
                        },
                    },
                });

            };

            $scope.showCopy = function (item, parent) {
                return copyMove(item, parent, 'Copy');
            };

            $scope.showMove = function (item, parent) {
                return copyMove(item, parent, 'Move');
            };


            $scope.moveItem = function (item, newParent) {

                var promise = filingCabinet.one('move', item.id).one('to', newParent.id).put();

                promise.then(function (itemResponse) {
                    $scope.removeItem(item);
                    $scope.addItem(item, newParent.id);
                    MessageService.success("'{{ data.name }}' moved", item);
                });

                return promise;
            };

            $scope.copyItem = function (item, newParent) {

                var promise = filingCabinet.one('copy', item.id).one('to', newParent.id).put();

                promise.then(function (itemResponse) {
                    itemResponse = itemResponse.data.plain();
                    itemResponse.expanded = false;
                    $scope.addItem(itemResponse, newParent.id);
                    MessageService.success("'{{ data.name }}' copied", item);
                });

                return promise;
            };

            $scope.addItem = function (item, parentId) {
                var existingItem = $scope.itemRoot.find(item.id);
                if (existingItem === null) {
                    $scope.itemRoot.find(parentId).appendChild(item, item.id);
                    return $scope.itemRoot.find(item.id);
                }

                return existingItem;
            };

            $scope.removeItem = function (remove) {
                var id = remove.id || remove;
                var item = $scope.itemRoot.find(id);
                item.removeChildren();
                item.remove();
            };

            $scope.selectItem = function (item, reloadItemPane) {
                $scope.currentItem = item;
                reloadItemPane = reloadItemPane === undefined ? true : reloadItemPane;
                if ($scope.isFolder(item) && reloadItemPane) {
                    $scope.selectFolder(item);
                }
            };

            $scope.selectFolder = function (folder) {
                $scope.currentFolder = folder;
                return $scope.loadFolder(folder.id).then(function (item) {
                    $scope.currentFolder = item;
                    return item;
                });
            };

            $scope.toggleItem = function (item, expanded) {
                $scope.selectItem(item);
                if ($scope.isFolder(item)) {
                    $scope.$storage.filingCabinet[item.id].expanded = item.data.expanded = expanded || (!item.data.expanded);
                    if (item.data.expanded) {
                        if (item.children[item.id] === undefined) {
                            $scope.loadFolder(item.id);
                        }
                    }
                } else {
                    NavigationService.action('view')($scope, item);
                }
            };

            $scope.openItem = function (item) {
                if (item.data.isFolder) {
                    item.bubbleUp(function (item) {
                        $scope.toggleItem(item, true);
                    });

                    $scope.selectFolder(item);
                }
            };

            $scope.showRemove = function (item) {
                ModalService.create({
                    title: 'Delete',
                    body: "Are you sure you want to delete <strong>'{{ context.data.name }}'</strong>?",
                    context: item,
                    callbacks: {
                        ok: function ($modalScope, $modalInstance) {
                            $modalScope.processing = true;
                            $scope.remove(item.id).then(function () {
                                $modalInstance.dismiss($modalScope);
                            });
                        },
                    },
                });
            };

            $scope.remove = function (id) {
                var item = $scope.itemRoot.find(id);
                var endpoint = item.data.isFolder ? 'folders' : 'files';
                var promise = filingCabinet.one(endpoint, id).remove();

                return promise.then(function () {
                    $scope.removeItem(id);
                    $scope.currentItem = null;
                    MessageService.success("'{{ data.name }}' successfully deleted", item);
                }, function () {
                    MessageService.error("Error deleting '{{ data.name }}'", item);
                });
            };

            var postProcessFile = function (event, $flow, flowFile, response) {
                try {
                    var file = JSON.parse(response).data;
                    var currentFolderId = $scope.currentFolder.id;
                    var fileId = file.id;
                    delete file.id;
                    $scope.saveFile(file, fileId, currentFolderId).then(function () {
                        flowFile.cancel();
                    });

                } catch (e) {
                    return;
                }
            }

            $scope.$on('flow::fileProgress', postProcessFile);
            $scope.$on('flow::fileSuccess', postProcessFile);

            $scope.loadFolder = function (id, selectedItem, preloadItems) {
                return $scope.itemRoot.loadChildren(id, function () {
                    var params = {};
                    if (selectedItem) {
                        params.selectedItem = selectedItem;
                    }

                    if (preloadItems && preloadItems.length) {
                        params.preloadItems = preloadItems.join(',');
                    }
                    params.overallPermission = true;
                    return filingCabinet.one('folders', id).get(params);
                }, {

                    callback: function (child) {
                        child.file = child.file ? child.file : {data: {extension: 'folder'}};
                        return child;
                    },

                    created: angular.bind(this, function ($scope, node) {
                        //If node is selected, walk back up tree expanding
                        var parent = node;
                        if (node.data.selected) {
                            $scope.currentFolder = $scope.currentItem = node;
                            if (!node.data.isFolder) {
                                $scope.currentFolder = node.parent;
                            }

                            parent = parent.parent;
                            while (parent) {
                                parent.data.expanded = true;
                                parent = parent.parent;
                            }
                        }

                        $scope.$storage.filingCabinet[node.id] = $scope.$storage.filingCabinet[node.id] || {};
                        $scope.$storage.filingCabinet[node.id].id = node.id;
                        $scope.$storage.filingCabinet[node.id].expanded = $scope.$storage.filingCabinet[node.id].expanded || false;
                        if ($scope.$storage.filingCabinet[node.id].expanded) {
                            parent = node;
                            do {
                                parent.data.expanded = true;
                                parent = parent.parent;
                            } while (parent);
                        }

                    }, $scope),

                    initData: {
                        selected: false,
                        expanded: false,
                    },
                });
            };

            var expandedItems = function () {
                return _($scope.$storage.filingCabinet).filter(function (item) {
                    return item.expanded;
                }).map(function (item) {
                    return item.id;
                }).value();

            };

            var selectedItem = function () {
                return $window.selectedItem;
            };

            $scope.loadFolder(1, selectedItem(), expandedItems());

            $scope.showOpenButton = function () {
                return $scope.canI('can_download_file', $scope.currentItem) && !$scope.isFolder($scope.currentItem);
            };

            $scope.showEditButton = function () {
                return $scope.isFolder($scope.currentItem) ? $scope.canI('can_edit_folder', $scope.currentItem) : $scope.canI('can_edit_file', $scope.currentItem);

            };

            $scope.showAddFolderButton = function () {
                return $scope.isFolder($scope.currentItem) && $scope.canI('can_add_folder', $scope.currentFolder);

            };

            $scope.showAddFileButton = function () {
                return $scope.canI('can_add_file', $scope.currentFolder) && $scope.currentFolder;

            };

            $scope.showReviewButton = function () {
                return $scope.canI('can_review_file', $scope.currentItem) && !$scope.isFolder($scope.currentItem);
            };

            $scope.showMoveButton = function () {

                if ($scope.isFolder($scope.currentItem)) {
                    if ($scope.currentItem.parent.id !== 1) {
                        return $scope.canI('can_move_folder', $scope.currentItem);
                    }
                } else {
                    return $scope.canI('can_move_file', $scope.currentItem);
                }
                return false;
            };

            $scope.showCopyButton = function () {

                if ($scope.isFolder($scope.currentItem)) {
                    if ($scope.currentItem.parent.id !== 1) {
                        return $scope.canI('can_copy_folder', $scope.currentItem);
                    }
                } else {
                    return $scope.canI('can_copy_file', $scope.currentItem);
                }

                return false;
            };

            $scope.showDeleteButton = function () {
                if ($scope.isFolder($scope.currentItem)) {
                    if ($scope.currentItem.parent.id !== 1) {
                        return $scope.canI('can_delete_folder', $scope.currentItem);
                    }
                } else {
                    return $scope.canI('can_delete_file', $scope.currentItem);
                }
                return false;
            };
        },
    ]);
})();
