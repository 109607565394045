(function () {
  'use strict';
  angular.module('lucidity').directive('ngColorPicker', function () {
    var defaultColors = [
      '#7bd148',
      '#5484ed',
      '#a4bdfc',
      '#46d6db',
      '#7ae7bf',
      '#51b749',
      '#fbd75b',
      '#ffb878',
      '#ff887c',
      '#dc2127',
      '#dbadff',
      '#e1e1e1',
    ];
    var directive = {};
    directive.restrict = 'AE';
    directive.templateUrl = '/app/element/color-picker/ngcolorpicker.html';
    directive.scope = {
      selected: '=',
      customizedColors: '=colors',
    };
    directive.link = function (scope, element, attr) {
      scope.colors = scope.customizedColors || defaultColors;
      scope.selected = scope.selected || scope.colors[0];

      scope.pick = function (color) {
        scope.selected = color;
      };

    };

    return directive;
  });
})();
