(function () {
  'use strict';

  angular.module('lucidity').controller('ActionWidgetLinksController', ActionWidgetLinksController);

  ActionWidgetLinksController.$inject = ['ActionService', 'HATEOASLinksService', 'EnabledFeaturesService'];

  function ActionWidgetLinksController(ActionService, HATEOASLinksService, EnabledFeaturesService) {
    var vm = this;
    vm.pages = {};
    vm.processing = { loading: false };

    // items per page
    vm.pageSize = 5;

    // number of pages displayed in pagination
    vm.maxSize = 5;

    // current page position (starts at one)
    vm.currentPage = 1;

    // total number of items
    vm.totalItems = 0;

    vm.pageChanged = pageChanged;

    vm.showPagination = showPagination;
    vm.deleteAction = deleteAction;
    vm.hasActions = HATEOASLinksService.hasActions;
    vm.editUrl = HATEOASLinksService.editUrl;
    vm.deleteUrl = HATEOASLinksService.deleteUrl;
    vm.informIntegrationEnabled = EnabledFeaturesService.hasFeature('action_link_inform');

    init();

    function init() {
      vm.processing.loading = true;
      ActionService.countTotalItems().then(function (response) {
        var data = response.data.plain();
        vm.totalItems = data.totalItems;
        pageChanged();
      });
    }

    function pageChanged() {
      vm.processing.loading = true;
      ActionService.getWidgetPages(vm.currentPage, vm.pageSize).then(function (response) {
        vm.pages = response.data.plain();
        vm.processing.loading = false;
      });
    }

    function showPagination() {
      return vm.totalItems > vm.pageSize;
    }

    function deleteAction(id) {
      ActionService.deleteAction(id).then(function(response) {
        pageChanged();
      });
    }
  
  }
})();
