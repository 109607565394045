(function () {
    'use strict';
    angular.module('lucidity').directive('ngFormIdentifier', FormIdentifier);

    function FormIdentifier() {
        var directive = {};
        directive.restrict = 'A';
        directive.controller = ['$attrs', function ($attrs) {
            var self = this;
            self.identifier = $attrs.ngFormIdentifier || null;
        }];
        return directive;
    }
})();
