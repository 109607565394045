(function () {
    'use strict';
    angular.module('lucidity').controller('UploadImageController', [
        '$scope',
        '$window',
        function ($scope, $window) {
            $scope.addFile = function (file, response) {
                file.isUploading = false;
                file.isUploaded = true;
                $window.location.reload();
            };
            $scope.submitFile = function (flow) {
                flow.upload();
                $scope.isFileError = false;
            };

            $scope.errorFile = function ($file, response, $flow) {
                $file.isUploading = false;
                $file.isUploaded = false;
                $file.error = true;
                $scope.isFileError = true;
            };

            $scope.isInvalidExtension = function (scope) {
                return (scope.errorCode === 4015 && scope.isFileError);
            };

            $scope.$on('flow::fileSuccess', function (event, $flow, flowFile, response) {
                flowFile.cancel();
            });
        }
    ]);
}());
