(function () {
  'use strict';
  angular.module('lucidity').directive('uiSelectRequired', function () {
    var directive = {};
    directive.require = 'ngModel';

    directive.link = function (scope, element, attrs, ngModel) {
      var isRequiredValidatorEnabled = attrs.uiSelectRequired === true || attrs.uiSelectRequired === 'true';
      scope.$watch(function () {
        return ngModel.$modelValue;
      }, function (newValue, oldValue) {

        if (!isRequiredValidatorEnabled || ngModel.$modelValue && ngModel.$modelValue.length) {
          ngModel.$setValidity('required', true);
        } else {
          ngModel.$setValidity('required', false);
        }
      });
    };

    return directive;
  });
})();
