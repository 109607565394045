(function () {
    'use strict';

    angular.module('lucidity').controller('ActionCommentController', ActionCommentController);

    ActionCommentController.$inject = ['ActionCommentService', 'ModalService', '_'];

    function ActionCommentController(ActionCommentService, ModalService, _) {
        var vm = this;

        vm.comment = {
            text: '',
        };
        vm.comments = {};
        vm.identifier = null;
        vm.action_comment_uuid = null;
        vm.processing = false;
        vm.loadActionComments = loadActionComments;
        vm.addComment = addComment;
        vm.editComment = editComment;
        vm.deleteComment = deleteComment;

        function loadActionComments() {
            vm.comments = [];
            var identifier = vm.identifier; // The action's uuid

            if (window.location.pathname.indexOf("/intranet/actions/create") === -1 && window.location.pathname.indexOf("/intranet/myactions/create") === -1) {
                vm.processing = true;
                //Load attachments from storage for an action
                ActionCommentService.loadComments(identifier).then(function (comments) {
                    vm.comments = comments.data.plain();
                    vm.processing = false;
                }).catch(function (data) {
                    vm.processing = false;
                });
            }

        }

        function addComment() {
            var addModalInstance = ModalService.create({
                title: 'Add Comment',
                bodyUrl: '/app/action/action-comment.html',
                identifier: vm.identifier,
                comment: {},
            }, {
                controller: 'ActionCommentModalController',
            });

            addModalInstance.result.then(function (comment) {
                vm.comments.push(comment.data.plain());
            });
        }

        function editComment(comment) {
            var editModalInstance = ModalService.create({
                title: 'Edit Comment',
                bodyUrl: '/app/action/action-comment.html',
                identifier: vm.identifier,
                comment: comment,
            }, {
                controller: 'ActionCommentModalController',
            });

            editModalInstance.result.then(function (comment) {
                vm.comments = _.updateByKey(vm.comments, 'uuidString', comment.data.plain().uuidString, comment.data.plain());
            });
        }

        function deleteComment(comment) {
            ActionCommentService.deleteComment(vm.identifier, comment).then(function (comment) {
                _.removeByKey(vm.comments, 'uuidString', comment.data.plain().uuidString);
            });
        }
    }
})();
