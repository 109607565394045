(function () {
    'use strict';
    angular.module('lucidity').directive('formaction', Formaction);

    Formaction.$inject = ['Modernizr'];

    function Formaction(Modernizr) {
        var directive = {};
        directive.restrict = 'A';
        directive.link = link;
        return directive;

        function link(scope, elem, attrs) {
            var action = attrs.formaction;
            if (!Modernizr.inputformmethod) {
                elem.on('click', function () {
                    elem.parents('form').attr('action', action);
                });
            }
        }
    }
})();
