(function () {
    'use strict';
    angular.module('lucidity').directive('ngCkeditorNotification', [
        '$window',
        'CKEDITOR',
        'ckeditorNotificationConfig',
        '$compile',
        '$location',
        '$route',
        'EventService',
        function ($window, CKEDITOR, ckeditorNotificationConfig, $compile, $location, $route, EventService) {
            var directive = {};
            directive.require = '?ngModel';
            directive.link = function ($scope, elem, attrs, ngModel) {
                var editor = CKEDITOR.replace(elem[0], ckeditorNotificationConfig);
                if (attrs.ngCkeditor) {
                    CKEDITOR.config.bodyClass = attrs.ngCkeditor;
                }

                editor.$scope = $scope;
                editor.$compile = $compile;
                editor.$route = $route;
                editor.$location = $location;
                editor.EventService = EventService;

                CKEDITOR.on('dialogDefinition', onDialogDefinition);

                //Remove that cockersucker reset from the dialog
                function onDialogDefinition(event) {
                    var dialog = event.data.definition.dialog;

                    function onShow(event) {
                        angular.module('lucidity').constant('ckeditorDialog', event.sender.parts.dialog.$);
                        var dialogContainer = angular.element(event.sender.parts.dialog.$).parent();
                        dialog.on('show', function (event) {
                            dialogContainer.removeClass('cke_reset_all');
                            if (dialog.definition.id !== undefined && dialog.definition.id === 'luciditywidget') {
                                dialogContainer.addClass('ckeditor-lucidity-widget');
                            }
                        });
                    }

                    dialog.on('show', onShow);
                }

                function readViewText() {
                    if (ngModel) {
                        ngModel.$setViewValue(editor.getData());
                    }
                }

                function onInstanceReady(evt) {
                    function onElementChange() {
                        if (!$scope.$$phase) {
                            $scope.$apply(readViewText);
                        }
                    }

                    evt.editor.on('blur', onElementChange);
                    evt.editor.on('change', onElementChange);
                }

                editor.on('instanceReady', onInstanceReady);

                if (ngModel) {
                    ngModel.$render = function (value) {
                        editor.setData(ngModel.$viewValue);
                    };
                }
            };
            return directive;
        },
    ]);
})();
