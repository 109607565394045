(function () {
  'use strict';
  angular.module('lucidity').directive('ngActionList', [
      '_',
        function (_) {
          var directive = {};
          directive.restrict = 'EA';

          directive.link = function (scope, elem, attrs) {
            elem.bind('click', function (event) {
              // prevent a click on the action from triggering the click event from ng-action-trigger
              event.stopPropagation();

              // open the dropdown now that we have prevents the click event from doing so
              elem.find('.dropdown-menu').first().toggle();
            });

          };

          return directive;
        },
    ]);
})();
