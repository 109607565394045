(function () {
  'use strict';
  var exceptionCount = 0;
  angular.module('lucidity')
  .service('$log', function () {

    this.debug = function (msg) {
      window.JL().debug(msg);
    };

    this.error = function (msg) {
      window.JL().error(msg);
    };

    this.info = function (msg) {
      window.JL().info(msg);
    };

    this.log = function (msg) {
      window.JL().trace(msg);
    };

    this.warn = function (msg) {
      window.JL().warn(msg);
    };
  })

  .factory('$exceptionHandler', function () {

    return function (exception, cause) {
      exceptionCount++;
      window.console.log('Exception ' + exceptionCount);
      window.console.log(exception);
      window.console.log(exception.stack || {});
      window.console.log('Exception ' + exceptionCount + '\n^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    };
  })

  .factory('logToServerInterceptor', ['$q', function ($q) {

    var myInterceptor = {
      request: function (config) {
        config.msBeforeAjaxCall = new Date().getTime();
        return config;
      },

      response: function (response) {
        if (response.config.warningAfter) {
          var msAfterAjaxCall = new Date().getTime();
          var timeTakenInMs = msAfterAjaxCall - response.config.msBeforeAjaxCall;
          if (timeTakenInMs > response.config.warningAfter) {
            window.JL('Angular.Ajax').warn({
              timeTakenInMs: timeTakenInMs,
              config: response.config,
              data: response.data,
            });
          }
        }

        return response;
      },

      responseError: function (rejection) {
        var errorMessage = 'timeout';
        if (rejection.status !== 0) {
          errorMessage = rejection.data.ExceptionMessage;
        }

        window.JL().fatalException({
          errorMessage: errorMessage,
          status: rejection.status,
          config: rejection.config,
        }, rejection.data);
        return $q.reject(rejection);
      },
    };
    return myInterceptor;
  },]);
})();
