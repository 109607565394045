(function () {
  'use strict';
  angular.module('lucidity').filter('join', ['_', function (_) {
    return function (input, delimeter, last) {
      if (_.isArray(input)) {
        delimeter = delimeter || ', ';
        if (last !== undefined && input.length > 1) {
          var lastInput = input.pop();
          input = input.join(delimeter);
          return [input, lastInput].join(last);
        } else {
          return input.join(delimeter);
        }
      }

      return input;
    };
  },]);
})();
