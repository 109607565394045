// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
(function () {
    'use strict';

    angular.module('lucidity').controller('FilingCabinetPermissionController', FilingCabinetPermissionController);

    FilingCabinetPermissionController.$inject = ['_', 'FilingCabinetService', 'TreeFactory', '$scope'];

    function FilingCabinetPermissionController(_, FilingCabinetService, TreeFactory, $scope) {
        var vm = this;

        vm.itemRoot = TreeFactory.create(1);
        vm.itemRoot.permissions = {};
        vm.currentFolder = null;
        vm.type = null;
        vm.targetId = null;

        vm.roles = [
            {name: '[ Clear Permissions ]', permissions: []},
            {name: 'View', permissions: ['can_view_file', 'can_view_folder', 'can_download_file']},
            {
                name: 'User',
                permissions: ['can_add_file', 'can_copy_file', 'can_delete_file', 'can_download_file',
                    'can_edit_file', 'can_move_file', 'can_review_file', 'can_view_file', 'can_view_folder'],
            },
            {
                name: 'Admin',
                permissions: ['can_add_file', 'can_copy_file', 'can_delete_file', 'can_download_file',
                    'can_edit_file', 'can_move_file', 'can_review_file', 'can_view_file', 'can_add_folder',
                    'can_copy_folder', 'can_delete_folder', 'can_download_folder', 'can_edit_folder', 'can_move_folder',
                    'can_review_folder', 'can_view_folder'],
            },
            {
                name: 'Manager',
                permissions: ['can_add_file', 'can_copy_file', 'can_delete_file', 'can_download_file',
                    'can_edit_file', 'can_move_file', 'can_view_file', 'can_add_folder', 'can_copy_folder',
                    'can_edit_folder', 'can_move_folder', 'can_view_folder'],
            },
        ];

        vm.permissions = {
            can_add_file: {name: 'Add File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_copy_file: {name: 'Copy File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_delete_file: {name: 'Delete File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_download_file: {name: 'Download File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_edit_file: {name: 'Edit File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_move_file: {name: 'Move File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_review_file: {name: 'Review File', requires: requires(['can_view_folder', 'can_view_file'])},
            can_view_file: {name: 'View File', requires: requires(['can_view_folder'])},
            can_add_folder: {name: 'Add Folder', requires: requires(['can_view_folder'])},
            can_copy_folder: {name: 'Copy Folder', requires: requires(['can_view_folder'])},
            can_delete_folder: {name: 'Delete Folder', requires: requires(['can_view_folder'])},
            can_download_folder: {name: 'Download Folder', requires: requires(['can_view_folder'])},
            can_edit_folder: {name: 'Edit Folder', requires: requires(['can_view_folder'])},
            can_move_folder: {name: 'Move Folder', requires: requires(['can_view_folder'])},
            can_review_folder: {name: 'Review Folder', requires: requires(['can_view_folder'])},
            can_view_folder: {name: 'View Folder', requires: false},
        };

        // public functions
        vm.init = init;
        vm.getPermissions = getPermissions;
        vm.isFolder = isFolder;
        vm.selectRole = selectRole;
        vm.selectFolder = selectFolder;
        vm.toggleFolder = toggleFolder;
        vm.hasPermissions = hasPermissions;
        vm.countPermissions = countPermissions;
        vm.loadFolder = loadFolder;
        vm.requires = requires;
        vm.rgba = rgba;
        vm.clearBG = clearBG;

        function requires(permissions) {
            return function (item) {
            };
        }

        function getPermissions() {
            return vm.itemRoot.permissions;
        }

        // watch needs access to the scope
        $scope.$watch(vm.getPermissions, function (newPermissions, oldPermissions) {
            var updateFunction = window.parent.updateParentValue || function (key, value) {
            };

            updateFunction('cabinetPermissions', newPermissions);
        }, true);

        function isFolder(item) {
            return item && item.data.isFolder || false;
        }

        function init(type, targetId) {
            vm.type = type || null;
            vm.targetId = targetId || null;
            return loadTree(1);
        }

        function selectRole(role, item) {
            var keys = _.keys(vm.permissions);
            for (var i = 0; i < keys.length; i++) {
                vm.itemRoot.permissions[item.data.id] = vm.itemRoot.permissions[item.data.id] || {};
                vm.itemRoot.permissions[item.data.id][keys[i]] = (_.indexOf(role.permissions, keys[i]) !== -1);
            }
        }

        function selectFolder(item) {
            vm.currentFolder = item;
        }

        function toggleFolder(item, expanded) {
            selectFolder(item);

            item.data.expanded = expanded || (!item.data.expanded);
            if (item.data.expanded) {
                loadFolder(item.id);
            }

        }

        function hasPermissions(item) {
            return countPermissions(item) > 0;
        }

        function countPermissions(item) {
            var count = 0;
            var permissions = item && item.data ? vm.itemRoot.permissions[item.data.id] : null;
            var keys = _.keys(vm.permissions);
            if (permissions !== undefined) {
                for (var i = 0; i < keys.length; i++) {
                    if (permissions[keys[i]] !== undefined && permissions[keys[i]]) {
                        count++;
                    }
                }
            }

            return count;
        }

        function countInheritedPermissions(item) {
            var currentPermissionCount = countPermissions(item);
            if (item.parent && item.depth > 1) {
                var parentPermissionCount = countPermissions(item.parent);
                if (parentPermissionCount > currentPermissionCount || (parentPermissionCount === 0 && item.parent.depth > 1)) {
                    var inheritedPermissionCount = countInheritedPermissions(item.parent);
                    if (inheritedPermissionCount > currentPermissionCount) {
                        return inheritedPermissionCount;
                    }

                    return currentPermissionCount;
                }
            }

            return currentPermissionCount;
        }

        function rgba(item) {
            var count = (countInheritedPermissions(item) * (100 / 16));
            if (count < 1) {
                return '';
            } else if (count < 10) {
                return 'permissions permissions-bg-0';
            } else if (count < 20) {
                return 'permissions permissions-bg-1';
            } else if (count < 50) {
                return 'permissions permissions-bg-2';
            } else if (count < 80) {
                return 'permissions permissions-bg-3';
            } else {
                return 'permissions permissions-bg-4';
            }

        }

        function clearBG(item) {
            if (countInheritedPermissions(item) > 0 && (item.currentState != item.STATE_LOADING)) {
                return true;
            }

            return false;
        }

        function loadFolder(id) {
            var folder = vm.itemRoot.find(id);
            if (folder.childrenPromise === undefined) {
                folder.state(folder.STATE_LOADING);
                folder.childrenPromise = FilingCabinetService.getItemPermissions(id, vm.targetId, vm.type).then(function (item) {
                    initItem(folder, item);
                    return folder;
                });
            }

            return folder.childrenPromise;
        }

        function loadTree(rootId) {
            var folder = vm.itemRoot.find(rootId);
            folder.state(folder.STATE_LOADING);
            folder.childrenPromise = FilingCabinetService.getItemNestedPermissions(rootId, vm.targetId, vm.type).then(function (item) {
                initItem(folder, item);
                return folder;
            });
        }

        function initItem(folder, item) {
            var permissions = item.data.permissions && item.data.permissions.data ? item.data.permissions.data : {};
            _.each(permissions, function (permission) {
                vm.itemRoot.permissions[permission.cabinet_item_id] = permission;
            });

            folder.state(folder.STATE_LOADED);

            initChildren(folder, item.data.children.data);
        }

        function initChildren(folder, folderChildren) {
            _.each(folderChildren, function (child) {
                child = _.merge(child, {
                    selected: false,
                    expanded: false,
                    permissions: {
                        data: {},
                    },
                });
                folder.appendChild(child, child.id);
                if (countRecordChildren(child) > 0) {
                    var childFolder = vm.itemRoot.find(child.id);
                    initChildren(childFolder, child.children.data);
                    childFolder.data.expanded = true;
                }
            });
        }

        function countRecordChildren(record) {
            if (record.children && record.children.data) {
                return record.children.data.length;
            }

            return 0;
        }
    }
})();

