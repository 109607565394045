/**
 * Binds the initial contents of target element to directives scope
 */
(function () {
  'use strict';
  angular.module('lucidity').directive('ngCompileTemplate', [
      '$compile',
        function ($compile) {
          var directive = {};
          directive.link = function (scope, element, attrs) {
            var compiledElement;
            var elementHtml = element.html();
            scope[attrs.template] = elementHtml || '';
            scope.$watch(attrs.template, function (newTemplate, oldTemplate) {
              compiledElement = $compile(newTemplate)(scope);
              element.html('');
              element.append(compiledElement);
            });
          };

          return directive;
        },
    ]);
})();
