(function () {
  'use strict';
  angular.module('lucidity').directive('ngShowPlaceholderLabel', [
    '_',
    function (_) {
      var directive = {};
      directive.restrict = 'A';
      directive.link = function (scope, element, attr, ngModel) {
        var isSupported = ('placeholder' in document.createElement('input'));
        if (!isSupported && element.hasClass('sr-only')) {
          // st-class is controlling the visibility of the label
          element.removeClass('sr-only');
        }
      };
      return directive;
    }
  ])
}());