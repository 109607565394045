(function() {
    'use strict';

    angular.module('lucidity').factory('HATEOASLinksService', HATEOASLinksService);
    HATEOASLinksService.$inject =['_'];

    function HATEOASLinksService(_) {
        return {
            hasLinks: hasLinks,
            hasActions: hasActions,
            selfUrl: selfUrl,
            createUrl: createUrl,
            editUrl: editUrl,
            deleteUrl: deleteUrl,
            linkByRel:linkByRel,
            listUrl: listUrl,
        };
        
        
        function hasLinks(object) {
            return selfUrl(object) || createUrl(object) || editUrl(object) || deleteUrl(object) || listUrl(object);
        }
        
        function hasActions(object) {
            return createUrl(object) || editUrl(object) || deleteUrl(object);
        }

        function selfUrl(object) {
            return urlFromLink(linkByRel(object, 'self'));
        }

        function createUrl(object) {
            return urlFromLink(linkByRel(object, 'create'));

        }

        function editUrl(object) {
            return urlFromLink(linkByRel(object, 'edit'));
        }

        function deleteUrl(object) {
            return urlFromLink(linkByRel(object, 'delete'));
        }
       
        function listUrl() {
            return urlFromLink(linkByRel(object, 'list'));
        }

        function linkByRel(object, rel) {
            var link = undefined;
            if (object !== undefined && object.links !== undefined) {
                link = _.find(object.links, function(l){
                    return l.rel === rel;
                });
            }
            return link;
        }
        
        function urlFromLink(link)
        {
            if (link !== undefined && link.url !== undefined) {
                return link.url;
            }
            return false;
        }
    }
})();
