(function () {
  'use strict';
  angular.module('lucidity').config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/widget-list', {
        templateUrl: '/app/editor/widgetlist.html',
        controller: 'EditorWidgetController',
      }).when('/widget-detail/:widgetName', {
        templateUrl: function (params) {
          return '/app/editor/' + params.widgetName + '/' + params.widgetName + '-detail.html';
        },

        controller: 'EditorWidgetController',
      });
    },
  ]);
})();
