(function () {
  'use strict';
  angular.module('lucidity').controller('PageAlertController', [
      '$scope',
      '$timeout',
      'MessageService',
        function ($scope, $timeout, MessageService) {

          $scope.alerts = [];

          $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
          };

          $scope.clearAlerts = function () {
            $scope.alerts = [];
          };

          $scope.$on('messageAdded', function () {
            $scope.alerts = MessageService.releaseMessages();
            $timeout($scope.clearAlerts, 3000);
          });

        },
    ]);
})();
