(function () {
  'use strict';
  angular.module('lucidity').controller('EditorWidgetController', [
    '$scope',
    '$window',
    'EventService',
    'CKEDITOR',
    function ($scope, $window, EventService, CKEDITOR) {
      $scope.widget = {};
      $scope.widgets = {};
      $scope.widgetDefaults = {
        elementType: 'div',
      };
      $scope.methods = {};
      $scope.methods.insertWidget = insertWidget;
      $scope.methods.insertElement = insertElement;
      $scope.methods.insertRaw = insertRaw;
      $scope.methods.editables = editables;
      $scope.methods.getContent = getContent;
      $scope.addWidget = addWidget;

      function insertWidget(scope, content) {
        var name = scope.widget.name;
        var editor = EventService.data.editor;
        var element = new CKEDITOR.dom.element($scope.widgetDefaults.elementType);
        var data = {
          $scope: scope,
        };
        var widgetDef = angular.extend(editor.widgets.registered['managementsystem-widget'], data);

        //wrapper
        element.appendHtml(content);

        //insert widget contents
        editor.insertElement(element);

        //add class post-insertion to prevent widget creation
        element.addClass('managementsystem-widget')
        .addClass(name);

        //init widget
        var widget = editor.widgets.initOn(element, widgetDef);

        //set widget data
        widget.setData(scope.widget);
      }

      function insertElement(scope, content) {
        var editor = EventService.data.editor;
        editor.insertElement(content, 'unfiltered_html');
      }

      function insertRaw(scope, content) {
        var editor = EventService.data.editor;
        editor.insertHtml(content, 'unfiltered_html');
      }

      function editables(num) {
        var editables = {};
        for (var i = 1; i <= num; i++) {
          editables['editable' + i] = '.editable-' + i;
        }

        return editables;
      }

      function getContent() {
        var contents = EventService.data.contents;
        var content = angular.element(contents).find('.preview-pane').html();

        //Replace all ng- directives etc
        return content
        .replace(/<(ng-[^ ]+)[^>]*>[\w\W]+?<\/\1>/g, '')        //replace ng tag directives <ng-*>
        .replace(/(\="[^\"]*)ng\-[^\" ]+([^\"]*")/g, '$1$2')    //strips ng directives from valid tags <div ng-repeat="">...
        .replace(/ng\-[^\"]+"[^\"]+"/g, '')                     //strips ng directives from valid tags <div ng-repeat="">...
        .replace(/<!-- [^:]+:.*?-->/g, '');                     //Removes ng comments
      }

      function addWidget(name, title, description) {
        $scope.widgets[name] = {
          name: name,
          title: title,
          description: description,
        };
      }

      //Move to a JSON source

      $scope.addWidget('filing-cabinet-file', 'Filing Cabinet File', 'Inserts a file from the the filing cabinet');
      $scope.addWidget('filing-cabinet-image', 'Filing Cabinet Image', 'Inserts an image from the the filing cabinet');
      $scope.addWidget('subpage-list', 'Subpage List', 'Displays a list of all subpages of the current page');

    },
  ]);
})();

