(function () {
    'use strict';

    angular.module('lucidity').factory('ActionAttachmentService', ActionAttachmentService);

    ActionAttachmentService.$inject = ['Restangular'];

    function ActionAttachmentService(Restangular) {
        return {
            loadAttachments: loadAttachments,
            addAttachment: addAttachment,
            editAttachment: editAttachment,
            deleteAttachment:deleteAttachment,
        };

        function loadAttachments(actionUuid) {
            return Restangular.one('actions', actionUuid).one('loadattachments').getList();
        }

        function addAttachment(actionUuid, actionAttachment) {
            return Restangular.one('actions', actionUuid).post('attachments', actionAttachment);
        }

        function editAttachment(actionUuid, actionAttachment) {
            return Restangular.one('actions', actionUuid).one('attachments', actionAttachment.uuidString).customPUT({
                "name" : actionAttachment.name,
                "description": actionAttachment.description,
            });
        }

        function deleteAttachment(actionUuid, actionAttachment) {
            return Restangular.one('actions', actionUuid).one('attachments', actionAttachment.uuidString).remove();
        }
    }
})();
