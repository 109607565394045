(function () {
  'use strict';
  angular.module('lucidity').directive('subpageList', SubpageList);

  SubpageList.$inject = ['$window', 'Restangular'];

  function SubpageList($window, Restangular) {
    var directive = {};
    directive.restrict = 'AE';
    directive.link = link;
    directive.scope = {
      alignment: '=?',
      displayAs: '=?',
    };
    directive.templateUrl = '/app/management-system/subpage-list/subpage-list.html';
    return directive;

    function link(scope, elem, attrs) {
      scope.alignment = scope.alignment || 'center';
      scope.displayAs = scope.displayAs || 'list';
      scope.subpages = [];
      init();

      function init() {
        scope.loading = true;
        var id = $window.location.pathname.match(/\d+$/)[0];
        Restangular.one('pages', id).getList('children').then(function (pages) {
            scope.loading = false;
            scope.subpages = pages.data.plain();
            scope.$parent.subpages = pages.data.plain();
          }
        );
      };
    }

  }

})();
