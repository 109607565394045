(function () {
  'use strict';
  angular.module('lucidity').directive('repeatingDatepickerBackdrop', [function () {
    return {
      restrict: 'EA',
      replace: true,
      template: '<div class="repeating-datepicker-backdrop" ng-show="isOpen" ng-click="close()"></div>',
    };
  }, ]);
})();
