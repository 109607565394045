(function () {
  'use strict';
  angular.module('lucidity').controller('SubpageListController', [
    '$scope',
    'Restangular',

    '$window',
    function ($scope, Restangular, $window) {

      $scope.methods = $scope.methods || {};
      $scope.subpages = [];

      var template = '<div subpage-list display-as="%displayAs%" alignment="%alignment%">%subpages%</div>';

      $scope.widget = {
        name: 'subpage-list',
        alignment: 'center',
        displayAs: 'list',
        loading: true,
      };

      $scope.init = function (init) {
        if (init !== undefined) {
          $scope.widget.alignment = init.alignment || $scope.widget.alignment;
          $scope.widget.displayAs = init.displayAs || $scope.widget.displayAs;
        }
      };

      $scope.$on('ckeditor.dialog.onOk', function () {
        var content = $scope.methods.getContent();
        $scope.methods.insertRaw($scope, content);
      });

      $scope.methods.getContent = function () {

        var subPages = '<ul>';
        $scope.subpages.forEach(function (page) {
            var li = '<li><a href="/intranet/management/'+page.managementSystemId+'/page/'+page.id+'">'+page.title+'</a></li>';

            subPages += li;
        });
        subPages += '</ul>'

       return  template.replace(/%alignment%/g, $scope.widget.alignment)
        .replace(/%displayAs%/g, $scope.widget.displayAs)
           .replace(/%subpages%/g, subPages)
      };

    },
  ]);
})();
