(function () {
  'use strict';
  angular.module('lucidity').controller('LinksWidgetController', [
    '$scope',
    '$http',
    'Restangular',
    'MessageService',
    'ModalService',
    '_',
    '$modalInstance',
    '$modalOptions',
    function ($scope, $http, Restangular, MessageService, ModalService, _, $modalInstance, $modalOptions) {

      ModalService.init($scope, $modalInstance, $modalOptions).loadBody();
      $scope.config = $scope.$modalOptions.config;
      $scope.preferences = $scope.$modalOptions.preferences || {};

      $scope.loadPreferences = function (preferences) {
        if (preferences !== null) {
          $scope.preferences = preferences;
        }

        $scope.config.view = $scope.config.view || {};
        $scope.preferences.view = $scope.preferences.view || {};
        $scope.preferences.view.title = _.nested($scope, 'preferences.view.title', $scope.config.view.title || '');
      };

      $scope.callback('ok', function ($parentScope, $modalInstance) {
        $scope.form.linksForm.$setDirty();
        if ($scope.form.linksForm.$invalid) {
          return;
        }

        $http.post($scope.config.editUrl, $scope.preferences).success(function (response) {
          $modalInstance.close($scope);
        });
      });

      $scope.$watch('form.linksForm.$invalid', function (validity) {
        $scope.disabled.ok = validity;
      });

      $scope.addRow = function () {
        $scope.preferences.links = $scope.preferences.links || [];
        $scope.preferences.links.push({
          title: '',
          href: '',
        });
      };

      $scope.removeRow = function (index) {
        $scope.preferences.links.splice(index, 1);
      };
    },
  ]);
})();
