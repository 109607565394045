(function () {
    'use strict';
    angular.module('lucidity').controller('ActionWidgetController', [
        '$scope',
        '$window',
        'MessageService',
        'ModalService',
        '_',
        'ActionService',
        'FormService',
        'PermissionService',
        'moment',
        '$modalInstance',
        '$modalOptions',
        'user',
        'EnabledFeaturesService',
        function ($scope, $window, MessageService, ModalService, _, ActionService, FormService, PermissionService, moment, $modalInstance, $modalOptions, user, EnabledFeaturesService) {

            ModalService.init($scope, $modalInstance, $modalOptions).loadBody();
            $scope.form = {};
            $scope.action = {};
            $scope.canRaiseOnBehalfOfOther = PermissionService.hasPermission('intranet.myaction.raiseforother');
            $scope.canModifyCreatedBy = PermissionService.hasPermission('intranet.action.modifycreatedby.all');
            $scope.config = {};
            $scope.preferences = {
                view: {},
                links: []
            };
            $scope.informIntegrationEnabled = EnabledFeaturesService.hasFeature('action_link_inform');

            $scope.addAction = function () {
                $scope.saveAction();
            };

            $scope.editAction = function (id) {
                ActionService.getAction(id).then(function (response) {
                    $scope.saveAction(response.data);
                });
            };

            $scope.saveAction = function (action) {
                $scope.finishProcessing();
                $scope.bodyTemplate('/app/widget/action/addaction.html');
                $scope.action = _.extend({}, action);
                if (action !== undefined) {
                    $scope.action.actionee = {key: action.actionee.data.id, value: action.actionee.data.fullName};
                    $scope.action.created_by = action.actionRaisedBy || null;
                } else {
                    $scope.action.created_by = user;
                    $scope.action.created_by.firstName = user.first_name || '';
                    $scope.action.created_by.lastName = user.last_name || '';
                    if (!$scope.canRaiseOnBehalfOfOther) {
                        $scope.action.actionee = {key: user.id, value: user.first_name + ' ' + user.last_name};
                    }
                }

                $scope.callback('ok', function ($modalScope, $modalInstance) {
                    $scope.startProcessing('ok');
                    if (!$scope.form.addActionForm.$valid || $scope.form.addActionForm.dueDate.$modelValue.date === null) {
                        FormService.markAllDirty($scope.form.addActionForm);
                        MessageService.error('Validation error.');
                        $scope.finishProcessing();
                        return;
                    }

                    ActionService.saveAction(createAction($modalScope.action, $modalScope, $modalInstance)).then(function () {
                        $scope.finishProcessing();
                        MessageService.success('Action saved successfully.');
                        $modalInstance.dismiss('cancel');
                        $window.location.reload();
                    }, function () {

                        $scope.finishProcessing();
                        MessageService.error('Error saving action. Please try again.');
                    });
                });

                $scope.callback('cancel', function ($modalScope, $modalInstance) {
                    return $modalInstance.dismiss('cancel');
                });
            };

            $scope.closeAction = function (action) {
                $scope.startProcessing('status_btn_close');

                if (!$scope.form.addActionForm.$valid || $scope.form.addActionForm.dueDate.$modelValue.date === null) {
                    FormService.markAllDirty($scope.form.addActionForm);
                    MessageService.error('Validation error.');
                    $scope.finishProcessing();
                    return;
                }

                ActionService.saveAction(createAction(action)).then(function () {
                    ActionService.closeAction(action.id).then(function () {
                        $scope.finishProcessing();
                        MessageService.success('Action ' + action.id + ' is closed.');
                        $modalInstance.dismiss('cancel');
                        $window.location.reload();
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error closing action. Please try again.');
                        $modalInstance.dismiss('cancel');
                    });
                });
            };

            $scope.reopenAction = function (action) {
                $scope.startProcessing('status_btn_reopen');
                ActionService.reopenAction(action.id).then(function () {
                    $scope.finishProcessing();
                    MessageService.success('Action ' + action.id + ' is reopened.');
                    $scope.editAction(action.id);
                }, function () {
                    $scope.finishProcessing();
                    MessageService.error('Error reopening action. Please try again.');
                    $modalInstance.dismiss('cancel');
                });
            };

            function createAction(form) {
                var repeat = _.nested(form.dueDate, 'repeat.value', null);
                var interval = _.nested(form.dueDate, 'interval.value', null);
                var on = _.nested(form.dueDate, 'on', null);

                if (on === null || _.isEmpty(on)) {
                    on = null;
                } else {
                    on = !_.isArray(on) ? [on] : on;
                    on = _(on).map(function (onOption) {
                        return onOption.value;
                    }).sortBy().value();
                }

                var dueDate = {
                    date: moment(form.dueDate.date).format('YYYY-MM-DD'),
                    repeat: repeat,
                    interval: interval,
                    on: on
                };

                var obj = {
                    id: form.id || null,
                    actionee: form.actionee.key,
                    status: form.status,
                    actionText: form.actionText,
                    dueDate: dueDate,
                    dateClosed: form.dateClosed,
                    actionCreatedBy: form.created_by.key || null
                };

                if ($scope.informIntegrationEnabled) {
                    obj.linkedFormVersionId = $('select[name="linkedFormVersionId"]').val();
                }

                return obj;
            }

            $scope.showModal = function (id) {
                var title = 'Edit Widget';
                ModalService.create({
                    title: title,
                    id: id || null
                });
            };

            $scope.startProcessing('bodyLoading');
            if ($scope.$modalOptions.id !== null) {
                $scope.title('Edit Action');
                $scope.editAction($scope.$modalOptions.id);
            } else {
                $scope.title('Add Action');
                $scope.addAction();
            }
        }
    ]);
})();
