(function () {
    'use strict';
    angular.module('lucidity').directive('ngStopPropagation', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.on('click', function (event) {
                    event.stopPropagation();
                });
            }

        };
    });

})();




