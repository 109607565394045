(function () {
  'use strict';
  angular.module('lucidity').factory('NavigationService', [
    '$rootScope',
    '_',
    '$templateCache',
    '$interpolate',
    '$window',
    function ($rootScope, _, $templateCache, $interpolate, $window) {
      var service = {};
      service.actions = {};

      service.addActions = function (actions) {
        _.each(actions, function (action) {
          var actionName = action.id;
          if (actionName) {
            service.actions[actionName] = service.createAction(action);
          }
        });
      };

      service.action = function (name) {
        return service.actions[name] || function () {
          };
      };

      service.createAction = function (action) {
        return function (scope, context) {

          var condition = action.condition || function (context) {
              return true;
            };

          var hashKey = action.$$hashKey;

          //Parse out values
          angular.forEach(action, function (value, key) {
            if (angular.isString(value) && !key.match(/^\$/)) {
              var cacheKey = hashKey + key;
              if ($templateCache.get(cacheKey) === undefined) {
                $templateCache.put(cacheKey, $interpolate(value));
              }

              action[key] = $templateCache.get(cacheKey)(context);
            }
          });

          //Check condition
          if (condition(context)) {
            if (action.href) {
              $window.open(action.href);
            } else if (action.action) {
              if (angular.isFunction(action.action)) {
                action.action(scope.$parent, context);
              } else {
                scope[action.action](context);
              }
            }
          }
        };

      };

      return service;
    },
  ]);
})();
