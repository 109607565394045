(function () {
    'use strict';
    angular.module('lucidity').directive('ngCkeditorInline', [
        '$window',
        'CKEDITOR',
        'ckeditorInlineConfig',
        '$compile',
        '$location',
        '$route',
        'EventService',
        function ($window, CKEDITOR, ckeditorInlineConfig, $compile, $location, $route, EventService) {
            return {
                require : '?ngModel',
                link : function($scope, elm, attr, ngModel)
                {
                    elm.attr("contenteditable", "true");


                    var editor = CKEDITOR.inline(elm[0],ckeditorInlineConfig);

                    if (attr.ngCkeditor) {
                        CKEDITOR.config.bodyClass = attr.ngCkeditor;
                    }

                    editor.$scope = $scope;
                    editor.$compile = $compile;
                    editor.$route = $route;
                    editor.$location = $location;
                    editor.EventService = EventService;

                    if (!ngModel) return; // do nothing if no ng-model

                    ngModel.$render = function(value){
                        editor.setData(ngModel.$viewValue);
                    }

                    elm.on('blur keyup change', function () {
                        $scope.$apply(readViewText);
                    });

                    function readViewText() {
                        // console.log('change now', editor.getData());
                        ngModel.$setViewValue(editor.getData());
                    }



                }
            };
        }
    ]);
})();
