(function () {
        'use strict';
        angular.module('lucidity').controller('PageActionController', [
        '$scope',
        '_',
        'CommandService',
        '$modal',
        '$window',
        function ($scope, _, CommandService, $modal, $window) {

            $scope.command = function () {
                var command = _.toArray(arguments).slice(0, 1)[0];
                var args = _.toArray(arguments).slice(1);

                CommandService.fireCommand(command, args);
            };
        },
    ]);
})();
