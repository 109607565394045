(function () {
  'use strict';

  angular
    .module("lucidity")
    .filter('startFrom', function () {

      return function (input, start) {
        if (input) {
          start = +start; //parse to int
          return input.slice(start);
        }

        return null;
      }
    });

  angular
    .module('lucidity')
    .controller('notificationEvent', [
      '$scope',
      '$http',
      '$location',
      function ($scope, $http, $location) {

        var baseUrl = $location.$$protocol + '://' + $location.$$host;

        $scope.master = [{}];
        $scope.template = {};
        $scope.loading = 1;
        $scope.server_busy = 0;
        $scope.current_page = 1;
        $scope.per_page = 5;
        $scope.total_items = 0;


        $scope.nextStep = function () {
          $scope.$emit('eventTriggerController', $scope);
        }

        $scope.searchTriggers = function () {

          var searchUrl = baseUrl + '/intranet/api/v1/notification-triggers/search?';
          var keyword = '';
          var category = '';
          $scope.loading = 1;

          if (undefined !== $scope.template.category) {
            category = $scope.template.category;
            searchUrl = searchUrl + 'category=' + category + '&';
          }

          if (undefined !== $scope.template.keyword) {
            keyword = $scope.template.keyword
            searchUrl = searchUrl + 'keyword=' + keyword + '&';
          }

          $http.get(searchUrl)
            .then(function success(serverResponse) {
                $scope.master.triggers = cruse.helper.htmlEncodeObject(serverResponse.data.data);
                $scope.loading = 0;
                $scope.total_items = $scope.master.triggers.length;

              }, function failed(serverResponse) {
                $scope.master = [{}];
                $scope.master.triggers = [];
                $scope.loading = 0;
              }
            );
        }

        $scope.saveTrigger = function () {
          $scope.server_busy = 1;

          $http.post(baseUrl + "/intranet/api/v1/notification-template/choose-trigger", $scope.template)
            .then(function success(serverResponse) {
                location.replace('/system/notificationtemplate/updatetemplate?template_id=' + serverResponse.data);
              }, function failed(serverResponse) {

              }
            );
        }

        $scope.searchTriggers();

        $scope.setPage = function (page_number) {
          $scope.current_page = page_number;
        };

        $scope.openConfirmationCancelModal = function () {
          $scope.$emit('eventCancelNotification', false);
        }
      }
    ]);

  angular
    .module('lucidity')
    .controller('notificationPlaceholder', [
      '$scope',
      '$http',
      'CKEDITOR',
      '$location',
      '$rootScope',
      function ($scope, $http, CKEDITOR, $location) {

        $scope.base_url = $location.$$protocol + '://' + $location.$$host;
        $scope.trigger_id = '';
        $scope.placeholders = [];
        $scope.sectionPlaceholders = [];
        $scope.organizedPlaceholders = {};
        $scope.dragCommand = "drag(event,orgpl;";
        $scope.subject = '';
        $scope.messageBody = '';
        $scope.isCreateTemplate = true;
        $scope.errorMessage = '';
        $scope.subjectNameExist = true;
        $scope.attachments = [];
        $scope.selectedAttachments = [];

        var urlParams = new URLSearchParams(window.location.search);
        $scope.templateId = urlParams.get('template_id');

        $scope.$watch('subject', function (newValue, oldValue, scope) {
          $scope.checkSubjectIfExist();
          if (newValue === '') {
            $scope.$emit('eventCheckRequiredField', ['template', 'status', false]);
          } else {
            $scope.$emit('eventCheckRequiredField', ['template', 'status', true]);
          }
        });

        $scope.$watch('messageBody', function (newValue, oldValue, scope) {
          if (newValue === '') {
            $scope.$emit('eventCheckRequiredField', ['template', 'status', false]);
          } else {
            $scope.$emit('eventCheckRequiredField', ['template', 'status', true]);
          }
        });

        $scope.$on("eventTriggerHasChanged", function (events, data) {
          if (data) {
            $scope.subject = '';
            $scope.messageBody = '';
          }
        });

        $scope.$on("eventSendTriggerToPlaceholder", function (events, data) {

          $scope.trigger_id = data.trigger_id;

          if ('undefined' != typeof data.isUpdateTemplate) {
            $scope.isCreateTemplate = false;
            $scope.isUpdateTemplate = data.isUpdateTemplate;

            $scope.subject = data.template.subject;
            $scope.messageBody = data.template.body;
            $scope.selectedAttachments = data.template.attachment;
          }

          $scope.loadPlaceholders();
          $scope.loadAttachments();
        });

        $scope.$on("requestTemplateDetails", function (events, data) {
          var dataObj = {
            subject: $scope.subject,
            body: $scope.messageBody,
            attachment : $scope.attachments
          };

          $scope.$emit('receiveTemplateDetails', dataObj);
        });


        $scope.checkSubjectIfExist = function () {

          $scope.subjectNameExist = true;

          var dataObj = {
            subject: $scope.subject,
            body: $scope.messageBody,
            attachment : $scope.attachments
          };

          $http.post($scope.base_url + "/intranet/api/v1/notification/check-subject-if-exist/", dataObj)
            .then(function success(serverResponse) {
                if (serverResponse.data.data.length === 0) {
                  $scope.subjectNameExist = false;
                  $scope.errorMessage = '';
                } else {

                  if ($scope.checkIfExistInEditPage(serverResponse.data.data)) {
                    $scope.subjectNameExist = true;
                    $scope.errorMessage = '* subject name already exist!';
                    $scope.$emit('disableStepper', 'customizeTemplate');
                  }
                }
              }, function failed(serverResponse) {

              }
            );
        }


        $scope.checkIfExistInEditPage = function (listOfIds) {

          var isFoundTemplateId = true;
          angular.forEach(listOfIds, function (value, key) {

            if (listOfIds[key]["id"] === $scope.templateId) {
              isFoundTemplateId = false;
            }
          })

          return isFoundTemplateId;

        }

        $scope.nextStep = function () {
          $scope.errorMessage = '';
          var dataObj = {
            subject: $scope.subject,
            body: $scope.messageBody,
            attachment : $scope.attachments
          };

          $scope.$emit('eventPlaceholderController', dataObj);
        }

        $scope.saveTemplate = function () {

          var templateUrl = $scope.base_url + '/notification-template/compose-body/' + $scope.trigger_id;
          var dataObj = {
            subject: $scope.subject,
            body: $scope.messageBody,
            attachment : $scope.attachments
          };

          $http.post(templateUrl, dataObj)
            .then(function success(resp) {
              $scope.loading = false;
              location.replace('/system/notificationtemplate/addrecipients/' + $scope.trigger_id);
            }, function failed(resp) {
              $scope.loading = false;
            });

        }

        $scope.createPlaceholder = function (placeholder) {

          if (placeholder) {
            var editor = CKEDITOR.instances[$('#editor_instance').val()];
            var data = {
              label: placeholder['label'],
              content: placeholder['tag'],
            };

            editor.execCommand('lucidityplaceholdersWidget', {startupData: {pl: data}});
            editor.execCommand('blur');
            editor.execCommand('blurBack');
            var range = editor.getSelection().getRanges()[0];
            var endNode = range.endContainer;
            var endOffset = range.endOffset;
            range.setStart(endNode, endOffset);
            range.setEnd(endNode, endOffset);
            editor.getSelection().selectRanges([range]);
            editor.focus();

            editor.fire('change');
          }
        };

        $scope.loadTemplate = function () {

          var templateUrl = $scope.base_url + '/notification-template/' + $scope.trigger_id;
          $scope.loading = true;
          $http.get(templateUrl)
            .then(function success(serverResponse) {
                $scope.subject = cruse.helper.htmlEncodeString(serverResponse.data.data.subject);
                $scope.messageBody = cruse.helper.htmlEncodeString(serverResponse.data.data.messageBody);
                $scope.loading = false;
              }, function failed(serverResponse) {
                $scope.loading = false;
              }
            );
        };

        $scope.loadPlaceholders = function () {

          $scope.organizedPlaceholders = {};

          var templateUrl = $scope.base_url + '/intranet/api/v1/placeholder?trigger_id=' + $scope.trigger_id;
          $http.get(templateUrl)
            .then(function success(serverResponse) {
                $scope.placeholders = cruse.helper.htmlEncodeObject(serverResponse.data.data);
                organizePlaceholders();
              }, function failed(serverResponse) {
              }
            );
        };

          $scope.loadAttachments = function () {
             var templateUrl = $scope.base_url + '/intranet/api/v1/notification-trigger/' + $scope.trigger_id + '/available-files';
              $http.get(templateUrl)
                  .then(function success(serverResponse) {
                          $scope.attachments = [];
                          angular.forEach(serverResponse.data, function(value, key){
                                angular.forEach(value, function(classFile, classLabel) {
                                    var obj = {label : classLabel, selected : false};
                                    $scope.attachments.push(obj);
                                });
                          });
                          angular.forEach($scope.selectedAttachments, function(selected){
                              var i = 0;
                              for (i; i < $scope.attachments.length ; i++) {
                                  var obj = $scope.attachments[i];
                                  if (selected.label === obj.label) {
                                      $scope.attachments[i].selected = selected.selected;
                                  }
                              }
                          });

                      }, function failed(serverResponse) {
                      }
                  );
          };

          $scope.toggleAttachmentSelection = function(attachment) {
              var idx = $scope.selectedAttachments.indexOf(attachment);

              // Is currently selected
              if (idx > -1) {
                  $scope.selectedAttachments.splice(idx, 1);
              }

              // Is newly selected
              else {
                  $scope.selectedAttachments.push(attachment);
              }
          };

          // Helper method to get selected attachments
          $scope.getSelectedAttachments = function() {
              return filterFilter($scope.attachments, { selected: true });
          };

          // Watch attachments for changes
          $scope.$watch('attachments|filter:{selected:true}', function (nv) {
              $scope.selectedAttachments = nv.map(function (attachment) {
                  return attachment.label;
              });
          }, true);


          function organizePlaceholders() {

          $.each($scope.placeholders, function (i, placeholder) {
            if ($.inArray(placeholder.section, $scope.sectionPlaceholders) === -1) {
              $scope.sectionPlaceholders.push(placeholder.section);
            }
          });

          $.each($scope.sectionPlaceholders, function (i, section) {
            $.each($scope.placeholders, function (i, placeholder) {
              if (section === placeholder.section) {
                if ($scope.organizedPlaceholders[section] && $scope.organizedPlaceholders[section] instanceof Array) {
                  $scope.organizedPlaceholders[section].push(placeholder);
                } else {
                  $scope.organizedPlaceholders[section] = [];
                  $scope.organizedPlaceholders[section].push(placeholder);
                }
              }
            });
          });
        }

        //ui related
        $scope.closeOffcanvas = function () {
          document.getElementById('sidenav-content').style.width = "0";
          document.getElementById('notification-main').style.marginRight = "0";
        };

        $scope.toggleSearchMessage = function (search) {
          if (search.plSearch.length === 0) {
            $('#no_pl_found').hide();
            return;
          }

          var count = 0;
          $('*[id=pl_list]').each(function () {
            if ($(this).children().length >= 1) {
              count++;
            }
          });
          if (count === 0)
            $('#no_pl_found').show();
          else
            $('#no_pl_found').hide();
        };

        $scope.openConfirmationCancelModal = function () {
          $scope.$emit('eventCancelNotification', false);
        }

      }
    ])
})();
