(function () {
  'use strict';
  angular.module('lucidity').directive('ngFlowUpload', [
    '_',
    'ValueParseService',
    function (_, ValueParseService) {
      var directive = {};
      directive.replace = false;
      directive.restrict = 'E';
      directive.templateUrl = '/app/element/flow-upload/uploadelement.html';
      directive.scope = {
        ngModel: '=',
        extension: '=ngFileExtension',
        filename: '=ngFileName',
        defaultValue: '=ngDefaultValue',
        disableSubmit: '=?ngDisableSubmit',
        persisted: '=?ngFilePersisted',
      };
      directive.link = function (scope, elem, attrs) {
        scope.name = attrs.name;
        if (scope.defaultValue) {
          scope.ngModel = scope.defaultValue;
        }
        /**
         * Handle file upload completed
         * @param file
         * @param response
         */
        scope.fileAdded = function (file, response) {
          file.isUploading = false;
          file.isUploaded = true;
          var data = ValueParseService.jsonParse(response);
          scope.ngModel = data.data.filelink;
          scope.extension = data.data.extension;
          if (!scope.filename) {
            scope.filename = data.data.filename;
          }

          scope.disableSubmit = false;
        };

        /**
         * Handle file submitted for upload
         * @param flow
         * @param files
         */
        scope.fileSubmitted = function (flow, files) {
          //Set name in input to use file name
          flow.upload();
          scope.disableSubmit = true;
          scope.persisted = 0;
        };

        /**
         * Handle error when uploading
         * @param file
         * @param message
         * @param flow
         */
        scope.fileError = function (file, message, flow) {
          file.isError = true;
          file.isUploading = false;
          var data = ValueParseService.jsonParse(message);
          file.errorMessage = data.data;
        };
      };

      return directive;
    },]);
})();
