(function () {
  'use strict';
  angular.module('lucidity').controller('ModalController', [
    'ModalService',
    '$scope',
    '$modalInstance',
    '$modalOptions',
    function (ModalService, $scope, $modalInstance, $modalOptions) {
      ModalService.init($scope, $modalInstance, $modalOptions).loadBody();
    },
  ]);
})();
