(function () {
  'use strict';
  angular.module('lucidity').controller('CollapsableSidebarController', CollapsableSidebarController);

  CollapsableSidebarController.$inject = ['$sessionStorage', '$window', '_'];

  function CollapsableSidebarController($sessionStorage, $window, _) {
    var vm = this;

    var key = 'sidebarisCollapsed';

    vm.$storage = $sessionStorage;

    vm.isCollapsed = _.nested(vm.$storage, key, false);
    vm.toggle = toggle;

    function toggle() {
      vm.$storage[key] = vm.isCollapsed = !vm.isCollapsed;
    }
  }

})();
