(function () {
    'use strict';
    angular.module('lucidity').controller('WidgetController', [
        '$scope',
        '$window',
        'Restangular',
        'MessageService',
        'ModalService',
        '_',
        'WidgetService',

        function ($scope, $window, Restangular, MessageService, ModalService, _, WidgetService) {
            $scope.config = {};
            $scope.preferences = {};
            $scope.showEdit = function () {
                $scope.config = _.isEmpty($scope.config) ? {} : $scope.config;
                $scope.preferences = _.isEmpty($scope.preferences) ? {} : $scope.preferences;
                var title = 'Edit Widget';
                var controllerOptions = {
                    title: title,
                    dynamicTemplateUrl: $scope.config.editUrl,
                    config: $scope.config,
                    preferences: $scope.preferences,
                };

                var modalOptions = {};
                if ($scope.config.controller) {
                    modalOptions.controller = $scope.config.controller;
                }

                var editModal = ModalService.create(controllerOptions, modalOptions);

                return editModal.result.then(function (modal) {
                    MessageService.success('Widget Updated');
                    $window.location.reload();
                });
            };

            $scope.showModal = function (id) {
                ModalService.create({
                    title: 'Edit Widget',
                    id: id || null,
                }, {
                    controller: $scope.config.controller,
                });
            };

            $scope.toggleWidgetDisplay = function () {

                WidgetService.widgetdisplay($scope.config).then(function (response) {
                    var data = response.data.plain();
                    $scope.config.display = data.display;
                });


            };
            $scope.isEnabled = function () {
                return $scope.config.display;
            };


        },
    ]);
})();

