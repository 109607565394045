(function () {
  'use strict';
  angular.module('lucidity').directive('ngOnDrop', [
      '$parse',
      '$rootScope',
        function ($parse, $rootScope) {
          return function (scope, element, attr) {
            var dragging = 0; //Ref. http://stackoverflow.com/a/10906204
            var dropChannel = 'defaultchannel';
            var dragChannel = '';
            var dragEnterClass = attr.dragEnterClass || 'on-drag-enter';
            var dragHoverClass = attr.dragHoverClass || 'on-drag-hover';

            function onDragOver(e) {

              if (e.preventDefault) {
                e.preventDefault(); // Necessary. Allows us to drop.
              }

              if (e.stopPropagation) {
                e.stopPropagation();
              }

              e.dataTransfer.dropEffect = 'move';
              return false;
            }

            function onDragLeave(e) {
              dragging--;
              if (dragging === 0) {
                element.removeClass(dragHoverClass);
              }
            }

            function onDragEnter(e) {
              dragging++;
              $rootScope.$broadcast('ANGULAR_HOVER', dropChannel);
              element.addClass(dragHoverClass);
            }

            function onDrop(e) {
              if (e.preventDefault) {
                e.preventDefault(); // Necessary. Allows us to drop.
              }

              if (e.stopPropagation) {
                e.stopPropagation(); // Necessary. Allows us to drop.
              }

              var data = e.dataTransfer.getData('Text');
              data = angular.fromJson(data);
              var fn = $parse(attr.ngOnDrop);
              scope.$apply(function () {
                fn(scope, {
                  $data: data,
                  $event: e,
                });
              });

              element.removeClass(dragEnterClass);
            }

            $rootScope.$on('ANGULAR_DRAG_START', function (event, channel) {
              dragChannel = channel;
              if (dropChannel === channel) {

                element.bind('dragover', onDragOver);
                element.bind('dragenter', onDragEnter);
                element.bind('dragleave', onDragLeave);

                element.bind('drop', onDrop);
                element.addClass(dragEnterClass);
              }

            });

            $rootScope.$on('ANGULAR_DRAG_END', function (e, channel) {
              dragChannel = '';
              if (dropChannel === channel) {

                element.unbind('dragover', onDragOver);
                element.unbind('dragenter', onDragEnter);
                element.unbind('dragleave', onDragLeave);

                element.unbind('drop', onDrop);
                element.removeClass(dragHoverClass);
                element.removeClass(dragEnterClass);
              }
            });

            $rootScope.$on('ANGULAR_HOVER', function (e, channel) {
              if (dropChannel === channel) {
                element.removeClass(dragHoverClass);
              }
            });

            attr.$observe('dropChannel', function (value) {
              if (value) {
                dropChannel = value;
              }
            });

          };
        },
    ]);
})();
