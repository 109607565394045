(function () {
  'use strict';
  angular.module('lucidity').factory('TreeFactory', [
    '_',
    'Arboreal',
    function (_, Arboreal) {
      var factory = {};
      var originalAppendChild = Arboreal.prototype.appendChild;

      Arboreal.prototype.STATE_NOT_LOADING = 0;
      Arboreal.prototype.STATE_LOADING = 1;
      Arboreal.prototype.STATE_LOADED = 2;
      Arboreal.prototype.currentState = Arboreal.prototype.STATE_NOT_LOADING;
      Arboreal.prototype.hasChildren = false;
      Arboreal.prototype.state = function (state) {
        this.currentState = state;
        return this;
      };

      Arboreal.prototype.hasState = function (state) {
        return this.currentState === state;
      };

      Arboreal.prototype.find = function (finder) {
        var match = null;
        var iterator = (typeof finder === 'function') ?
          finder : function (node) {
          if (finder === node.id || parseInt(node.id, 10) === parseInt(finder, 10)) {
            match = node;
            return false;
          }
        };

        this.traverseDown(function (node) {
          if (iterator.call(this, node)) {
            match = node;
            return false;
          }
        });

        return match;
      };

      Arboreal.prototype.loadChildren = function (id, dataSource, options) {
        options = _.extend({
          children: 'children.data',
          cacheData: true,
          callback: function (child) {
            return child;
          },

          created: function (node) {

          },

          initData: {},
        }, options);

        var node = this.find(id);
        if (!options.cacheData) {
          node.promise = undefined;
        }

        if (node.promise === undefined) {
          node.currentState = node.STATE_LOADING;

          var appendChildren = function (appendedNode, item) {
            _(item).nested(options.children).each(function (child) {
              child = _.merge({}, options.initData, options.callback(child));
              var childNode = appendedNode.find(child.id);
              if (childNode) {
                childNode.data = child;
              } else {
                appendedNode.appendChild(child, child.id);
              }

              childNode = childNode ? childNode : appendedNode.find(child.id);
              options.created(childNode);
              if (_.nested(child, options.children)) {
                appendChildren(childNode, child);
              }
            });

            return appendedNode;
          };

          node.promise = dataSource().then(function (item) {
            node.currentState = node.STATE_LOADED;
            return appendChildren(node, item.data.plain());
          });
        }

        return node.promise;
      };

      Arboreal.prototype.appendChild = function (data, id) {
        this.hasChildren = true;
        if (data instanceof Arboreal && data.parent !== this) {
          this.children.push(data);
          data.parent = this;
          return this;
        } else if (!this.find(id)) {
          return originalAppendChild.call(this, data, id);
        }
      };

      Arboreal.prototype.removeChildren = function (deep) {
        deep = deep || false;
        if (deep) {
          this.traverseDown(function (node) {
            node.removeChildren(deep);
          });
        }

        var child;
        while ((child = this.children.pop())) {
          child.remove();
        }

        if (this.children.length === 0) {
          this.hasChildren = false;
        }

        return this;
      };

      Arboreal.prototype.moveNode = function (newParent) {
        if (newParent instanceof Arboreal) {
          this.remove();
          newParent.appendChild(this);
        }
      };

      Arboreal.prototype.clone = function (parent) {
        var clonedNode = _.extend(new Arboreal(parent, this.data, this.id), this, { children: [] });
        for (var i = 0; i < this.children.length; i++) {
          clonedNode.children.push(this.children[i].clone(clonedNode));
        }

        return clonedNode;
      };

      factory.create = function (id) {
        return new Arboreal(undefined, undefined, id);
      };

      return factory;
    }, ]);
})();
