'use strict';

angular
    .module('lucidity')
    .controller('EventFilterController', [
    '$scope',
    '$http',
    '$location',
    'ModalService',
    '$modal',
    '$interval',
    function ($scope, $http, $location, ModalService, $modal,$interval) {

        $scope.base_url = $location.$$protocol + '://' + $location.$$host;

        $scope.suggestions = [
            'action.firstname',
            'action.lastname'
        ];

        $scope.filter = {};
        $scope.filter.expression = '';
        $scope.filter.modules = null;
        $scope.filter.status = '';
        $scope.filter.validationMessage = '';
        $scope.modal = {};
        $scope.isCreateTemplate = true;

        $scope.$watch('filter.expression', function (newValue, oldValue, scope) {
            if(newValue === ''){
                $scope.filter.status = '';
                $scope.$emit('eventChangeConditions', $scope.filter);
                $scope.$emit('eventCheckRequiredField', ['filter','status',true]);

            }
        });

        $scope.$watch('filter.status', function (newValue, oldValue, scope) {
            if(newValue === 'invalid' || newValue === 'validating'){
                $scope.$emit('eventCheckRequiredField', ['filter','status',false]);
            } else {
                $scope.$emit('eventCheckRequiredField', ['filter','status',true]);
            }
        });

        $scope.$on("eventTriggerHasChanged", function (events, data) {
            if(data){
                $scope.filter = {};
                $scope.filter.expression = '';

                $scope.filter.validationMessage = '';
            }
        });

        $scope.$on("eventSendTriggerToConditions", function (events, data) {

            $scope.filter.trigger_id = data.trigger_id;
            $scope.filter.expression = data.filter.expression;
            $scope.filter.modules = data.filter.modules;

            if (typeof data.isUpdateTemplate !== 'undefined') {
                $scope.isCreateTemplate = false;
                $scope.isUpdateTemplate = data.isUpdateTemplate;
            }

            $scope.assembleEventFilter(data);

        });

        $scope.$on("requestFilter", function () {
            $scope.$emit('receiveFilterDetails', $scope.filter );
        });

        $scope.nextStep = function () {
            $scope.$emit('eventConditionsController', $scope.filter );
        }

        $scope.onBlur = function () {
            if($scope.filter.status === 'invalid'){
                $scope.expressionIsValid();
            }
        }

        $scope.openConfirmationCancelModal = function () {
            $scope.$emit('eventCancelNotification', false);
        }

        $scope.assembleEventFilter = function(data) {
            $scope.selection = {};
            $scope.selection.modules = '';

            var triggerData = data.triggers.filter(function(trigger) {
                return parseInt(trigger.id) === parseInt(data.trigger_id);
            });

            if (triggerData) {
                $http({
                    method: 'GET',
                    url: $scope.base_url + triggerData[0].config.getUrl,
                })
                    .success(function (response) {
                        $scope.selection = response.data;
                        $scope.conditionTemplate = triggerData[0].config.templatePath;
                    }).error(function (response) {

                });
            }
        }
    }
]);
