(function () {
  'use strict';
  angular.module('lucidity').directive('ngPopulateFrom', [
      'EventService',
      '_',
      'ValueParseService',
        function (EventService, _, ValueParseService) {
          var directive = {};
          directive.restrict = 'AE';
          directive.require = '?ngModel';
          directive.link = function (scope, element, attr, ngModel) {
            if (ngModel) {
              var watchElement = _.ngSafe(attr.ngPopulateFrom.match(/^[^\.]+/)[0]);
              var watchProperty = _.ngSafe(attr.ngPopulateFrom.replace(/^[^\.]+\./, ''));
              var populateWhenEmpty = ValueParseService.booleanParse(attr.ngPopulateWhenEmpty);
              EventService.on('element.update', watchElement, function (event, data) {
                var isEmpty = !ngModel.$modelValue;
                if (!populateWhenEmpty || populateWhenEmpty && isEmpty) {
                  var property = _.nested(data.extendedValue, watchProperty, '');
                  ngModel.$setViewValue(property);
                  ngModel.$render();
                }
              });
            }
          };

          return directive;
        },
    ]);
})();
