(function () {
    'use strict';
    /**
     * trigger point for opening a new modal
     * @param $scope
     * @param $modal
     * @param $http
     * @param ModalService
     */
    angular.module('lucidity').controller('AddRecipientsController', [
        '$scope',
        '$filter',
        '$modal',
        'ModalService',
        '$http',
        '$location',
        function ($scope, $filter, $modal, ModalService, $http, $location) {

            $scope.base_url = $location.$$protocol + '://' + $location.$$host;

            $scope.master = [];
            $scope.master.selectedRecipients = [];
            $scope.master.totalRecipients = 0;
            $scope.modal = [];
            $scope.modal.recipients = [];
            $scope.modal.selectedRecipients = [];
            $scope.trigger_id = null;
            $scope.isCreateTemplate = true;

            $scope.$watch('master.selectedRecipients', function (newValue, oldValue, scope) {
                console.log(newValue.length)
                if(newValue.length == 0){
                    $scope.$emit('eventCheckRequiredField', ['recipient','status',false]);
                } else {
                    $scope.$emit('eventCheckRequiredField', ['recipient','status',true]);
                }
            });

            $scope.$on("eventTriggerHasChanged", function (events, data) {
                if(data){
                    $scope.master = [];
                    $scope.master.selectedRecipients = [];
                    $scope.master.totalRecipients = 0;
                    $scope.modal.recipients = [];
                    $scope.modal.selectedRecipients = [];
                }
            });

            $scope.$on("eventSendTriggerToAddRecipients", function (events, data) {
                $scope.trigger_id = data.trigger_id;
                $scope.master.selectedRecipients = data.recipient;
                $scope.master.totalRecipients = $scope.master.selectedRecipients.length;

                for (var idx = 0; idx < $scope.master.selectedRecipients.length; idx++) {
                    var tracker = $scope.master.selectedRecipients[idx].tracker;
                    $scope.modal.selectedRecipients[tracker] = true;
                }

                if ('undefined' != typeof data.isUpdateTemplate) {
                    $scope.isCreateTemplate = false;
                    $scope.isUpdateTemplate = data.isUpdateTemplate;
                }
            });

            $scope.$on("requestRecipients", function () {
                $scope.$emit('receiveRecipientsDetails', $scope.master.selectedRecipients );
            });

            $scope.nextStep = function () {
                $scope.$emit('eventAddRecipientController', $scope.master.selectedRecipients );
            }

            $scope.gotoSetConditions = function() {
                location.replace($scope.base_url+'/system/notificationtemplate/advanceconditions/'+$scope.trigger_id);
            }

            $scope.removeRecipient = function(recipient) {
                var tracker = recipient.tracker;

                $scope.modal.selectedRecipients[tracker] = false;

                for (var idx = 0; idx < $scope.master.selectedRecipients.length; idx++) {
                    if (tracker == $scope.master.selectedRecipients[idx].tracker) {
                        $scope.master.selectedRecipients.splice(idx, 1);
                        break;
                    }
                }
                if($scope.master.selectedRecipients.length == 0){
                    $scope.$emit('eventCheckRequiredField', ['recipient','status',false]);
                }
                $scope.master.totalRecipients -= 1;

            }

            $scope.openModal = function () {

                var modalOptions = {
                    backdrop: 'static',
                };

                var controllerOptions = {
                    title: 'Add Recipients',
                    templateUrl: '/app/recipients/recipients-modal.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$filter', '$modalInstance', '$http', function($scope, $filter, $modalInstance, $http) {
                            $scope.search = [{}];
                            $scope.search.role = 'All';
                            $scope.search.search_term = '';

                            $scope.loading = 1;
                            $scope.current_page = 1;
                            $scope.per_page = 5;
                            $scope.total_items = 0;

                            $scope.testcheck = true;
                            $http.get($scope.base_url+'/intranet/api/v1/template-recipients/'+$scope.trigger_id)
                                .then(function success(srv_resp) {
                                        $scope.modal.allRecipients = srv_resp.data.data;

                                    }, function failed(srv_resp) {
                                        $scope.modal.allRecipients = [];
                                    }
                                );

                            $scope.getAvailableRecipients = function(category, search_term) {
                                $scope.loading = 1;
                                var search_url = $scope.base_url+'/intranet/api/v1/template-recipients/'+$scope.trigger_id+'?category='+category+'&keyword='+search_term;

                                $http.get(search_url)
                                    .then(function success(srv_resp) {
                                            $scope.modal.recipients = srv_resp.data.data;

                                            $scope.loading = 0;

                                            $scope.total_items = $scope.modal.recipients.length;

                                        }, function failed(srv_resp) {
                                            $scope.modal = [{}];
                                            $scope.loading = 0;
                                        }
                                    );
                            }

                            $scope.addToList = function(id) {
                                if (true == $scope.modal.selectedRecipients[id]) {
                                    $scope.master.totalRecipients--;
                                } else {
                                    $scope.master.totalRecipients++;
                                }
                            };

                            $scope.searchRecipient = function (category, search_term) {
                                $scope.current_page = 0;
                                $scope.getAvailableRecipients(category, search_term);
                            };

                            $scope.setPage = function (page_number) {
                                $scope.current_page = page_number;
                            };

                            $scope.chooseRecipients = function () {

                                $scope.master.selectedRecipients = [];

                                var ctr = 0;

                                for (var key in $scope.modal.selectedRecipients) {

                                    if (true === $scope.modal.selectedRecipients[key]) {
                                        var currentRecipient = $scope.modal.allRecipients.filter(function(item) {
                                            return item.tracker == key
                                        })[0];

                                        $scope.master.selectedRecipients.push(currentRecipient);
                                    }

                                    ctr++;
                                }

                                $modalInstance.close();
                            };

                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };

                            $scope.getAvailableRecipients('All', '');
                        }]
                };

                $modal.open(controllerOptions);

            };

            $scope.openConfirmationCancelModal = function () {
                $scope.$emit('eventCancelNotification', false);
            }

            $scope.openSetupList = function(modalName) {
                var modalOptions = {
                    backdrop: 'static',
                };


                var controllerOptions = {
                    title: 'Add Recipients',
                    templateUrl: '/app/recipients/recipient-setup-list-modal.html',
                    controller: [
                        '$scope', '$filter', '$modalInstance', '$http','$timeout', function($scope, $filter, $modalInstance, $http, $timeout) {

                            $scope.modalName = modalName;

                            var sub = [{ "name":"High"},
                                { "name":"Medium"},
                                { "name":"Low"
                                }];

                            var sub_cat = [{
                                "id": "1",
                                "name": "Potential Incident Category",
                                "tag_name": "potential_incident_category",
                                "sub_cat": sub
                            },
                                {
                                    "id": "2",
                                    "name": "Actual Incident Category",
                                    "tag_name": "actual_incident_category",
                                    "sub_cat": sub
                                }
                            ];

                            $scope.setConditionList = [
                                {
                                    "id": "1",
                                    "name": "Incident",
                                    "open": false,
                                    "checked":false,
                                    "sub_cat": sub_cat
                                },
                                {
                                    "id": "2",
                                    "name": "Hazard",
                                    "open": false,
                                    "checked":false,
                                    "sub_cat": sub_cat
                                },
                                {
                                    "id": "3",
                                    "name": "Non-conformance",
                                    "open": false,
                                    "checked":false,
                                    "sub_cat": sub_cat
                                },
                                {
                                    "id": "4",
                                    "name": "Complaint",
                                    "open": false,
                                    "checked":false,
                                    "sub_cat": sub_cat
                                },
                                {
                                    "id": "5",
                                    "name": "Improvement Opportunity",
                                    "open": false,
                                    "checked":false,
                                    "sub_cat": sub_cat
                                }
                            ];

                            $scope.selectedConditions = [];


                            $scope.checkboxClick = function(index){
                                $timeout(function () {
                                    $scope.setConditionList[index].checked = $scope.setConditionList[index].checked ? false : true;
                                });
                            }

                            $scope.ok = function () {
                                $modalInstance.close($scope.selectedConditions);
                            };

                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }],
                };

                var modalConditionInstance = $modal.open(controllerOptions);

                modalConditionInstance.result.then(function (ConditionSetup) {
                    $scope.conditionSetup = ConditionSetup;
                });
            };
        }]);
})();


