(function () {
  'use strict';
  angular.module('lucidity').factory('FormService', [
    '_',
    function (_) {

      var service = {};

      service.markAllDirty = function (scope) {
        var _this = this;
        _.each(scope, function (value, key) {
          // We skip non-form and non-inputs
          if (!value || value.$dirty === undefined) {
            return;
          }

          // Recursively applying same method on all forms included in the form
          if (value.$addControl) {
            return _this.setAllInputsDirty(value);
          }

          // Setting inputs to $dirty, but re-applying its content in it_this
          if (value.$setViewValue) {
            return value.$setViewValue(value.$viewValue);
          }
        });
      };

      /**
       * loops through a model and sets all the values to null or to the default provided
       * @param model
       * @param defaultValues
       */
      service.resetModel = function (model, defaultValues) {
        defaultValues = defaultValues || {};
        _.each(model, function (value, key) {
          var newValue = null;
          if (defaultValues[key] !== undefined) {
            newValue = defaultValues[key];
          }

          model[key] = newValue;
        });

      };

      service.disableElement = function ($scope, elementScope) {
        if ($scope.disabled === undefined) {
          $scope.disabled = {};
        }

        $scope.disabled[elementScope] = true;
      };

      service.disableElements = function ($scope, elements) {
        angular.forEach(elements, function (elementScope) {
          service.disableElement($scope, elementScope);
        });
      };

      service.enableElement = function ($scope, elementScope) {
        if ($scope.disabled === undefined) {
          $scope.disabled = {};
        }

        $scope.disabled[elementScope] = false;
      };

      service.enableElements = function ($scope, elements) {
        angular.forEach(elements, function (elementScope) {
          service.enableElement($scope, elementScope);
        });
      };

      service.hideElement = function ($scope, elementScope) {
        if ($scope.hidden === undefined) {
          $scope.hidden = {};
        }

        $scope.hidden[elementScope] = true;
      };

      service.hideElements = function ($scope, elements) {
        angular.forEach(elements, function (elementScope) {
          service.hideElement($scope, elementScope);
        });
      };

      service.showElement = function ($scope, elementScope) {
        if ($scope.hidden === undefined) {
          $scope.hidden = {};
        }

        $scope.hidden[elementScope] = false;
      };

      service.showElements = function ($scope, elements) {
        angular.forEach(elements, function (elementScope) {
          service.showElement($scope, elementScope);
        });
      };

      service.getId = function (form) {
        return form.id.value || form.session_id.value.replace(/[^0-9]*/, '');// jscs:ignore requireCamelCaseOrUpperCaseIdentifiers
      };

      return service;
    },
  ]);
})();
