(function () {
  'use strict';

  angular.module('lucidity').factory('ActionService', ActionService);

  ActionService.$inject = ['Restangular'];

  function ActionService(Restangular) {
    var actions = Restangular.all('actions');
    var dashboardActions = Restangular.all('dashboard').all('actions');

    return {
      getWidgetPages: getWidgetPages,
      countTotalItems: countTotalItems,
      saveAction: saveAction,
      getAction: getAction,
      deleteAction: deleteAction,
      closeAction: closeAction,
      reopenAction: reopenAction
    };

    function saveAction(action) {
      if (action.id === null) {
        return actions.post(action);
      } else {
        return actions.one(action.id).customPUT(action);
      }
    }

    function getAction(actionId) {
      return Restangular.one('actions', actionId).get();
    }

    function getWidgetPages(currentPage, pageSize) {
      return dashboardActions.getList({ currentPage: currentPage, pageSize: pageSize });
    }

    function countTotalItems() {
      return dashboardActions.one('count').get();
    }
    
    function deleteAction(actionId) {
      return Restangular.one('actions', actionId).remove();
    }

    function closeAction(actionId) {
      return actions.one('close', actionId).put();
    }

    function reopenAction(actionId) {
      return actions.one('reopen', actionId).put();
    }
  }

})();

