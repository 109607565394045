(function () {
    'use strict';
    angular.module('lucidity').directive('ngFormUuid', FormUuid);

    function FormUuid() {
        var directive = {};
        directive.restrict = 'A';
        directive.controller = ['$attrs', function ($attrs) {
            var self = this;
            self.formUuid = $attrs.ngFormUuid || null;
        }];
        return directive;
    }
})();
