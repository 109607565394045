(function () {
  'use strict';
  angular.module('lucidity').directive('ngDraggable', [
      '$parse',
      '$rootScope',
        function ($parse, $rootScope) {
          return function (scope, element, attrs) {
            if (window.jQuery && !window.jQuery.event.props.dataTransfer) {
              window.jQuery.event.props.push('dataTransfer');
            }

            element.attr('draggable', false);
            attrs.$observe('ngDraggable', function (newRange) {
              element.attr('draggable', newRange);
            });

            var dragData = '';
            scope.$watch(attrs.drag, function (newValue) {
              dragData = newValue;
            });

            element.bind('dragstart', function (e) {
              var sendData = angular.toJson(dragData);
              var sendChannel = attrs.dragChannel || 'defaultchannel';
              var dragImage = attrs.dragImage || null;
              if (dragImage) {
                var dragImageFn = $parse(attrs.dragImage);
                scope.$apply(function () {
                  var dragImageParameters = dragImageFn(scope, {
                    $event: e,
                  });
                  if (dragImageParameters && dragImageParameters.image) {
                    var xOffset = dragImageParameters.xOffset || 0;
                    var yOffset = dragImageParameters.yOffset || 0;
                    e.dataTransfer.setDragImage(dragImageParameters.image, xOffset, yOffset);
                  }
                });
              }

              e.dataTransfer.setData('Text', sendData);
              $rootScope.$broadcast('ANGULAR_DRAG_START', sendChannel);

            });

            element.bind('dragend', function (e) {
              var sendChannel = attrs.dragChannel || 'defaultchannel';
              $rootScope.$broadcast('ANGULAR_DRAG_END', sendChannel);
              if (e.dataTransfer && e.dataTransfer.dropEffect !== 'none') {
                if (attrs.onDropSuccess) {
                  var fn = $parse(attrs.onDropSuccess);
                  scope.$apply(function () {
                    fn(scope, {
                      $event: e,
                    });
                  });
                }
              }
            });

          };
        },
    ]);
})();
