(function () {
    'use strict';
    angular.module('lucidity').directive('loading', [
        function () {
            return {
                restrict: 'E',
                replace:true,
                template: '<div class="modal fade" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false">' +
                                '<div class="modal-body">' +
                                    '<div class="circles-loader"></div>' +
                                '</div>' +
                            '</div>',
                link: function (scope, element, attr) {
                    scope.$watch('loading', function (val) {
                        console.log('loading...');
                        if (val)
                            $(element).modal('show');
                        else
                            $(element).modal('hide');
                    });
                }
            }
        }
    ]);
})();
