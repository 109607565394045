(function () {
  'use strict';
  angular.module('lucidity').filter('linkify', ['$sce', '$sanitize', function ($sce, $sanitize) {
    return function (htmlString) {
      return $sce.trustAsHtml(linkify(htmlString));
    };
    function linkify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      text = $sanitize(text);
      return text.replace(urlRegex, function (url) {
          // see: https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
        return '<a target="_blank" rel="noopener noreferrer" href="' + url + '">' + url + '</a>';
      });
    }
  },]);
})();
