(function () {
  'use strict';

  angular.module('lucidity').service('managementSystemPermissionsService', managementSystemPermissionsService);

  managementSystemPermissionsService.$inject = ['Restangular'];

  function managementSystemPermissionsService(Restangular) {

    return {
      getUsersPermissions: getUsersPermissions,
    };

    function getUsersPermissions(type, targetId) {
      return Restangular.all('managementsystem/permissions').one('type', type).one('target', targetId).getList();
    }
  }

})();
