(function() {
  'use strict';
  angular.module('lucidity').directive('ngHex', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, controller) {
        function toHex(value) {
          if (typeof value === 'string') {
            return (value + '').toUpperCase().replace(/[^0-9A-F]+/g, '');
          }

          return value;
        }

        element.bind('blur', function() {
          element.val(toHex(controller.$modelValue));
        });

        controller.$formatters.push(toHex);
      },
    };
  });
})();
