(function () {
  'use strict';
  angular.module('lucidity').directive('ngElementBackdrop', [
    '_',
    function (_) {
      var directive = {};
      directive.scope = {
        ngClick: '&',
        ngShow: '&',
      };
      directive.restrict = 'EA';
      directive.replace = true;
      directive.template = '<div class="element-backdrop" ></div>';

      return directive;
    }, ]);
})();
