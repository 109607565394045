(function () {
    'use strict';

    angular.module('lucidity').controller('ActionAttachmentModalController', ActionAttachmentModalController);

    ActionAttachmentModalController.$inject = ['$scope', 'FormService', 'MessageService', 'ActionAttachmentService', 'ModalService', '$modalInstance', '$modalOptions', '_'];

    function ActionAttachmentModalController($scope, FormService, MessageService, ActionAttachmentService, ModalService, $modalInstance, $modalOptions, _) { //NOSONAR
        var vm = this;
        var editTemplate = '/app/action/action-attachment-modal-edit.html';
        var addTemplate = '/app/action/action-attachment-modal-add.html';
        var footerTemplate = '/app/ui/modal/modal-footer-ok-cancel.html';

        ModalService.init($scope, $modalInstance, $modalOptions);
        $scope.form = {};
        $scope.attachment = {};
        $scope.isFileError = false;
        $scope.errorMessages = [];
        $scope.errorCode = null;

        vm.saveAttachment = saveAttachment;

        function saveAttachment() {

            $scope.attachment = $modalOptions.attachment || {};

            if ($modalOptions.mode === 'edit'){
                $scope.bodyTemplate(editTemplate);
            } else {
                $scope.bodyTemplate(addTemplate);
            }
            $scope.footerTemplate(footerTemplate);



            $scope.callback('ok', function ($modalScope, $modalInstance) {
                $scope.startProcessing('ok');

                if ($modalOptions.mode === 'edit'){
                    if (!$scope.form.editActionAttachmentForm.$valid) {
                        FormService.markAllDirty($scope.form.editActionAttachmentForm);
                        MessageService.error('Validation error');
                        $scope.finishProcessing();
                        return;
                    }
                } else if (!$scope.form.addActionAttachmentForm.$valid) {
                    FormService.markAllDirty($scope.form.addActionAttachmentForm);
                    MessageService.error('Validation error');
                    $scope.finishProcessing();
                    return;
                }


                if ($modalScope.attachment.uuid) {
                    ActionAttachmentService.editAttachment($modalOptions.identifier, $modalScope.attachment).then(function (attachment) {
                        $scope.finishProcessing();
                        $modalInstance.close(attachment);
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error saving attachment. Please try again.');
                    });
                } else {
                    ActionAttachmentService.addAttachment($modalOptions.identifier, $modalScope.attachment).then(function (attachment) {
                        $scope.finishProcessing();
                        $modalInstance.close(attachment);
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error saving attachment. Please try again.');
                    });
                }
            });
        }

        $scope.isInvalidExtension = function() {
            return ($scope.errorCode === 4015 && $scope.isFileError);
        };

        $scope.errorFile = function($file, response, $flow) {
            $file.isUploading = false;
            $file.isUploaded = false;
            $scope.disabled.ok = false;
            $file.error = true;
            $scope.isFileError = true;
            if (response !== '') {
                response = JSON.parse(response);
                if (response.result !== undefined && response.result.messages !== undefined) {
                    $scope.hasErrorMessage = true;
                    $scope.errorMessages = response.result.messages;
                    $scope.errorCode = response.result.code;
                }
            }
        };

        $scope.addFile = function($file, response, $flow) {
            if (response !== '' && $file.error !== true) {
                var tempFile = JSON.parse(response);
                $file.isUploading = false;
                $file.isUploaded = true;
                $scope.disabled.ok = false;
                $scope.attachment.tempFile = tempFile.data;
                $scope.isFileError = false;
                $scope.errorMessages = [];
            }
        };

        $scope.submitFile = function($flow, $files) {
            // test if the file name has been provided else add one off the file name
            $scope.attachment.name = $scope.attachment.name || $files[0].name.replace(/\.[\w\d]*$/, '');
            $flow.upload();
            $scope.disabled.ok = true;
            $scope.isFileError = false;
            $scope.errorMessages = [];
        };

        saveAttachment();
    }
})();
