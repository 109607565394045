(function () {
    'use strict';

    angular.module('lucidity').factory('ActionCommentService', ActionCommentService);

    ActionCommentService.$inject = ['Restangular'];

    function ActionCommentService(Restangular) {
        return {
            loadComments: loadComments,
            addComment: addComment,
            editComment: editComment,
            deleteComment: deleteComment,
        };

        function loadComments(actionUuid) {
            return Restangular.one('actions', actionUuid).one('actioncomments').getList();
        }

        function addComment(actionUuid, actionComment) {
            return Restangular.one('actions', actionUuid).post('comments', actionComment);
        }

        function editComment(actionUuid, actionComment) {
            return Restangular.one('actions', actionUuid).one('comments', actionComment.uuidString).customPUT(actionComment);
        }

        function deleteComment(actionUuid, actionComment) {
            return Restangular.one('actions', actionUuid).one('comments', actionComment.uuidString).remove();
        }
    }
})();
