(function () {
    'use strict';
    angular.module('lucidity').directive('inlineList', inlineList);

    function inlineList() {
        var directive = {};
        directive.restrict = 'E';
        directive.templateUrl = '/app/element/inline-list/inline-list.html';
        directive.scope = {
            modalTemplate: '=',
            modalController: '=',
            formIdentifier: '@',
            parentId: '@',
            parentUuid: '@',
        };
        return directive;
    }
})();
