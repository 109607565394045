(function () {
  'use strict';
  angular.module('lucidity').factory('ModelTransactionQueueService', [
    '_',
    'uuid',
    function (_, uuid) {
      /* jshint -W117 */
      var service = {};
      service.queue = [];

      service.createModelTransaction = function (model, value) {
        return new ModelTransaction(uuid.v4(), model, value);
      };

      service.addToQueue = function (modelTransaction) {
        if (this.queue.length > 0) {
          this.queue = _.filter(this.queue, function (queueItem) {
            return !modelTransaction.matches(queueItem);
          });
        }

        if (modelTransaction !== undefined) {
          this.queue.push(modelTransaction);
        }
      };

      service.setQueue = function (newQueue) {
        this.queue = newQueue;
      };

      service.getQueue = function () {
        return this.queue;
      };

      service.flushQueue = function () {
        var queue = this.queue;
        this.queue = [];
        return queue;
      };

      return service;
    },
  ]);
})();
