(function () {
  'use strict';
  angular.module('lucidity').directive('ngAction', [
    '$window',
    '$templateCache',
    '$interpolate',
    function ($window, $templateCache, $interpolate) {
      var directive = {};

      directive.templateUrl = function (elem, attr) {
        if (attr.template) {
          return '/app/ui/action/' + attr.template + '.html';
        } else {
          return '/app/ui/action/action.html';
        }
      };

      directive.link = function (scope, elem, attrs) {
        var action = scope[attrs.ngAction] || null;
        var contextKey = attrs.ngActionContext || null;
        var context = null;

        action.condition = action.condition || function () {
          return true;
        };

        scope.$watch(function () {
          return scope.$parent[contextKey] || null;
        }, function (newContext, oldContext) {

          context = newContext;
          var hashKey = action.$$hashKey;
          angular.forEach(action, function (value, key) {
            if (angular.isString(value) && !key.match(/^\$/)) {
              var cacheKey = hashKey + key;
              if ($templateCache.get(cacheKey) === undefined) {
                $templateCache.put(cacheKey, $interpolate(value));
              }

              scope[attrs.ngAction][key] = $templateCache.get(cacheKey)(context);
            }
          });

          action.display = action.condition(context);
        });

        elem.find('button').bind('click', function () {
          if (action !== null) {
            scope.$apply(function () {
              if (angular.isFunction(action.action)) {
                action.action(scope.$parent, context);
              } else {
                scope[action.action](context);
              }
            });
          }
        });
      };

      return directive;
    },
  ]);
})();
