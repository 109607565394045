(function () {
    'use strict';
    //Not supported within Integral INTEGRALCS-5100
    angular.module('lucidity').factory('PermissionService', PermissionService);

    PermissionService.$inject = ['userPermissions'];

    function PermissionService(userPermissions) {
        return {
            hasPermission: hasPermission,
        };

        function hasPermission(permissionKey) {
            return userPermissions[permissionKey] !== undefined;
        }
    }
})();
