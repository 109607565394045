(function () {
  'use strict';

  angular.module('lucidity').factory('hashService', hashService);

  hashService.$inject = [];

  function hashService() {
    return {
      objectHashCode: objectHashCode,
      stringHashCode: stringHashCode,
    };

    function objectHashCode(object) {
      var jsonObject = JSON.stringify(object);
      return stringHashCode(jsonObject);
    }

    function stringHashCode(str) {
      /* jshint -W016 */
      var hash = 0;
      var i;
      var chr;
      var len;
      if (str.length === 0) {
        return hash;
      }

      for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }

      return hash;
    }

  }
})();
