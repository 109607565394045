(function () {
  'use strict';
  angular.module('lucidity').directive('ngData', [
    '_',
    'ValueParseService',
    function (_, ValueParseService) {
      var directive = {};
      directive.priority = 100;

      //Prevent interpolation of attributes;s
      directive.compile = function (element, attributes) {
        return function (scope, elem, attrs) {
          //Preference data init over other data options
          var attrsKeys = attrs.$attr;
          var keyPrefix = attrs.ngData || 'data';
          var initKey = keyPrefix + '-init';
          if (attrsKeys.init !== undefined && attrsKeys.init === initKey) {
            ValueParseService.injectAttribute(scope, 'init', attrs.init);
            delete attrsKeys.init;
          }

          _(attrsKeys).forEach(function (attrName, attrKey) {
            if (ValueParseService.isPrefixedKey(keyPrefix, attrKey)) {
              var scopeKey = ValueParseService.removePrefix(keyPrefix, attrKey);
              ValueParseService.injectAttribute(scope, scopeKey, attrs[attrKey]);
            }
          });
        };
      };

      return directive;
    },
  ]);
})();
