(function () {
    'use strict';

    angular.module('lucidity').factory('EnabledFeaturesService', EnabledFeaturesService);

    EnabledFeaturesService.$inject = ['enabledFeatures'];

    function EnabledFeaturesService(enabledFeatures) {
        return {
            hasFeature: hasFeature,
        };

        function hasFeature(feature) {
            return enabledFeatures[feature] !== undefined;
        }
    }
})();
