(function () {
  'use strict';
  angular.module('lucidity').directive('ngStopEvent', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        element.bind(attr.ngStopEvent, function (e) {
          e.stopPropagation();
        });
      },
    };
  });
})();
