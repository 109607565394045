(function() {
  'use strict';

  angular.module('lucidity').directive('ngAutocompleteGeolocation', [
    '_',
    function(_) {
      var directive = {};
      var addressComponents = {
        locality: 'locality',
        administrative_area_level_1: 'administrativearealevel1',
        country: 'country',
      };

      directive.restrict = 'A';
      directive.require = '?ngModel';
      directive.link = function(scope, element, attrs, ngModel) {
        var options = {
          types: ['(cities)'],
          componentRestrictions: {},
        };

        var requiredAddressComponents = {};

        _.each(addressComponents, function(addressComponent, placeComponent) {
          if (attrs[addressComponent] !== undefined) {
            requiredAddressComponents[placeComponent] = attrs[addressComponent];
          }
        });

        var gPlace = new google.maps.places.Autocomplete(element[0], options);
        google.maps.event.addListener(gPlace, 'place_changed', placeChanged);
        function placeChanged() {
          scope.$apply(function() {
            var currentPlace = gPlace.getPlace();
            var currentAddressComponents = currentPlace['address_components'];

            if (ngModel) {
              ngModel.$setViewValue(currentPlace);
            }

            _.each(requiredAddressComponents, function(scopeVariable, addressComponent) {
              var requiredAddressComponent = null;
              _.each(currentAddressComponents, function(currentAddressComponent) {
                if (currentAddressComponent.types.indexOf(addressComponent) !== -1) {
                  requiredAddressComponent = currentAddressComponent.long_name;
                }

                _.setNested(scope, scopeVariable, requiredAddressComponent);
              });
            });
          });
        }
      };

      return directive;
    },
  ]);

})();
