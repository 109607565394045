(function () {
    'use strict';
    angular
        .module('lucidity')
        .controller('wizardController', [
        '$scope',
        '$http',
        '$modal',
        '$location',
        '$interval',
        'anchorSmoothScroll',
        '$localStorage',
        function($scope, $http, $modal, $location, $interval, anchorSmoothScroll, $localStorage) {

            $scope.base_url = $location.$$protocol + '://' + $location.$$host;
            $scope.parent = {};
            $scope.parent.trigger_id = null;
            $scope.conditionTemplate = "/app/notification/filter/default.html";

            $scope.wizard = {
                collapseTwo: false,
                collapseThree: false,
                collapseFour: false,
                collapseFive: false
            }

            $scope.requiredField = {
                    template: {
                        status: false,
                        errorMessage: 'Subject/Message Body is required',
                        hashId: 'customizeTemplate',
                        heading: 'headingCustomizeTemplate',
                    },
                    filter: {
                        status: false,
                        errorMessage: 'Conditions is invalid',
                        hashId: 'chooseCondition',
                        heading: 'headingChooseCondition'
                    },
                    recipient: {
                        status: false,
                        errorMessage: 'Recipient is required',
                        hashId: 'addRecipient',
                        heading: 'headingAddRecipient'
                    }
                };

            $scope.setEmptyVariable = function () {

                $scope.parent.filter = {
                    expression: "",
                };
                $scope.parent.template = {};
                $scope.parent.template.body = "";
                $scope.parent.template.subject = "";
                $scope.parent.recipient = [];
                $scope.parent.review = {};
                $scope.parent.review.template_id = null;
                $scope.parent.saveFlag = false;
                $scope.parent.saveStatus = '';
                $scope.parent.validationMessage = '';
            }

            $scope.setEmptyVariable();

            $scope.validateFields = function () {
                var openAccordion = [];
                var openHeader = [];
                angular.forEach($scope.requiredField, function(value, key) {
                    if($scope.requiredField[key]['status'] === false){
                        openAccordion.push($scope.requiredField[key]['hashId']);
                        openHeader.push($scope.requiredField[key]['heading']);
                    }
                });

                if(openAccordion.length > 0){
                    $scope.openAccordion(openAccordion[0],openHeader[0]);
                    return false;
                }
                return true;
            }

            $scope.$on('eventCheckRequiredField', function (events,args) {
                $scope.requiredField[args[0]][args[1]] = args[2];
            })

            $scope.$on('disableStepper', function (events,args) {
                $scope.wizard.collapseThree = false;
                $scope.wizard.collapseFour = false;
                $scope.wizard.collapseFive = false;
            })

            //send Event Trigger
            $scope.$on('eventTriggerController', function (events, args) {
                if($scope.parent.trigger_id != null && args.template.trigger_id !== $scope.parent.trigger_id) {
                    $scope.openModal(args);
                } else {
                    $scope.parent.triggers = args.master.triggers;
                    $scope.assignNewTriggerId(args);
                }
            });

            $scope.assignNewTriggerId = function (args){
                $scope.parent.trigger_id  = args.template.trigger_id;
                $scope.$broadcast('eventSendTriggerToConditions', $scope.parent);
                $scope.openAccordion("chooseCondition","headingChooseCondition");
                $scope.wizard.collapseTwo = true;
            }

            $scope.changeTrigger = function (args) {
                $scope.$broadcast('eventTriggerHasChanged', true);
                $scope.setEmptyVariable();
                $scope.setAllStepsTofalse();
                $scope.assignNewTriggerId(args);
            }

            $scope.setAllStepsTofalse = function () {
                angular.forEach($scope.wizard, function(value, key) {
                    $scope.wizard[key] = false;
                });
            }

            $scope.softDeleteTemplate = function () {
                var id = $scope.parent.review.template_id;
                var saveTemplateUrl = $scope.base_url+'/intranet/api/v1/notification/delete-template/'+ id;

                $http({
                    method  : 'POST',
                    url     :  saveTemplateUrl,
                    data: { _method: 'delete'}
                })
                .success(function(response) {

                }).error(function(response) {

                });
            }

            $scope.openModal = function (args) {

                var controllerOptions = {
                    title: 'Message details',
                    templateUrl: '/app/notification/confirm-change-event.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$modalInstance', function ($scope, $modalInstance) {
                            $scope.confirm = function () {
                                $modalInstance.close(args.template);
                            };
                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }
                    ],
                    resolve: {

                    }
                };

                var modalInstance = $modal.open(controllerOptions);

                modalInstance.result.then(function (args) {
                    $scope.changeTrigger(args);
                });

                return false;
            };

            //send Template/Placeholder
            $scope.$on('eventPlaceholderController', function (events, args) {
                $scope.parent.template  = args;
                $scope.openAccordion("reviewAndFinalize", "headingReviewAndFinalize");

                $scope.$broadcast('eventSendTriggerToReviewAndFinalize', $scope.parent);
                $scope.wizard.collapseFive = true;
            });

            //send Conditions
            $scope.$on('eventConditionsController', function (events, args) {

                $scope.parent.filter  = args;
                $scope.$broadcast('eventSendTriggerToAddRecipients', $scope.parent);
                $scope.openAccordion("addRecipient", "headingAddRecipient");
                $scope.wizard.collapseThree = true;
            });

            //send Conditions
            $scope.$on('eventChangeConditions', function (events, args) {
                $scope.parent.filter  = args;
            });

            //send Recipients
            $scope.$on('eventAddRecipientController', function (events, args) {
                $scope.parent.recipient  = args;
                $scope.$broadcast('eventSendTriggerToPlaceholder', $scope.parent);
                $scope.openAccordion("customizeTemplate", "headingCustomizeTemplate");
                $scope.wizard.collapseFour = true;
            });

            //send Review and Finalize
            $scope.$on('eventReviewAndFinalizeController', function (events, args) {
                $scope.parent.review  = args;
                $scope.saveNotification();
            });

            $scope.saveNotification = function () {
                $scope.parent.saveStatus = 'saving';
                $scope.parent.validationMessage = 'Saving the notification';
                $scope.parent.saveFlag = true;
                var saveTemplateUrl = $scope.base_url+'/intranet/api/v1/notification/save-template';
                var templateData = { template: $scope.parent };

                if($scope.parent.review.isTested === true && $scope.parent.review.template_id != null) {
                    saveTemplateUrl = $scope.base_url+'/intranet/api/v1/notification/save-template/' + $scope.parent.review.template_id;
                    templateData = $scope.parent.review;
                }

                if($scope.validateFields()) {

                    $http({
                        method  : 'POST',
                        url     : saveTemplateUrl,
                        data    :  templateData,
                        dataType: 'json',
                    })
                        .success(function(response) {
                            $scope.parent.saveStatus = 'saved';
                            $scope.parent.validationMessage = 'Successfully created the template! You will be redirected to the Notifications landing page in a few seconds.';
                            $interval(function() {
                                $localStorage.created = 1;
                                location.replace($scope.base_url + '/system/notificationtemplate/templatelist');

                            }, 3000);
                        }).error(function(response) {

                    });
                } else {
                    $scope.parent.saveStatus = 'FieldRequired';
                    $scope.parent.validationMessage = '';
                }
            }

            //assign template ID
            $scope.$on('eventSendTemplateIdToParent', function (events, args) {
                $scope.parent.review.template_id  = args;
            });

            $scope.cancelNotification = function () {
                if($scope.parent.review.template_id){
                    $scope.softDeleteTemplate();
                }
                $scope.parent.trigger_id = null;
                $scope.setEmptyVariable();
                $scope.setAllStepsTofalse();
                $scope.$broadcast('eventTriggerHasChanged', true);
                $scope.openAccordion("chooseNotification","headingChooseNotification");
            }


            $scope.$on('eventCancelNotification', function (events,args) {

                if(args){
                    $scope.parent.review.template_id = args;
                }

                var controllerOptions = {
                    title: 'Message details',
                    templateUrl: '/app/notification/confirm-cancel-notification.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$modalInstance', function ($scope, $modalInstance) {
                            $scope.confirm = function () {
                                $modalInstance.close();
                            };
                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }
                    ],
                };

                var modalInstance = $modal.open(controllerOptions);

                modalInstance.result.then(function () {
                    $scope.cancelNotification();
                });

            })

            $scope.openAccordion = function (anchor,divId) {
                var id = $('#accordion .in').attr("id");
                if(id !== anchor){
                    $(".collapse").collapse('hide');
                    $('#' + anchor).collapse('show');
                }


                $('#' + anchor).on('shown.bs.collapse', function () {
                    $scope.gotoRequiredField(divId)
                })
            }

            $scope.gotoRequiredField = function(divId) {

                // call $anchorScroll()
                anchorSmoothScroll.scrollTo(divId);
            }

        }
    ]);
})();
