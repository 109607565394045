(function () {
  'use strict';
  angular.module('lucidity').factory('EventService', [
    '$rootScope',
    '_',
    function ($rootScope, _) {

      var service = {
        data: {},
        event: null,
        eventId: null,
      };

      service.broadcast = function () {
        var args = _.toArray(arguments);
        var event = args[0];
        var eventId;
        var data;
        if (args.length === 2) {
          eventId = null;
          data = args[1];
        } else if (args.length === 3) {
          eventId = args[1];
          data = args[2];
        }

        //Store most recent event
        service.event = event;
        service.data = data;
        service.eventId = eventId;
        data.__eventId = eventId;
        $rootScope.$broadcast(event, data);
      };

      service.on = function () {
        var args = _.toArray(arguments);
        var event = args[0];
        var listenerEventId;
        var fn;
        if (args.length === 2) {
          listenerEventId = null;
          fn = args[1];
        } else if (args.length === 3) {
          listenerEventId = args[1];
          fn = args[2];
        }

        var wrappedFn = function (event, data) {
          if (listenerEventId === null || areEventIdsEqual(listenerEventId, data.__eventId)) {
            return fn(event, data);
          }
        };

        $rootScope.$on(event, wrappedFn);
      };

      //Related to INTEGRALCS-3552
      function areEventIdsEqual(id1, id2) {
        id1 = sanitiseEventId(id1);
        id2 = sanitiseEventId(id2);
        return id1 === id2;
      }

      function sanitiseEventId(id) {
        return (id + '').replace(/-/g, '_');
      }

      return service;
    },
  ]);
})();
