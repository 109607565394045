(function () {
  'use strict';
  window.ModelTransaction = ModelTransaction;

  function ModelTransaction(uuid, id, value) {
    this.uuid = uuid;
    this.id = id;
    this.value = value;
  }

  ModelTransaction.prototype.getValue = function () {
    return this.value;
  };

  ModelTransaction.prototype.getId = function () {
    return this.id;
  };

  ModelTransaction.prototype.matches = function (modelTransaction) {
    return this.id === modelTransaction.getId();
  };

  ModelTransaction.prototype.getUuid = function () {
    return this.uuid;
  };

})();
