(function () {
  'use strict';
  angular.module('lucidity').factory('CommandService', [
      '$rootScope',
        function ($rootScope) {
          var service = {};
          service.commands = {};

          service.registerCommand = function (name, command) {
            service.commands[name] = command;
          };

          service.getCommand = function (name) {
            if (service.commands[name] !== undefined) {
              return service.commands[name];
            }

            return function () {
                };
          };

          service.fireCommand = function (name, args) {
            return service.getCommand(name)(args);
          };

          return service;
        },
    ]);
})();
