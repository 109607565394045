(function () {
  'use strict';
  angular.module('lucidity').directive('ngActionTrigger', [
        function () {
          var directive = {};
          directive.scope = {};
          directive.restrict = 'A';
          directive.controller = ['$scope', function ($scope) {
            var action;
            $scope.executeAction = function () {
              action();
            };

            this.addPrimaryAction = function (primaryAction) {
              action = primaryAction;
            };
          },];

          directive.link = function (scope, elem, attrs) {
            elem.addClass('clickable');
            elem.bind('click', function () {
              scope.executeAction();
            });
          };

          return directive;
        },
    ]);
})();
