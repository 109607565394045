(function () {
  'use strict';
  angular.module('lucidity').directive('ngDatepickerRepeat', [
    '_',
    'ValueParseService',
    function (_, ValueParseService) {

      var directive = {};
      directive.replace = true;
      directive.restrict = 'AE';
      directive.scope = {
        name: '@',
        date: '=?',
        dateFromString: '@?',
        repeat: '=?',
        repeatFromString: '@?',
        interval: '=?',
        intervalFromString: '@?',
        on: '=?',
        onFromString: '@?',
        class: '@',
        repeatDate: '=?ngModel',
      };
      directive.templateUrl = '/app/element/datepicker-repeat/datepicker-repeat.html';
      directive.controller = 'DatepickerRepeatController';
      directive.link = function (scope, elem, attrs) {
        scope.dateFromString = scope.dateFromString || null;
        if (scope.dateFromString !== null) {
          scope.dateFromString = new Date(scope.dateFromString);
        }

        scope.repeatDate.date = scope.date || scope.dateFromString;
        var repeat = scope.repeat || scope.repeatFromString;
        var interval = scope.interval || parseInt(scope.intervalFromString, 10) || scope.intervalFromString;
        var on = scope.on || ValueParseService.jsonParse(scope.onFromString, null);

        scope.setValue(repeat, interval, on);

        elem.find('.focusser').on('focus', function ($event) {
          scope.$apply(function () {
            scope.focusDateInput();
          });
        });

      };

      return directive;
    },
  ]);
})();
