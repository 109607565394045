(function () {
    'use strict';
    angular.module('lucidity').directive('ngCkeditorTemp', [
        '$window',
        'CKEDITOR',
        'ckeditorConfig',
        '$compile',
        '$location',
        '$route',
        'EventService',
        function ($window, CKEDITOR, ckeditorConfig, $compile, $location, $route, EventService) {
            var directive = {};
            directive.require = 'ngModel';
            directive.link = function ($scope, elem, attrs, ngModel) {
                var editor = CKEDITOR.replace(elem[0], ckeditorConfig);
                if (attrs.ngCkeditor) {
                    CKEDITOR.config.bodyClass = attrs.ngCkeditor;
                }

                editor.$scope = $scope;
                editor.$compile = $compile;
                editor.$route = $route;
                editor.$location = $location;
                editor.EventService = EventService;

                //Remove that cockersucker reset from the dialog
                CKEDITOR.on('dialogDefinition', function (event) {
                    var dialog = event.data.definition.dialog;

                    dialog.on('show', function (event) {

                        angular.module('lucidity').constant('ckeditorDialog', event.sender.parts.dialog.$);
                        var dialogContainer = angular.element(event.sender.parts.dialog.$)
                            .parent();

                        dialogContainer.removeClass('cke_reset_all');
                        if (dialog.definition.id !== undefined && dialog.definition.id === 'luciditywidget') {
                            dialogContainer.addClass('ckeditor-lucidity-widget');
                        }

                    });
                });

                editor.on('instanceReady', function (evt) {
                    evt.editor.on('blur', function () {
                        if(!$scope.$$phase) {
                            // ngModel.$setViewValue(this.getData());
                            $scope.$apply(readViewText);
                        }
                    });
                    evt.editor.on('change', function () {
                        if(!$scope.$$phase) {
                            //ngModel.$setViewValue(this.getData());
                            $scope.$apply(readViewText);
                        }
                    });

                });

                function readViewText() {
                    //console.log('change now', editor.getData());
                    ngModel.$setViewValue(editor.getData());
                }

                ngModel.$render = function(value){
                    editor.setData(ngModel.$viewValue);
                }
            };

            return directive;
        },
    ]);
})();

