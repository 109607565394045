(function () {
    'use strict';
    angular
        .module('lucidity')
        .controller('ManagementSystemSettingsController', [
            '$scope',
            '$http',
            '$modal',
            'ModalService',
            '$location',
            'MessageService',
            function($scope, $http, $modal, ModalService, $location, MessageService) {

                $scope.base_url = $location.$$protocol + '://' + $location.$$host;
                $scope.managementSystems = {}
                $scope.action = '';

                $scope.addManagementSystem = function () {
                    $scope.action = 'add';
                    $scope.openModal();

                }

                $scope.models = {
                    selected: null,
                    lists: { managementSystems: []}
                };

                $scope.fetch = function () {
                    $http({
                        method  : 'GET',
                        url     :  $scope.base_url+'/intranet/api/v1/managementsystem',
                        dataType: 'json'
                    })
                        .success(function(response) {
                            $scope.managementSystems = response.data
                            $scope.models.lists.managementSystems = response.data

                        }).error(function(response) {

                    });
                }

                $scope.fetch();

                $scope.editManagementSystem = function (id) {
                    $scope.action = 'edit';

                    $scope.selectedManagementSystem = $scope.managementSystems.filter(function(item) {
                        return item.id === id;
                    })[0];
                    $scope.openModal();
                }

                $scope.openModal = function () {

                    var controllerOptions = {
                        title: 'Message details',
                        templateUrl: '/app/management-system/management-system-settings.html',
                        scope: $scope,
                        controller: [
                            '$scope', '$modalInstance', 'action', 'managementSystem',
                            function ($scope, $modalInstance, action, managementSystem) {

                                $scope.title = "Add Management System";
                                $scope.managementSystemName = "";
                                $scope.managementSystemDescription = "";

                                if(action === 'edit'){
                                    $scope.title = "Edit " + managementSystem.name;
                                    $scope.managementSystemName = managementSystem.name;
                                }

                                $scope.confirm = function () {
                                    $modalInstance.close($scope.managementSystemName, $scope.managementSystemDescription);
                                };

                                $scope.cancel = function () {
                                    $modalInstance.dismiss('cancel');
                                };
                            }
                        ],
                        resolve: {
                            action: function () {
                                return $scope.action;
                            },
                            managementSystem: function () {
                                return $scope.selectedManagementSystem;
                            }
                        }
                    };

                    var modalInstance = $modal.open(controllerOptions);

                    modalInstance.result.then(function (managementSystemName, managementSystemDescription) {
                        if ($scope.action === "add") {
                            $scope.createManagementSystem(managementSystemName, managementSystemDescription);
                        }

                        if ($scope.action === 'edit') {
                            $scope.selectedManagementSystem.name = managementSystemName;
                            $scope.updateManagementSystem($scope.selectedManagementSystem);
                        }
                    });

                    return false;
                };

                $scope.updateManagementSystem = function(managementSystem) {

                    $http.put($scope.base_url+'/intranet/api/v1/managementsystem/' + managementSystem.id, managementSystem)
                        .then(function success(srv_resp) {
                                MessageService.success(srv_resp.data.data['name'] + ' has been updated!', '');
                            }, function failed(srv_resp) {
                                MessageService.error(srv_resp.data.messages.name[0], '');
                            }
                        );
                }

                $scope.createManagementSystem = function (managementSystemName, managementSystemDescription) {

                    var managementSystem = {
                        name: managementSystemName,
                        description: managementSystemDescription
                    };

                    $http.post($scope.base_url+'/intranet/api/v1/managementsystem', managementSystem )
                        .then(function success(srv_resp) {
                                $scope.managementSystems.push(srv_resp.data.data)
                                MessageService.success(srv_resp.data.data['name'] + ' has been added successfully!', '');
                            }, function failed(srv_resp) {
                                MessageService.error(srv_resp.data.messages.name[0], '');
                            }
                        );
                }


                $scope.deleteAndRestore = function (index, id) {
                    $scope.index = index;

                    $scope.selectedManagementSystem = $scope.managementSystems.filter(function(item) {
                        return item.id === id;
                    })[0];

                    var controllerOptions = {
                        title: 'Message details',
                        templateUrl: '/app/management-system/management-system-settings-delete-restore.html',
                        scope: $scope,
                        controller: [
                            '$scope', '$modalInstance', 'managementSystem',
                            function ($scope, $modalInstance, managementSystem) {

                                $scope.title = "Disable " + managementSystem.name;
                                $scope.description = "Are you sure you want to disable this?";
                                $scope.action = "Disable";


                                if(!managementSystem.isActive){
                                    $scope.title = "Enable " + managementSystem.name;
                                    $scope.description = "Are you sure you want to enable this?"
                                    $scope.action = "Enable";
                                }

                                $scope.confirm = function () {
                                    $modalInstance.close();
                                };

                                $scope.cancel = function () {
                                    $scope.managementSystems[$scope.index].isActive = !managementSystem.isActive
                                    $modalInstance.dismiss('cancel');
                                };
                            }
                        ],
                        resolve: {
                            managementSystem: function () {
                                return $scope.selectedManagementSystem;
                            }
                        }
                    };

                    var modalInstance = $modal.open(controllerOptions);

                    modalInstance.result.then(function () {

                        if ($scope.selectedManagementSystem.isActive) {
                            $scope.restoreManagementSystem();
                        } else {
                            $scope.deleteManagementSystem();
                        }
                    });

                    return false;
                }


                $scope.reorderManagementSystem = function(index) {
                    $scope.managementSystems.splice(index, 1);
                    var managementSystemOrder = $scope.managementSystems.map(function(managementSystem, order) {
                        return {id: managementSystem.id, order: order};
                    });

                    $http.put('/intranet/api/v1/managementsystem/order', managementSystemOrder)
                        .then(function success(srv_resp) {
                                MessageService.success('Reordered!', '');
                            }, function failed(srv_resp) {
                                MessageService.error('Failed to reorder!', '');
                            }
                        );
                }

                $scope.restoreManagementSystem = function () { //NOSONAR [INTEGRALCS-7911]
                    $http.put($scope.base_url+'/intranet/api/v1/managementsystem/' + $scope.selectedManagementSystem.id + '/restore')
                        .then(function success(srv_resp) {
                                $scope.managementSystems[$scope.index] = srv_resp.data.data;
                                MessageService.success(srv_resp.data.data['name'] + ' has been enabled!', '');
                            }, function failed(srv_resp, a) {
                                $scope.managementSystems[$scope.index].isActive = !$scope.managementSystems[$scope.index].isActive;
                                if (srv_resp.status === 401) {
                                    MessageService.error("You are not authorised to configure management systems.", '');
                                    return;
                                }
                                MessageService.error(srv_resp.data.messages.name[0], '');
                            }
                        );
                }

                $scope.deleteManagementSystem = function () { //NOSONAR [INTEGRALCS-7911]
                    $http.delete($scope.base_url+'/intranet/api/v1/managementsystem/' + $scope.selectedManagementSystem.id)
                        .then(function success(srv_resp) {
                                $scope.managementSystems[$scope.index] = srv_resp.data.data;
                                MessageService.success(srv_resp.data.data['name'] + ' has been disabled!', '');
                            }, function failed(srv_resp) { //NOSONAR [INTEGRALCS-7911]
                                $scope.managementSystems[$scope.index].isActive = !$scope.managementSystems[$scope.index].isActive;
                                if (srv_resp.status === 401) {
                                    MessageService.error("You are not authorised to configure management systems.", '');
                                    return;
                                }
                                MessageService.error(srv_resp.data.messages.name[0], '');
                            }
                        );
                };
            }
        ]);
})();
