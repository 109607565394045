(function () {
  'use strict';
  angular.module('lucidity').controller('ListFilterController', ListFilterController);

  ListFilterController.$inject = ['$sessionStorage', '$window', '_'];

  function ListFilterController($sessionStorage, $window, _) {
    var vm = this;

    var key = $window.location.pathname.replace(/\//g, '_') + '_filtersisCollapsed';

    vm.$storage = $sessionStorage;

    vm.isCollapsed = _.nested(vm.$storage, key, true);
    vm.toggle = toggle;

    function toggle() {
      vm.$storage[key] = vm.isCollapsed = !vm.isCollapsed;
    }

  }
})();
