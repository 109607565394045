(function () {
  'use strict';

  angular.module('lucidity').controller('NewsWidgetController', NewsWidgetController);

  NewsWidgetController.$inject = ['NewsWidgetService'];

  function NewsWidgetController(NewsWidgetService) {
    var vm = this;
    vm.pages = {};
    vm.processing = { loading: false };
    // items per page
    vm.pageSize = 2;
    // number of pages displayed in pagination
    vm.maxSize = 5;
    // current page position (starts at one)
    vm.currentPage = 1;
    // total number of items
    vm.totalItems = 0;

    vm.pageChanged = pageChanged;

    vm.showPagination = showPagination;

    init();

    function init() {
      vm.processing.loading = true;
      NewsWidgetService.countTotalNewsItems().then(function (response) {
          var data = response.data.plain();
          vm.totalItems = data.totalItems;
          pageChanged();
        });
    }

    function pageChanged() {
      vm.processing.loading = true;
      NewsWidgetService.getPages(vm.currentPage, vm.pageSize).then(function (response) {
          vm.pages = response.data.plain();
          vm.processing.loading = false;
        });
    }

    function showPagination() {
      return vm.totalItems > vm.pageSize;
    }

  }

})();
