(function () {
  'use strict';
  angular.module('lucidity').directive('ngAutoSave', [
    '$interval',
    'AutoSaveService',
    'FormService',
    function ($interval, AutoSaveService, FormService) {
      var directive = {};

      directive.restrict = 'A';
      directive.require = '';
      directive.link = function (scope, element, attrs) {
        var latestModel = null;
        var autoSaveModel = scope.$eval(attrs.autoSaveModel);
        var hasModel = !!autoSaveModel;
        var autoSaveFn = scope.$eval(attrs.autoSaveFn);
        var modelUpdatedFn = scope.$eval(attrs.autoSaveModelUpdatedFn);
        var autoSaveInterval = scope.$eval(attrs.autoSaveInterval) * 1;
        latestModel = angular.copy(autoSaveModel);
        var intervalPromise = null;

        AutoSaveService.setApiParam('autoSaveEndPoint', attrs.autoSaveEndPoint);

        intervalPromise = $interval(function () {
          autoSaveModel = scope.$eval(attrs.autoSaveModel);
          if (!hasModel || !angular.equals(latestModel, autoSaveModel)) {
            latestModel = angular.copy(autoSaveModel);
            for (var id in latestModel) {
              modelUpdatedFn(id, latestModel[id]);
            }

            autoSaveFn(FormService.getId(element.context));
          }
        }, autoSaveInterval);

        element.on('$destroy', function (event) {
          if (intervalPromise) {
            $interval.cancel(intervalPromise);
          }
        });
      };

      return directive;
    },
  ]);
})();
