(function () {
  'use strict';
  angular.module('lucidity').filter('ordinal', ['_', function (_) {

    function ordinalReplacement(digit) {
      return digit + (['st', 'nd', 'rd'][(digit + '').match(/1?\d\b/) - 1] || 'th');
    }

    return function (input) {
      if (_.isString(input) || _.isNumber(input)) {
        input += '';
        var digits = _.map(input.match(/\d+/g) || [], ordinalReplacement);
        return _.map(input.split(/\d+/) || [], function (part) {
          return part + (digits.shift() || '');
        }).join('');
      }

      return input;
    };
  },]);
})();
