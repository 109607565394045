(function () {
  'use strict';

  angular.module('lucidity').factory('NewsWidgetService', NewsWidgetService);

  NewsWidgetService.$inject = ['Restangular'];

  function NewsWidgetService(Restangular) {
    return {
        getPages: getPages,
        countTotalNewsItems: countTotalNewsItems,
      };

    function getPages(currentPage, pageSize) {
      return Restangular.all('news').getList({ currentPage: currentPage, pageSize: pageSize });
    }

    function countTotalNewsItems() {
      return Restangular.one('news').one('count').get();
    }
  }

})();



