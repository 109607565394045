(function () {
  'use strict';
  angular.module('lucidity').controller('DateRangePickerController', DateRangePickerController);

  DateRangePickerController.$inject = ['$scope', '_', '$document', '$rootScope', '$compile', 'moment'];

  function DateRangePickerController($scope, _, $document, $rootScope, $compile, moment) {
    var vm = this;
    var backdropDomEl;
    var backdropScope;

    vm.open = open;
    vm.close = close;
    vm.clear = clear;
    vm.createBackdrop = createBackdrop;
    vm.closeBackdrop = closeBackdrop;
    vm.isOpen = false;

    vm.model = {
        from: {
            date: null,
            type: '',
          },
        to: {
            date: null,
            type: '',
          },
      };
    $scope.dateRange = $scope.dateRange || {
            rangeDisplay: '',
            from: {
                date: null,
                display: '',
              },
            to: {
                date: null,
                display: '',
              },
          };

    $scope.$watch('dateRange',

        function (newValue, oldValue) {

            var from = (newValue.from.date !== null) ? moment(newValue.from.date) : null;
            var fromDisplay = (from) ? moment(from).format('DD/MM/YYYY') : '';
            var to = (newValue.to.date !== null) ? moment(newValue.to.date) : null;
            var toDisplay = (newValue.to.date !== null) ? moment(newValue.to.date).format('DD/MM/YYYY') : '';
            //At this stage we are not supporting relative dates, so default it to be absolute type.
            $scope.dateRange.rangeDisplay = fromDisplay + ((fromDisplay && toDisplay) ? ' - ' : '') + toDisplay;
            $scope.dateRange.from.display = fromDisplay;
            $scope.dateRange.to.display = toDisplay;

            vm.model.from.date = from ? from.format('YYYY-MM-DD') : null;
            vm.model.from.type = 'absolute';
            vm.model.to.date = to ? to.format('YYYY-MM-DD') : null;
            vm.model.to.type = 'absolute';
          },

        true
    );

    function open($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.isOpen = true;
      createBackdrop();
    }

    function close() {
      vm.isOpen = false;
      closeBackdrop();
    }

    function clear() {
      $scope.dateRange = {
          rangeDisplay: '',
          from: {
              date: null,
              display: '',
            },
          to: {
              date: null,
              display: '',
            },
        };

      vm.model = {
          from: {
              date: null,
              type: '',
            },
          to: {
              date: null,
              type: '',
            },
        };
    }

    function createBackdrop() {
      if (!backdropScope) {
        var body = $document.find('body').eq(0);
        backdropScope = $rootScope.$new(true);
        backdropScope.close = function () {
            if (backdropScope.isOpen) {
              backdropScope.isOpen = false;
              close();
            }

          };

        var angularBackgroundDomEl = angular.element('<div date-range-picker-backdrop></div>');
        backdropDomEl = $compile(angularBackgroundDomEl)(backdropScope);
        body.append(backdropDomEl);
      }

      backdropScope.isOpen = true;
    }

    function closeBackdrop() {
      if (backdropScope) {
        backdropScope.close();
      }
    }
  }
})();
