(function () {
  'use strict';
  angular.module('lucidity').filter('nested', ['_', function (_) {
    return function (input, property) {
      return _(input).values().map(
        function (value) {
          return _.nested(value, property);
        }
      ).value();
    };
  },]);
})();
