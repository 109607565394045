(function () {
  'use strict';
  angular.module('lucidity').directive('ngPrimaryAction', ['$window', '_', function ($window, _) {
    var directive = {};
    directive.restrict = 'A';
    directive.require = '^?ngActionTrigger';
    directive.link = function (scope, element, attrs, controller) {
      if (controller) {
        var primaryAction = function () {
        };

        if (_.isFunction(attrs.ngClick)) {
          primaryAction = attrs.ngClick;
        } else if (_.isString(attrs.href)) {
          primaryAction = function () {
            scope.$apply(function () {
              $window.location = attrs.href;
            });
          };
        }

        controller.addPrimaryAction(primaryAction);
      }
    };

    return directive;
  },]);
})();
