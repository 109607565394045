(function () {
    'use strict';

    angular
        .module("lucidity")
        .filter('startFrom', function() {

        return function(input, start) {
            if (input) {
                start = +start; //parse to int
                return input.slice(start);
            }
            return false;
        }
    });

    angular
        .module('lucidity')
        .controller('notificationListController', [
        '$scope',
        '$http',
        '$modal',
        '$location',
        '$localStorage',
        'MessageService',
        function($scope, $http, $modal,$location, $localStorage, MessageService) {

            $scope.base_url = $location.$$protocol + '://' + $location.$$host;
            $scope.notificationList = [{}];
            $scope.template = {};
            $scope.loading = 1;
            $scope.server_busy = 0;
            $scope.per_page = 5;
            $scope.total_items = 0;
            $scope.categoryList = [];

            $scope.newPage = $localStorage.current_page ? $localStorage.current_page : 1;

            if ($localStorage.edit_notification){
                var subject_name = $localStorage.edit_notification ? String($localStorage.edit_notification).replace(/(<[^>]+>)|(\:{3})|(\&[a-z]+\;)/gm, ' ') : '';
                MessageService.success('Template "'+ subject_name + '" updated successfully!', '');
                $localStorage.edit_notification = "";
            }

            $scope.setPage = function (){

                if($localStorage.created){
                    $scope.current_page  = Math.ceil($scope.total_items / $scope.per_page);
                    $localStorage.created = 0;
                }else {
                    if($scope.newPage && $scope.total_items !== 0){
                        $scope.current_page = $scope.newPage;
                    }else{
                        $scope.current_page = 1;
                    }
                }

            }

            $scope.$watch('current_page', function (newValue, oldValue, scope) {
                $localStorage.current_page = newValue;
            })

            $scope.search = {
                "event": "all",
                "isEnabled": "all",
                "subject": "",
            };

            $scope.getEvents = function() {
                $http.get($scope.base_url+'/intranet/api/v1/notification-triggers/search')
                    .then(function success(srv_resp) {
                            $scope.categoryList = cruse.helper.htmlEncodeObject(srv_resp.data.data);
                            console.log($scope.categoryList);
                        }, function failed(srv_resp) {
                            $scope.categoryList = [];
                        }
                    );
            }

            $scope.searchTriggers = function () {
                $scope.loading = 1;

                $http.post($scope.base_url+'/intranet/api/v1/notification-list/search', $scope.search)
                    .then(function success(srv_resp) {
                            $scope.notificationList = cruse.helper.htmlEncodeObject(srv_resp.data.data);
                            $scope.loading = 0;
                            $scope.total_items = $scope.notificationList.length;
                            $scope.setPage();

                            $scope.generateSubjectBubble($scope.notificationList);

                        }, function failed(srv_resp) {
                            $scope.notificationList = [];
                            $scope.total_items = $scope.notificationList.length;
                            $scope.loading = 0;
                        }
                    );
            }

            $scope.generateSubjectBubble = function(notifications) {

                $.each(notifications, function(idx, notification) {
                    var words = $scope.notificationList[idx].subject.trim().split(/({.*?})/);

                    $scope.notificationList[idx].subject = '';

                    $.each(words, function(i, v) {

                        if (v !== '') {
                            if (v.charAt(0) === '{') {
                                v = v.replace('{','').replace('}','')
                                $scope.notificationList[idx].subject += '<span class = "placeholder-bubble">'+ v + '</span>'
                            } else {
                                $scope.notificationList[idx].subject += v;
                            }
                        }
                    });
                });

            }

            $scope.checkIfTested = function (notification) {
                if(notification.isTested === 0 && notification.isEnabled === 0){
                    $scope.openModal(notification);
                } else if(notification.isTested === 0 && notification.isEnabled === false){
                    $scope.openModal(notification);
                } else {
                    notification.isEnabled = 1 - notification.isEnabled;
                    $scope.activateTemplate(notification);
                }
            }

            $scope.openModal = function (notification) {


                var controllerOptions = {
                    title: 'Warning',
                    templateUrl: '/app/notification/template-not-tested.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$filter', '$modalInstance','notification', function ($scope, $filter, $modalInstance,notification) {

                            $scope.ok = function () {

                                notification.isEnabled = 1 - notification.isEnabled;
                                $scope.activateTemplate(notification);
                                $modalInstance.close(notification);
                            };
                            $scope.cancel = function () {

                                $modalInstance.close(notification);

                            };
                        }
                    ],
                    resolve: {
                        notification: function () {
                          return notification;
                        }
                    }
                };

                var modalInstance = $modal.open(controllerOptions);

                modalInstance.result.then(function (notification) {

                    document.getElementById(notification.id).checked = false;

                });
            };

            $scope.activateTemplate = function (notification) {
                $http({
                  method  : 'POST',
                  url     :  $scope.base_url+'/intranet/api/v1/notification-list/' + notification.id,
                  data    :  notification,
                  dataType: 'json',
                })

                .success(function(response) {

                }).error(function(response){

                });
            }



            $scope.deleteConfirmation = function (notification) {
                var controllerOptions = {
                    title: 'Warning',
                    templateUrl: '/app/notification/confirm-delete.html',
                    scope: $scope,
                    controller: [
                        '$scope', '$filter', '$modalInstance','notification', function ($scope, $filter, $modalInstance,notification) {

                            $scope.ok = function () {

                                notification.deleted = 1;
                                $scope.deleteTemplate(notification);
                                $modalInstance.close(notification);

                            };
                            $scope.cancel = function () {

                                $modalInstance.close(notification);

                            };
                        }
                    ],
                    resolve: {
                        notification: function () {
                            return notification;
                        }
                    }
                };

                var modalInstance = $modal.open(controllerOptions);

                modalInstance.result.then(function (notification) {

                });
            }

            $scope.deleteTemplate = function (notification) {
                $http({
                    method  : 'DELETE',
                    url     :  $scope.base_url+'/intranet/api/v1/notification/delete-template/' + notification.id,
                    data    :  notification,
                    dataType: 'json',
                    // headers : {'Content-Type': 'application/x-www-form-urlencoded'}
                })

                    .success(function(response) {

                        $scope.searchTriggers()
                    }).error(function(response) {

                    });
            };

            $scope.getEvents();
            $scope.searchTriggers();

        }



    ]);
})();


