(function () {
  'use strict';
  angular.module('lucidity').directive('ngDateRangePicker', DateRangePicker);

  DateRangePicker.$inject = ['_'];

  function DateRangePicker(_) {
    var directive = {};
    directive.replace = true;
    directive.restrict = 'AE';
    directive.templateUrl = '/app/element/date-range-picker/date-range-picker.html';
    directive.scope = {
      name: '@',
      fromDate: '=?',
      fromDateString: '@?',
      toDate: '=?',
      toDateString: '@?',
      class: '@',
      dateRange: '=?ngModel',
    };
    directive.link = link;
    directive.controller = 'DateRangePickerController';
    directive.controllerAs = 'vm';
    return directive;

    function link(scope, elem, attrs) {

      var fromDate = scope.fromDate || scope.fromDateString ? new Date(scope.fromDateString) : null;
      if (fromDate) {
        scope.dateRange.from.date = fromDate;
      }

      var toDate = scope.toDate || scope.toDateString ? new Date(scope.toDateString) : null;
      if (toDate) {
        scope.dateRange.to.date = toDate;
      }

      elem.find('.focusser').on('focus', function ($event) {
        scope.$apply(function () {
          scope.focusDateInput();
        });
      });
    }
  }
})();
