(function () {
  'use strict';
  angular.module('lucidity').directive('ngStretch', [
      '$window',
      '$timeout',
        function ($window, $timeout) {
          return function (scope, element) {
            var w = angular.element($window);

            var updateScope = function () {
              scope.windowHeight = w.height();
              scope.windowWidth = w.width();
              scope.fillHeight = scope.windowHeight - element.offset().top;
              scope.fillWidth = scope.windowWidth - element.offset().left;
              scope.style = function () {
                return {
                  height: (scope.windowHeight - 100) + 'px',
                  width: (scope.windowWidth - 100) + 'px',
                };
              };

              $timeout(function () {
                if (!scope.$$phase) {
                  scope.$apply();
                }
              }, 100);
            };

            w.bind('resize', updateScope);
            w.bind('load', updateScope);
            updateScope();
          };
        },
    ]);
})();
