(function () {
    'use strict';
    angular.module('lucidity').directive('ngConfirmBeforeNavigating', [
        '$window',
        '$compile',
        '$modal',
        '$http',
        function ($window, $compile, $modal, $http) {
            var directive = {};

            directive.link = function ($scope, $element, attrs) {

                $scope.confirmationMessage = attrs.ngConfirmBeforeNavigating;
                $scope.href = attrs.href;

                $element.on('click', function(event) {

                    event.preventDefault();

                    $http({
                        method  : 'POST',
                        url     : $scope.href,
                        dataType: 'json',
                    })
                        .success(function(response) {

                            if (response.flag === false) {
                                $scope.confirmationMessage = response.message;
                            }
                            $scope.openModal(response);

                        }).error(function(response) {

                    });

                })

                $scope.openModal = function (response) {

                    var controllerOptions = {
                        title: 'Message details',
                        templateUrl: '/app/element/popover/confirm-before-navigating.html',
                        scope: $scope,
                        controller: [
                            '$scope', '$modalInstance', function ($scope, $modalInstance) {
                                $scope.confirm = function () {
                                    $modalInstance.close();
                                };
                                $scope.cancel = function () {
                                    $modalInstance.dismiss('cancel');
                                };
                            }
                        ]
                    };

                    var modalInstance = $modal.open(controllerOptions);

                    modalInstance.result.then(function () {

                        if(response.flag){
                            $window.location.href = $scope.href;
                        }

                    });

                    return false;
                };
            }
            return directive;
        }
    ]);
})();
