(function() {
    'use strict';

    angular.module('lucidity').directive('ngGeolocation', ['NgMap', '$timeout', '$modal', function(NgMap, $timeout, $modal) {
        var directive = {};
        directive.restrict = 'AE';
        directive.templateUrl = '/app/element/geolocation/geolocation.html';
        directive.require = ['?ngModel'];
        directive.scope = {
            buttonText: '@?',
            name: '@?',
            position: '=?',
            showMap: '@?',
            showCoordinate: '@?',
            readOnly: '@?'
        };
        directive.link = link;

        function link(scope, element, attrs, ngModel) {
            scope.fetchingLocation = false;
            scope.geoLocation = scope.position || {};
            scope.showInformation = false;
            scope.getGeoLocation = getGeoLocation;
            scope.clearLocation = clearLocation;
            scope.displayMap = displayMap;
            scope.displayCoordinate = displayCoordinate;
            scope.map = null;
            scope.disableEdit = disableEdit;

            NgMap.getMap().then(function(map) {
                scope.map = map;
            });

            function disableEdit() {
                return scope.readOnly;
            }

            function displayMap() {
                return scope.showMap && scope.geoLocation.longitude;
            }

            function displayCoordinate() {
                return scope.showCoordinate && scope.geoLocation.longitude;
            }

            function clearLocation() {
                if (window.confirm('Are you sure?')) {
                    scope.geoLocation = {};
                }
            }

            function getGeoLocation() {
                if (navigator.geolocation) {
                    scope.fetchingLocation = true;
                    navigator.geolocation.getCurrentPosition(getGeoLocationSuccess, getGeoLocationError);
                } else {
                    scope.showError = true;
                    scope.errorcode = 'Service not available';
                }
            }

            function getGeoLocationSuccess(position) {
                scope.$apply(function() {
                    scope.fetchingLocation = false;
                    scope.geoLocation = {
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude,
                        accuracy: position.coords.accuracy,
                    };

                    scope.showInformation = true;
                    scope.showError = false;
                    scope.errorcode = '';
                    scope.geoShowmap = true;
                    $timeout(function() {
                        google.maps.event.trigger(scope.map, 'resize');
                        scope.map.setCenter({lat: position.coords.latitude, lng: position.coords.longitude});
                    }, 250);
                });

            }

            function getGeoLocationError(error) {
                scope.$apply(function() {
                    scope.fetchingLocation = false;
                    scope.showInformation = false;
                    scope.showError = true;
                    var errorMessage;
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = 'User denied the request for Geolocation.';
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = 'Location information is unavailable.';
                            break;
                        case error.TIMEOUT:
                            errorMessage = 'The request to get user location timed out.';
                            break;
                        default:
                            errorMessage = 'Service not available';
                            break;
                    }

                    scope.errorcode = errorMessage;
                });
            }
        }

        return directive;

    },]);

})();