(function () {
    'use strict';
    angular.module('lucidity').directive('ngClipboard', [
        function () {
            var directive = {};
            directive.scope = {
                clipboardTooltip: '@',
                clipboardTooltipError: '@',
                clipboardText: '@',
            };
            directive.link = function (scope, element) {

                var options = {};
                if (scope.clipboardText) {
                    options.text = function () {
                        return scope.clipboardText;
                    };
                }

                var clipboard = new Clipboard(element[0], options);

                clipboard.on('success', function (e) {
                    e.clearSelection();
                    if (scope.clipboardTooltip !== '') {
                        element.tooltip({
                            title: scope.clipboardTooltip,
                            trigger: 'manual',
                            placement: 'bottom',
                        }).tooltip('show');
                        element.on('mouseleave', function () {
                            element.tooltip('hide');
                        })

                    }
                });

                clipboard.on('error', function () {
                    scope.$apply(function () {

                    });
                    if (scope.clipboardTooltipError === '') {
                        element.alert({
                            title: scope.clipboardTooltipError,
                            trigger: 'manual',
                            placement: 'bottom',
                        }).tooltip('show');
                        element.on('mouseleave', function () {
                            element.tooltip('hide');
                        })

                    }
                });
            };

            return directive;
        },
    ]);
})();
