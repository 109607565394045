(function() {
  'use strict';

  angular.module('lucidity').controller('ThemeController', ThemeController);

  ThemeController.$inject = ['$http', 'MessageService', 'Restangular', '$window'];

  function ThemeController($http, MessageService, Restangular, $window) {
    var vm = this;
    vm.updating = false;
    vm.primaryColour = '';
    vm.secondaryColour = '';
    vm.customCss = '';
    vm.update = update;

    init();

    function update() {
      vm.updating = true;
      $http({
        method: 'PUT',
        url: '/intranet/api/v1/theme',
        data: {
          primaryColour: vm.primaryColour,
          secondaryColour: vm.secondaryColour,
          customCss: vm.customCss,
        },
      }).then(function successCallback(response) {
        vm.updating = false;
        MessageService.success('Theme Updated!');
        $window.location.reload();
      }, function errorCallback(response) {
        vm.updating = false;
        MessageService.error('Error Updating Theme!');
      });
    }

    function init() {
      Restangular.setBaseUrl('/intranet/api/v1/');
      Restangular.one('theme').get().then(function(response) {
        vm.primaryColour = response.data.primaryColour;
        vm.secondaryColour = response.data.secondaryColour;
        vm.customCss = response.data.customCss;
      });
    }
  }

})();
