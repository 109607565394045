(function () {
  'use strict';
  angular.module('lucidity').directive('ngFocusOn', [
    '_',
    function (_) {
      var directive = {};
      directive.scope = { trigger: '=ngFocusOn' };
      directive.link = function (scope, element) {
        scope.$watch('trigger', function (value) {
          if (value === true) {
            element[0].focus();
          }
        });
      };

      return directive;
    },
  ]);
})();
