(function () {
  'use strict';
  angular.module('lucidity').directive('ngLowercase', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, controller) {
        function lowercase(value) {
          if (typeof value === 'string') {
            return value.toLowerCase();
          } else {
            return value;
          }

        }

        controller.$formatters.push(lowercase);
        controller.$parsers.push(lowercase);
      },
    };
  });
})();
