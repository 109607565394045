(function () {
  'use strict';
  angular.module('lucidity').controller('ManagementSystemPermissionController', ManagementSystemPermissionController);

  ManagementSystemPermissionController.$inject = ['$scope', 'managementSystemPermissionsService', '_', ];

  function ManagementSystemPermissionController($scope, managementSystemPermissionsService, _) {
    var vm = this;

    vm.managementSystems = null;
    vm.roles = [
        {
            label: 'Select Permissions', permissions: {
              can_view: false,
              can_edit: false,
              can_create: false,
              can_copy: false,
              can_delete: false,
              can_reorder: false,
            },
          }, {
            label: 'View', permissions: {
              can_view: true,
              can_edit: false,
              can_create: false,
              can_copy: false,
              can_delete: false,
              can_reorder: false,
            },
          }, {
            label: 'Edit', permissions: {
              can_view: true,
              can_edit: true,
              can_create: true,
              can_copy: true,
              can_delete: true,
              can_reorder: true,
            },
          },
    ];

    vm.init = init;
    vm.loadPermissions = loadPermissions;
    vm.bgColor = bgColor;
    vm.selectRole = selectRole;
    vm.select = select;
    vm.isSelected = isSelected;

    /**
     * type could be either User or User Role
     * targetId is id of User or User role
     * @param type
     * @param targetId
     */
    function init(type, targetId) {
      loadPermissions(type, targetId);
    }

    $scope.$watch('vm.managementSystems', function (managementSystems, oldPermission) {
        var updateFunction = window.parent.updateParentValue || function (key, value) {
            };

        var roles = _.map(managementSystems, function (managementSystem) {
            if (hasRole(managementSystem)) {
              return managementSystem.role.data;
            } else {
              return vm.roles[0];
            }
          });

        updateFunction('managementSystemPermissions', roles);
      }, true);

    function loadPermissions(type, targetId) {
      return managementSystemPermissionsService.getUsersPermissions(type, targetId).then(function (response) {
          vm.managementSystems = response.data;
          vm.managementSystems.forEach(function (managementSystem) {
              var role = findRole(managementSystem);
              selectRole(managementSystem, role);
            });

        });
    }

    function selectRole(managementSystem, role) {
      if (!hasRole(managementSystem)) {
        managementSystem.role = { data: {} };
      }

      if (role !== null) {
        managementSystem.role.data.permissions = role.permissions;
        managementSystem.role.label = role.label;
      } else {
        //Set to no access
        managementSystem.role.data.permissions = vm.roles[0].permissions;
        managementSystem.role.label = vm.roles[0].label;
      }
    }

    function hasRole(managementSystem) {
      if (managementSystem.role !== undefined && managementSystem.role.data !== undefined) {
        return true;
      }

      return false;
    }

    function findRole(managementSystem) {
      var activeRole = null;
      if (hasRole(managementSystem)) {
        _.forEach(vm.roles, function (role) {
            if (angular.equals(role.permissions, managementSystem.role.data.permissions)) {
              activeRole = role;
            }
          });
      }

      return activeRole;
    }

    function bgColor(managementSystem) {
      var role = findRole(managementSystem);
      if (role.label === 'View') {
        return 'permissions permissions-bg-view';
      } else if (role.label === 'Edit') {
        return 'permissions permissions-bg-edit';
      } else {
        return '';
      }
    }

    function select(managementSystem) {
      vm.managementSystem = managementSystem;
    }

    function isSelected(managementSystem) {
      if (vm.managementSystem === managementSystem) {
        return true;
      }

      return false;
    }

  }

})();

