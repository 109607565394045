(function () {
  'use strict';
  angular.module('lucidity').controller('FilingCabinetReviewController', [
    '$scope',
    'ModalService',
    '$modalInstance',
    '_',
    'MessageService',
    'FilingCabinetService',
    'FormService',
    '$modalOptions',
    function ($scope, ModalService, $modalInstance, _, MessageService, FilingCabinetService, FormService, $modalOptions) {

      ModalService.init($scope, $modalInstance, $modalOptions).loadBody({ bodyTemplate: '/app/filing-cabinet/reviewlist.html' });
      $scope.form = {};
      $scope.currentReviews = [];
      $scope.currentReview = {};

      $scope.showCurrentReviews = function (item) {
        FilingCabinetService.getCurrentReviews(item).then(function (response) {
          $scope.currentReviews = response.data;
          $scope.finishProcessing();
          if (response.data.length > 0) {
            $scope.bodyTemplate('/app/filing-cabinet/reviewlist.html');
            $scope.footerTemplate('/app/ui/modal/modal-footer-close.html');
          } else {
            $scope.addReview(item);
          }
        });

        $scope.callback('cancel', function ($modalScope, $modalInstance) {
          return $modalInstance.dismiss('cancel');
        });
      };

      $scope.deleteReview = function (item, review) {
        FilingCabinetService.deleteReview(item, review).then(function (nextReview) {
          updateItem(item, nextReview);
          MessageService.success('Review deleted');
          showListOrDismiss(item);
        });
      };

      $scope.editReview = function (item, review) {
        saveReview(item, review);
      };

      $scope.addReview = function (item) {
        saveReview(item);
      };

      function saveReview(item, review) {
        $scope.bodyTemplate('/app/filing-cabinet/reviewadd.html');
        $scope.footerTemplate('/app/ui/modal/modal-footer-ok-cancel.html');

        var actions = _.nested(review, 'actions.data', []);
        $scope.currentReview = _.extend({}, { text: 'File requiring review: ' + item.data.name + '.' + item.data.file.data.extension }, review);
        $scope.currentReview.actionee = _.map(actions, function (action) {
          return { key: action.actionee.data.id, value: action.actionee.data.fullName };
        });

        $scope.callback('ok', function ($modalScope, $modalInstance) {
          $scope.startProcessing('ok');
          if (!$scope.form.currentReviewForm.$valid) {
            FormService.markAllDirty($scope.form.currentReviewForm);
            MessageService.error('Validation error');
            $scope.finishProcessing();
            return;
          }

          FilingCabinetService.saveReview(item, createReview($modalScope.currentReview)).then(function (nextReview) {
            $scope.finishProcessing();
            updateItem(item, nextReview);
            MessageService.success('Review saved successfully');
            $scope.showCurrentReviews(item);

          }, function () {

            $scope.finishProcessing();
            MessageService.error('Error saving review. Please try again.');
          });
        });

        $scope.callback('cancel', function ($modalScope, $modalInstance) {
          showListOrDismiss(item);
        });

      }

      function updateItem(item, nextReview) {
        item.data.nextReview = nextReview;
        item.data.nextReviewStatus = _.nested(nextReview, 'data.status', 'complete');
      }

      function createReview(form) {
        return {
          id: form.id || null,
          actionee: _.pluck(form.actionee, 'key'),
          text: form.text,
          dueDate: form.dueDate,
        };
      }

      function showListOrDismiss(item) {
        if (_.nested(item, 'data.nextReview.data')) {
          $scope.startProcessing('cancel');
          return $scope.showCurrentReviews(item);
        } else {
          return $modalInstance.dismiss('cancel');
        }
      }

      $scope.startProcessing('bodyLoading');
      $scope.showCurrentReviews($modalOptions.context);
    },
  ]);
})();
